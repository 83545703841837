import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {OutRoute, ProtectedRoute} from 'navigation/RouteManager'
//screens
import Dashboard from 'screens/Dashboard'
import PhoneLogin from 'screens/PhoneLogin'
import Register from 'screens/Register'
import ForgotPassword from 'screens/ForgotPassword'
import ResetPassword from 'screens/ResetPassword'
import Accounts from 'screens/Accounts'
import Account from 'screens/Account'
import Users from 'screens/Users'
import User from 'screens/User'
import Elders from 'screens/Elders'
import {useGetCurrentUserQuery} from 'store/Store'
import Ticket from 'screens/Ticket'
import TicketHistory from 'screens/TicketHistory'
import Volunteers from 'screens/Volunteers'
import SocialWorkers from 'screens/SocialWorkers'
import Organizations from 'screens/Organizations'
import UserChats from 'screens/userChats'

const navigationRouter = (
    <Router>
        <Routes>
            <Route element={<OutRoute />}>
                <Route exact path="/" element={<PhoneLogin />} />
                <Route exact path="/login" element={<PhoneLogin />} />
                <Route path="/register/:token" element={<Register />} />
                <Route path="/register" element={<Register />} />
                <Route
                    path="/resetPassword/:token"
                    element={<ResetPassword />}
                />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/account/:accountId" element={<Account />} />
                <Route path="/users" element={<Users />} />
                <Route path="/user/:userId" element={<User />} />
                <Route path="/user/:userId/chats" element={<UserChats />} />

                <Route path="/elders" element={<Elders />} />
                <Route path="/volunteers" element={<Volunteers />} />
                <Route path="/social_workers" element={<SocialWorkers />} />

                <Route path="ticket/:ticketId" element={<Ticket />} />
                <Route path="/ticketHistory" element={<TicketHistory />} />
                <Route path="/organizations" element={<Organizations />} />
            </Route>
        </Routes>
    </Router>
)

const NavigationRouter = () => {
    const {data, error} = useGetCurrentUserQuery()
    console.log('navigation router', {data, error})
    return <div>{navigationRouter}</div>
}

export default NavigationRouter

const getRouterProtectedPaths = (router) => {
    const outlets = router.props.children.props.children

    const protectedOutlet = outlets.filter(
        (outlet) => outlet.props.element.type.name === ProtectedRoute.name
    )?.[0]

    return protectedOutlet.props.children.map((route) => route.props.path)
}

export const protectedPaths = getRouterProtectedPaths(navigationRouter)
