import {formatPhone, getFullName} from 'services/utils/formUtils'
import {styled} from '@mui/material'
import TabContentRow from './TabContentRow'

const VolunteerDetails = ({ticket}) => {
    const volunteers = ticket?.volunteers
        .map(
            (volunteer) =>
                `${getFullName(volunteer)} (${formatPhone(volunteer.phone)})`
        )
        .join(', ')
    return (
        <Container>
            <TabContentRow
                rowData={volunteers}
                rowKey="name"
                showDivider={true}
            />
        </Container>
    )
}

export default VolunteerDetails

const Container = styled('div')``
