import {React} from 'common'
import {styled, Typography} from '@mui/material'
import Button from 'components/Control/Button'
import {useI18n, useLogoutMutation} from 'store/Store'

const HeaderActions = ({user}) => {
    const {i18n} = useI18n()
    const [doLogout] = useLogoutMutation()

    const {firstName = '', lastName = ''} = user ?? {}
    const name = `${firstName} ${lastName}`

    return (
        <Container>
            <Typography variant="h6">{name}</Typography>
            <Button variant="text" onClick={doLogout} name={'logout'}>
                {i18n.t('header.logout')}
            </Button>
        </Container>
    )
}

export default HeaderActions

const Container = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;
`
