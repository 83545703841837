import {Box} from '@mui/material'

const TabContent = ({children, value, index, ...other}) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box style={{padding: '12px 0'}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    )
}

export default TabContent
