import Checkbox from 'components/Control/Checkbox'
import {
    cancellationInitiators,
    cancellationReasons,
    ticketStatuses,
} from 'constants/tickets'
import {useUrlParams} from 'services/hooks'
import {useI18n} from 'store/Store'
import {
    Container,
    Title,
} from 'components/Display/Table/TableTitleAndFilters/filtersStyles'
import {isChecked, removeParams, toggleFilters} from './utils'

const TicketStatus = () => {
    const {i18n} = useI18n()
    const {urlParams, setUrlParam} = useUrlParams()

    const onChange = (fieldName) => (event) => {
        if (fieldName === 'status' && !event.target.checked) {
            removeParams(['cancellationInitiator', 'cancellationReason'])
        }
        updateFiltersParams(fieldName, event.target.name)
    }

    const updateFiltersParams = (fieldName, value) => {
        const filters = urlParams[fieldName]?.split(',') || []
        const newFilters = toggleFilters(filters, value)
        setUrlParam(fieldName, newFilters)
    }

    return (
        <Container>
            <div>
                <Title>{i18n.t('tickets.filters.status')}</Title>
                {ticketStatuses.map((status) => (
                    <Checkbox
                        name={status}
                        checked={isChecked(urlParams, 'status', status)}
                        onChange={onChange('status')}
                        value={status}
                        label={i18n.t(`tickets.statuss.${status}`)}
                    />
                ))}
            </div>

            <div>
                <Title disabled={!isChecked(urlParams, 'status', 'canceled')}>
                    {i18n.t('tickets.filters.cancellationInitiator')}
                </Title>
                {cancellationInitiators.map((initiator) => (
                    <Checkbox
                        name={initiator}
                        checked={isChecked(
                            urlParams,
                            'cancellationInitiator',
                            initiator
                        )}
                        onChange={onChange('cancellationInitiator')}
                        value={initiator}
                        label={i18n.t(
                            `tickets.cancellationInitiators.${initiator}`
                        )}
                        disabled={!isChecked(urlParams, 'status', 'canceled')}
                    />
                ))}
            </div>

            <div>
                <Title disabled={!isChecked(urlParams, 'status', 'canceled')}>
                    {i18n.t('tickets.filters.cancellationReason')}
                </Title>
                {cancellationReasons.map((reason) => (
                    <Checkbox
                        name={reason}
                        checked={isChecked(
                            urlParams,
                            'cancellationReason',
                            reason
                        )}
                        onChange={onChange('cancellationReason')}
                        value={reason}
                        label={i18n.t(`tickets.cancellationReasons.${reason}`)}
                        disabled={!isChecked(urlParams, 'status', 'canceled')}
                    />
                ))}
            </div>
        </Container>
    )
}

export default TicketStatus
