import {styled} from '@mui/material'
import Texts from 'appearance/texts'
import {useEffect, useState} from 'react'
import {useI18n} from 'store/Store'
import Button from './Button'

const ResendCode = ({submissionTime, submitPhoneLogin}) => {
    const {i18n} = useI18n()
    const [remainingTime, setRemainingTime] = useState(0.01)

    useEffect(() => {
        const targetDate = new Date(submissionTime.getTime() + 5 * 60000)

        const interval = setInterval(() => {
            const now = new Date().getTime()
            const distance = targetDate.getTime() - now
            setRemainingTime(distance)
        }, 1000)

        return () => clearInterval(interval)
    }, [setRemainingTime, submissionTime])

    const formatTime = (time) => {
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((time % (1000 * 60)) / 1000)

        return `${minutes}m ${seconds}s`
    }

    return (
        <Container>
            {remainingTime <= 0 ? (
                <Texts.MediumText>{i18n.t('auth.timesUp')}</Texts.MediumText>
            ) : (
                <>
                    <Texts.MediumText>
                        {i18n.t('auth.otpExpiresIn')}
                    </Texts.MediumText>
                    <Texts.BoldText style={{fontSize: 18}}>
                        {formatTime(remainingTime)}
                    </Texts.BoldText>
                </>
            )}

            <ResendCodeButtonWrapper>
                {remainingTime > 0 && (
                    <Texts.RegularText>
                        {i18n.t('auth.didntReceive')}
                    </Texts.RegularText>
                )}
                <Button variant="text" onClick={submitPhoneLogin}>
                    {i18n.t('auth.resendCode')}
                </Button>
            </ResendCodeButtonWrapper>
        </Container>
    )
}

export default ResendCode
const Container = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
`

const ResendCodeButtonWrapper = styled('div')`
    display: flex;
    align-items: center;
    gap: 4px;

    & button:hover {
        background-color: transparent;
        text-decoration: underline;
    }
`
