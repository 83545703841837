import {Label} from './Form'
import {FormControl, Select as RawSelect, styled, MenuItem} from '@mui/material'
import {paramCase} from 'change-case'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {useI18n, useIsRtl} from 'store/Store'
import {useState} from 'react'

const Select = ({
    itemName,
    name,
    label,
    dataTestId,
    multiline,
    required,
    InputProps,
    options,
    value,
    optionKey,
    doNotTranslate,
    ...p
}) => {
    const [open, setOpen] = useState(false)
    const {i18n} = useI18n()
    const isRTL = useIsRtl()

    const toggleOpen = () => {
        setOpen(!open)
    }
    const getOptionText = (option) => {
        // #org
        if (optionKey) {
            return option[optionKey]
        }
        return doNotTranslate
            ? option
            : i18n.t(`${itemName}.${name}s.${option}`)
    }
    console.log('status_select__6', p)
    return (
        <Container>
            {label && <Label required={required}>{label}</Label>}
            <FormControl {...p} style={{width: '100%'}}>
                <StyledSelect
                    open={open}
                    onClose={toggleOpen}
                    onOpen={toggleOpen}
                    data-testid={`${name}_select`}
                    hiddenLabel
                    disableUnderline
                    variant="filled"
                    multiline={multiline}
                    data-test-id={`input-${paramCase(
                        label || dataTestId || 'undefined'
                    )}`}
                    InputProps={{
                        ...InputProps,
                    }}
                    IconComponent={() => (
                        // <div>onClick={() => {value = null}}>x</div>
                        <KeyboardArrowDownIcon
                            onClick={toggleOpen}
                            style={{cursor: 'pointer'}}
                        />
                    )}
                    value={value}
                    defaultValue={value}
                    renderValue={(v) => (optionKey ? v[optionKey] : v)}
                    {...p}>
                    {options?.map((option, index) => (
                        <MenuItem
                            data-testid={`${option}_option`}
                            key={option._id ?? index}
                            value={option}
                            style={{direction: isRTL ? 'rtl' : 'ltr'}}>
                            {getOptionText(option)}
                        </MenuItem>
                    ))}
                </StyledSelect>
            </FormControl>
        </Container>
    )
}

export default Select
const Container = styled('div')``

const StyledSelect = styled(RawSelect)`
    &.MuiInputBase-root {
        height: 40px;
        padding-inline: 8px;
    }

    & .MuiFilledInput-root.MuiInputBase-hiddenLabel .MuiAutocomplete-input {
        height: 40px;
        padding: 0;
    }

    & .MuiSelect-select {
        padding-right: 0 !important;
    }
    & svg {
        position: unset;
    }
`
