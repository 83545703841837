import {React} from 'common'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import {menuActions, menuIcons} from 'constants/sideMenu'
import {useLocation, useNavigate} from 'react-router-dom'
import {styled} from '@mui/material'
import {Colors} from 'appearance'

const getIcon = (text) =>
    menuIcons[text] ||
    text
        ?.split(' ')
        .map((t) => t[0])
        .join('')

const SideMenuItem = ({open, text, index, onClick, children, disabled}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const path = location.pathname.split('/')[1]

    const onClickInternal = () => {
        if (menuActions[text]) {
            menuActions[text](navigate)
        }
        onClick?.()
    }

    const icon = getIcon(text, index)

    return (
        <StyledListItemButton
            selected={path === text}
            disabled={disabled}
            sx={{px: 2.5}}
            open={open}
            onClick={onClickInternal}>
            {icon ? <SideMenuIcon>{icon}</SideMenuIcon> : null}
            {children}
        </StyledListItemButton>
    )
}

export default SideMenuItem

const StyledListItemButton = styled(ListItemButton)`
    min-height: 48px;
    justify-content: center;

    &.Mui-selected {
        background: none;
    }
    &.Mui-selected svg,
    &.Mui-selected [class^='d-icon'] {
        color: ${Colors.PRIMARY_COLOR};
    }
`
const SideMenuIcon = styled(ListItemIcon)`
    min-width: initial;
    & [class^='d-icon-'],
    svg {
        font-size: 32px;
    }
`
