import {styled} from '@mui/material'
import {FormErrorText} from 'components/Control/Form'
import {fieldsComponents, formFields} from 'constants/forms'
import {useI18n} from 'store/Store'

const CreateSocialWorker = ({key, formControl, formErrors, errorMessage}) => {
    const {i18n} = useI18n()

    return (
        <Container>
            {formFields.social_workers.map((field) => {
                const Component = fieldsComponents[field.fieldType]
                const key =
                    field.fieldType === 'multiple' ||
                    field.fieldType === 'address'
                        ? field.mainInputName
                        : field.key

                return (
                    <Component
                        key={field.key}
                        itemName="social_workers"
                        name={key}
                        mainInputName={field.mainInputName}
                        label={i18n.t(`social_workers.${field.key}`)}
                        control={formControl}
                        required={field.required}
                        errors={formErrors}
                        options={field.options}
                        subInputs={field.subInputs}
                        subInputsSizing={field.subInputsSizing}
                    />
                )
            })}

            {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}
        </Container>
    )
}

export default CreateSocialWorker

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
