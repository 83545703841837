import {React, styled} from 'common'
import {useCreateUserMutation, useI18n, useSaveUserMutation} from 'store/Store'
import {FormErrorText} from 'components/Control/Form'
import {useFormContext} from 'react-hook-form'
import {fieldsComponents, formFields} from 'constants/forms'
import DialogButtons from 'components/Page/Dashboard/Tickets/NewTicket/NewTicketActions'
import {useState} from 'react'
import ConfirmationDialog from 'components/Display/ConfirmationDialog'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import EditIcon from '@mui/icons-material/Edit'
import {hideField, setValueOptions} from 'services/utils/formUtils'
import {isAdminActions} from 'services/utils/misc'
import {optionEmptyOrganization} from 'constants/users'
import ErrorDialog from 'components/Display/ErrorDialog'

const getButtonText = (userId, type, i18n) => {
    if ((type === 'admin' || type === 'specialist') && userId === 'new') {
        return i18n.t('form.sendInvite')
    }
    return userId === 'new'
        ? i18n.t('form.saveAndContinue')
        : i18n.t('form.updateDetails')
}

const getFieldKey = (field) =>
    field.fieldType === 'multiple' || field.fieldType === 'address'
        ? field.mainInputName
        : field.key

const User = ({
    userId,
    type,
    setAddressValue,
    handleClose,
    onDeleteUser,
    accounts,
    organizations,
}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const [errorMessage, setErrorMessage] = useState()
    const [showConfirmation, setShowConfirmation] = useState()

    const [doCreateUser] = useCreateUserMutation()
    const [doUpdateUser] = useSaveUserMutation()

    const {
        // watch,
        control: formControl,
        formState: {errors: formErrors},
        handleSubmit,
        setValue,
    } = formContext

    const onSubmit = async (values) => {
        let response
        if (userId && userId !== 'new') {
            const userAccounts = [...values.userAccounts]
            userAccounts[0].role = values.role
            response = await doUpdateUser({
                ...values,
                userAccounts,
            })
        } else {
            response = await doCreateUser(values)
        }

        if (response?.error) {
            let errorMessage = response?.error?.data?.error
            if (errorMessage === 'user already exists') {
                errorMessage = i18n.t('users.user_already_exists')
            }
            setShowConfirmation(null)
            setErrorMessage(errorMessage)
            return
        }
        handleClose()
    }

    const submit = handleSubmit(onSubmit)

    const setAccountValue = (newValues) => {
        setValue('accounts', newValues, setValueOptions)
    }

    const getFieldOptions = (field) => {
        if (field.key === 'accounts') {
            return accounts
        }
        if (field.key === 'organization') {
            return [optionEmptyOrganization, ...organizations]
        }

        return field.options
    }

    const setRegion = (city) => {
        setValue('region', city, setValueOptions)
    }

    const accountsFixedOptions = () => {
        const fixedAccount = accounts?.find((account) => account?.isSuper)
        return fixedAccount && [fixedAccount]
    }

    const handleSetRoleAndType = (event) => {
        const {value} = event.target
        setValue('type', 'specialist', setValueOptions)
        setValue('role', value, setValueOptions)
    }
    // console.log('user form', getValues())
    return (
        <>
            <Container>
                {formFields[`${type}s`]?.map((field) => {
                    const Component = fieldsComponents[field.fieldType]
                    const key = getFieldKey(field)
                    const options = getFieldOptions(field)

                    if (
                        hideField(field.showOnCreate, field.showOnEdit, userId)
                    ) {
                        return <></>
                    }
                    return (
                        <Component
                            key={key}
                            showOnCreate={
                                field.showOnCreate || userId !== 'new'
                            }
                            inputKey={field.key}
                            itemName="users"
                            name={key}
                            mainInputName={field.mainInputName}
                            label={i18n.t(
                                `users.${field.labelKey ?? field.key}`
                            )}
                            control={formControl}
                            required={field.required}
                            errors={formErrors}
                            options={options}
                            setAddressValue={setAddressValue}
                            setRegion={setRegion}
                            subInputs={field.subInputs}
                            subInputsSizing={field.subInputsSizing}
                            formatFunc={field.formatFunc}
                            optionKey={field.optionKey}
                            InputProps={{
                                readOnly: field.readOnly && userId !== 'new',
                            }}
                            handleChange={setAccountValue}
                            fixedOptions={accountsFixedOptions()}
                            setFormValue={
                                isAdminActions(type)
                                    ? handleSetRoleAndType
                                    : undefined
                            }
                            doNotTranslate={field.doNotTranslate}
                        />
                    )
                })}
                {errorMessage && (
                    <>
                        <ErrorDialog
                            open={errorMessage}
                            text={errorMessage}
                            handleClose={() => setErrorMessage(null)}
                        />
                        <FormErrorText>{errorMessage}</FormErrorText>
                    </>
                )}
                <DialogButtons
                    okButtonOptions={{
                        text: getButtonText(userId, type, i18n),
                        icon: <EditIcon />,
                    }}
                    cancelButtonOptions={
                        userId !== 'new' && {
                            text: i18n.t('form.deleteUser'),
                            icon: <DisabledByDefaultIcon />,
                            color: 'error',
                        }
                    }
                    onOk={
                        userId === 'new'
                            ? submit
                            : () => setShowConfirmation(true)
                    }
                    onCancel={() => onDeleteUser(userId)}
                />
            </Container>
            <ConfirmationDialog
                open={showConfirmation}
                text={i18n.t(`users_${type}s.updateConfirmationText`)}
                onOk={submit}
                handleClose={() => setShowConfirmation(null)}
            />
        </>
    )
}
export default User

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    overflow: auto;
`
