import {Menu, MenuItem} from '@mui/material'
import {useState} from 'react'
import {useI18n, useIsRtl} from 'store/Store'

const ContextMenu = ({
    ticketId,
    items,
    children,
    menuName,
    actions,
    disabledItems = [],
}) => {
    const {i18n} = useI18n()
    const isRtl = useIsRtl()

    const [contextMenu, setContextMenu] = useState(null)
    const getContextMenuPosition = (x, y) =>
        contextMenu === null
            ? {
                  mouseX: x + 2,
                  mouseY: y - 6,
              }
            : null

    const handleContextMenu = (event) => {
        event.preventDefault()
        setContextMenu(getContextMenuPosition(event.clientX, event.clientY))
    }

    const handleClose = () => {
        setContextMenu(null)
    }

    return (
        <div onContextMenu={handleContextMenu} style={{cursor: 'context-menu'}}>
            {children}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                        : undefined
                }>
                {items.map((item) => (
                    <MenuItem
                        key={item}
                        onClick={() => actions[item](ticketId)}
                        dir={isRtl ? 'rtl' : 'ltr'}
                        disabled={disabledItems.includes(item)}>
                        {i18n.t(`${menuName}.${item}`)}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default ContextMenu
