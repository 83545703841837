import {styled} from '@mui/material'
import {Colors, Texts} from 'appearance'
import Card from 'components/Display/Card'
import {useI18n} from 'store/Store'

const DashboardStatusItem = ({active, count, name, onClick}) => {
    const {i18n} = useI18n()

    return (
        <Container
            data-testid={`${name}`}
            square
            elevation={1}
            active={active}
            onClick={() => onClick(name)}>
            <Count>{count}</Count>
            <CardTitle>{i18n.t(`tickets.statuss.${name}`)}</CardTitle>
        </Container>
    )
}

export default DashboardStatusItem

const Container = styled(Card)`
    cursor: pointer;
    width: 250px;
    background-color: ${({active}) =>
        active === 'true' ? Colors.PRIMARY_COLOR : 'none'};
    box-shadow: ${({active}) =>
        active === 'true'
            ? '0px 2px 16px rgba(75,107,245,0.4)'
            : '0px 2px 16px rgba(21, 13, 67, 0.06)'};
    & * {
        color: ${({active}) =>
            active === 'true' ? '#fff' : Colors.PRIMARY_COLOR};
    }
`

const Count = styled(Texts.RegularText)`
    font-size: 32px;
`

const CardTitle = styled(Texts.RegularText)`
    font-size: 14px;
    margin: 0 auto;
`
