import {
    ToggleButtonGroup as ToggleButtonGroupRaw,
    ToggleButton,
    styled,
} from '@mui/material'
import {Colors} from 'appearance'

const ToggleButtonGroup = ({name, onChange, buttons, multiple, activeItem}) => {
    return (
        <>
            <StyledToggleButtonGroup
                name={name}
                variant="contained"
                disableElevation
                onChange={onChange}
                exclusive={!multiple}>
                {buttons.map((button) => (
                    <StyledToggleButton
                        data-testid={`${name}_button`}
                        key={button.key}
                        color="secondary"
                        value={button.key}
                        selected={button.key === activeItem}>
                        {button.label}
                    </StyledToggleButton>
                ))}
            </StyledToggleButtonGroup>
        </>
    )
}

export default ToggleButtonGroup

const StyledToggleButtonGroup = styled(ToggleButtonGroupRaw)`
    margin: auto;
    gap: 8px;
`

export const StyledToggleButton = styled(ToggleButton)`
    border: 0;
    border-radius: 1px;
    font-size: 16px;
    background: ${Colors.SECONDARY_COLOR};
    color: ${Colors.APP_WHITE};
    &:hover {
        background: ${Colors.PRIMARY_TEXT};
    }
    &.Mui-selected {
        color: ${Colors.APP_WHITE};
        background: ${Colors.PRIMARY_COLOR};
    }
    &.Mui-selected:hover {
        background: ${Colors.PRIMARY_COLOR};
    }
`
