import {React} from 'common'
import Table from 'components/Display/Table'
import {Page, PageContent} from 'components/Page'
import SocialWorkerFormDialog from 'components/Page/Dashboard/SocialWorkers/SocialWorkerFormDialog'
import {useCallback, useState} from 'react'
import {useDeleteUserMutation, useGetSocialWorkersQuery} from 'store/Store'

const SocialWorkers = () => {
    const [socialWorkerId, setSocialWorkerId] = useState(null)
    const clearSocialWorkerId = useCallback(() => {
        setSocialWorkerId(null)
    }, [])
    return (
        <Page menuTitle={'social_workers'}>
            <PageContent>
                <Table
                    itemName="social_worker"
                    allowEdit
                    allowDelete
                    allowAdd
                    addFunction={() => setSocialWorkerId('new')}
                    editFunction={setSocialWorkerId}
                    allowDownload
                    paginationType="external"
                    useGetQuery={useGetSocialWorkersQuery}
                    useDeleteMutation={useDeleteUserMutation}
                />
            </PageContent>
            <SocialWorkerFormDialog
                socialWorkerId={socialWorkerId}
                handleClose={clearSocialWorkerId}
            />
        </Page>
    )
}

export default SocialWorkers
