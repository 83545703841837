import FormAddressComponent from 'components/Control/Form/FormAddressComponent'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import FormRadioGroup from 'components/Control/Form/FormRadioGroup'
import FormSelect from 'components/Control/Form/FormSelect'
import FormTextInput from 'components/Control/Form/FormTextInput'
import MultipleInputsComponent from 'components/Control/Form/MultipleInputsComponent'
import TextArea from 'components/Control/Form/TextArea'
import Checkbox from 'components/Control/Form/FormChecbox'
import {
    genders,
    holocaustSurvivor,
    regions,
    statuses,
    underCares,
    userRoles,
} from './users'
import {relationships} from './tickets'
import StaticText from 'components/Control/StaticText'
import {formatKeyFunctionsByType} from 'services/utils/tables'
import FormChipSelect from 'components/Control/Form/FormChipSelect'
import CitiesListInput from 'components/Control/Form/CitiesListInput'

export const address = {
    key: 'address_title',
    mainInputName: 'address',
    required: true,
    fieldType: 'address',
    subInputs: [
        'street',
        'houseNumber',
        'city',
        'floor',
        'apartmentNumber',
        'enterance',
    ],
    subInputsSizing: [2, 1, 2, 1, 1, 1],
}

export const formFields = {
    elders: [
        {
            key: 'firstName',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'lastName',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'phone',
            required: true,
            fieldType: 'text',
            readOnly: true,
        },
        {
            key: 'additionalPhone',
            required: false,
            fieldType: 'text',
        },
        {
            key: 'gender',
            required: true,
            fieldType: 'radio',
            options: genders,
        },
        address,
        {
            key: 'birthDate',
            required: false,
            fieldType: 'datePicker',
            subInputs: ['year', 'month', 'day'],
            subInputsSizing: [2, 1, 1],
        },
        {
            key: 'underCare',
            required: true,
            fieldType: 'radio',
            options: underCares,
        },
        {
            key: 'holocaustSurvivor',
            required: false,
            fieldType: 'radio',
            options: holocaustSurvivor,
        },
        {
            key: 'comments',
            required: false,
            fieldType: 'textArea',
        },
    ],
    volunteers: [
        {
            key: 'firstName',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'lastName',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'phone',
            required: true,
            fieldType: 'text',
            readOnly: true,
        },
        {
            key: 'email',
            required: false,
            fieldType: 'text',
        },
        {
            key: 'gender',
            required: true,
            fieldType: 'radio',
            options: genders,
        },
        {
            key: 'birthDate',
            required: false,
            fieldType: 'datePicker',
            subInputs: ['year', 'month', 'day'],
            subInputsSizing: [2, 1, 1],
        },
        {
            key: 'allowWhatsAppInquiries',
            required: false,
            fieldType: 'checkbox',
        },
        {...address, labelKey: 'primaryAddress'},
        {
            ...address,
            labelKey: 'secondaryAddress',
            mainInputName: 'secondaryAddress',
            required: false,
        },
        {
            key: 'created',
            labelKey: 'creationDate',
            required: false,
            fieldType: 'staticText',
            showOnCreate: false,
            formatFunc: formatKeyFunctionsByType.date,
        },
        {
            key: 'status',
            required: true,
            fieldType: 'select',
            options: statuses,
        },
        {
            key: 'organization',
            required: false,
            fieldType: 'select',
            doNotTranslate: true,
            optionKey: 'name',
            // #org
        },
    ],
    social_workers: [
        {key: 'firstName', required: true, fieldType: 'text'},
        {key: 'lastName', required: true, fieldType: 'text'},
        {key: 'email', required: false, fieldType: 'text'},
        {
            key: 'phone',
            required: true,
            fieldType: 'text',
            readOnly: true,
            labelKey: 'officePhone',
        },
        {
            key: 'additionalPhone',
            required: false,
            fieldType: 'text',
            labelKey: 'mobilePhone',
        },
        {
            key: 'gender',
            required: true,
            fieldType: 'radio',
            options: genders,
        },
        {
            key: 'region',
            required: false,
            fieldType: 'cities',
            options: regions,
        },
        {key: 'subRegion', required: false, fieldType: 'text'},
    ],
    relatives: [
        {
            key: 'firstName',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'lastName',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'email',
            required: false,
            fieldType: 'text',
        },
        {
            key: 'phone',
            required: true,
            fieldType: 'text',
            readOnly: true,
        },
        {
            key: 'gender',
            required: true,
            fieldType: 'radio',
            options: genders,
        },
        {
            key: 'relationship',
            required: false,
            fieldType: 'select',
            options: relationships,
        },
    ],
    admins: [
        {
            key: 'firstName',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'lastName',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'nationalId',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'phone',
            required: true,
            fieldType: 'text',
            showOnEdit: false,
            readOnly: true,
        },
        {
            key: 'email',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'role',
            required: true,
            fieldType: 'select',
            options: userRoles,
            showOnCreate: false,
        },
    ],
    specialists: [
        {
            key: 'firstName',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'lastName',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'nationalId',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'phone',
            required: true,
            fieldType: 'text',
            showOnEdit: false,
            readOnly: true,
        },
        {
            key: 'email',
            required: true,
            fieldType: 'text',
        },
        {
            key: 'role',
            required: true,
            fieldType: 'select',
            options: userRoles,
            showOnCreate: false,
        },
    ],
    account: [
        {key: 'name', required: true, fieldType: 'text'},
        {key: 'contactName', required: false, fieldType: 'text'},
        {key: 'contactPhone', required: false, fieldType: 'text'},
        {key: 'contactEmail', required: false, fieldType: 'text'},
        {
            key: 'created',
            required: false,
            fieldType: 'staticText',
            showOnCreate: false,
            showOnEdit: false,
            formatFunc: formatKeyFunctionsByType.date,
        },
        {
            key: 'volunteersCount',
            required: false,
            fieldType: 'text',
            showOnCreate: false,
            showOnEdit: false,
        },
    ],
    organization: [
        {key: 'name', required: true, fieldType: 'text'},
        {key: 'contactName', required: false, fieldType: 'text'},
        {key: 'contactPhone', required: false, fieldType: 'text'},
        {key: 'contactEmail', required: false, fieldType: 'text'},
        {key: 'city', required: false, fieldType: 'cities'},
    ],
}

export const fieldsComponents = {
    text: FormTextInput,
    radio: FormRadioGroup,
    checkbox: Checkbox,
    multiple: MultipleInputsComponent,
    textArea: TextArea,
    datePicker: FormDatePicker,
    address: FormAddressComponent,
    select: FormSelect,
    chipSelect: FormChipSelect,
    staticText: StaticText,
    cities: CitiesListInput,
}

export const actions = {
    createTicket: {okAction: 'createElder'},
    createEntity: {okAction: 'saveAndContinue', cancelAction: 'cancel'},
}
