import {styled, Tab, Tabs} from '@mui/material'
import {Colors} from 'appearance'

export const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
        }}
    />
))({
    '& .MuiTabs-indicator': {
        backgroundColor: 'transparent',
    },
})

export const StyledTab = styled((props) => <Tab disableRipple {...props} />, {
    shouldForwardProp: (prop) => prop !== 'valid',
})((props) => ({
    textTransform: 'none',
    borderLeft: `1px solid ${Colors.BORDER}`,
    borderTop: `6px solid ${Colors.APP_GRAY}`,
    flexGrow: 1,
    padding: 8,
    '&:first-of-type': {
        borderRight: `1px solid ${Colors.BORDER}`,
    },
    '&:hover': {
        color: '#40a9ff',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: Colors.PRIMARY_COLOR,
        borderTop: `6px solid ${Colors.PRIMARY_COLOR}`,
        borderBottom: 'none',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
    '&.MuiTabs-indicator': {
        display: 'none',
    },
}))
