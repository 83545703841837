import React from 'react'
import {Controller} from 'react-hook-form'
import {FormErrorText} from '.'
import Select from '../Select'

const FormSelect = ({
    itemName,
    name,
    label,
    control,
    errors,
    rules,
    options,
    required,
    disabled,
    initialValue,
    optionKey,
    setFormValue,
    doNotTranslate,
}) => {
    return (
        <div>
            <Controller
                name={name}
                control={control}
                rules={rules || {required}}
                render={({field: {onChange, value, onBlur}}) => {
                    // console.log('status_select__5', name, value)
                    return (
                        <Select
                            itemName={itemName}
                            name={name}
                            label={label}
                            value={value}
                            onChange={setFormValue ?? onChange}
                            onBlur={onBlur} //to set the field as touched on select
                            options={options}
                            disabled={disabled}
                            initialValue={initialValue}
                            optionKey={optionKey}
                            required={required}
                            doNotTranslate={doNotTranslate}
                        />
                    )
                }}
            />
            {errors?.[name] && (
                <FormErrorText>{errors[name].message}</FormErrorText>
            )}
        </div>
    )
}

export default FormSelect
