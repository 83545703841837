import {styled, ToggleButton, ToggleButtonGroup} from '@mui/material'
import {useI18n} from 'store/Store'

const TableButtonsGroup = ({tableName, value, handleChange, options}) => {
    const {i18n} = useI18n()

    if (!options) {
        return null
    }

    return (
        <Container>
            <StyledToggleGroup
                color="primary"
                value={value}
                exclusive
                onChange={handleChange}>
                {options.map((option) => (
                    <StyledToggleButton key={option} value={option}>
                        {i18n.t(`${tableName}.${option}s`)}
                    </StyledToggleButton>
                ))}
            </StyledToggleGroup>
        </Container>
    )
}

export default TableButtonsGroup

const Container = styled('div')`
    display: flex;
    justify-content: center;
    margin-block: 24px;
`
const StyledToggleGroup = styled(ToggleButtonGroup)`
    & button {
        border-radius: 4px !important;
        border: 1px solid;
        margin-left: 0;
        border-left: 1px solid !important;
    }
`

const StyledToggleButton = styled(ToggleButton)`
    height: 35px;
    margin-inline: 12px;
    &.Mui-selected {
        font-weight: bold;
    }
`
