import {styled} from '@mui/system'
import {Colors, Texts} from 'appearance'
import ContextMenu from 'components/Page/ContextMenu'
import {useI18n} from 'store/Store'
import moment from 'moment'
import {getFullName} from 'services/utils/formUtils'
import {useState} from 'react'
import EditTicketStatus from '../Ticket/components/EditTicketStatus'
import EditTicketMarkerColor from '../Ticket/components/EditTicketMarkerColor'
import {useNavigate} from 'react-router-dom'
import {useDeleteTicketMutation} from 'store/slices/api/ticketsApi'
import Dialog from 'components/Display/DialogForm/Dialog'
import WarningIcon from '@mui/icons-material/Warning'
import TicketDeleteAlert from './TicketDeleteAlert'
import AsignVolunteerToTicket from '../Ticket/components/AsignVolunteerToTicket'
import SendTicketToVoulnteers from '../Ticket/components/SendTicketToVoulnteers'

const TicketsListItem = ({ticket, isAdmin, active, onRowClick}) => {
    const {i18n} = useI18n()
    const navigate = useNavigate()
    const [doDeleteTicket] = useDeleteTicketMutation()

    const [changeStatus, setChangeStatus] = useState(false)
    const [changeMarkerColor, setChangeMarkerColor] = useState(false)
    const [ticketIdToDelete, setTicketIdToDelete] = useState()
    const [asignVolunteer, setAsignVolunteer] = useState(false)
    const [sendToVolunteers, setSendToVolunteers] = useState(false)

    const toggleTicketStatus = () => {
        setChangeStatus(!changeStatus)
        onRowClick(ticket._id)
    }

    const toggleTicketMarkerColor = () => {
        setChangeMarkerColor(!changeMarkerColor)
        onRowClick(ticket._id)
    }
    const toggleSendToVolunteers = (e, reason) => {
        if (reason === 'backdropClick') return
        setSendToVolunteers(!sendToVolunteers)
        onRowClick(ticket._id)
    }
    const toggleAsignVolunteer = () => {
        setAsignVolunteer(!asignVolunteer)
    }

    const handleChange = () => {
        onRowClick(ticket._id)
    }

    const {
        created,
        status,
        ticketType,
        category,
        elder: {address},
        availableTimes,
    } = ticket

    const elderName = getFullName(ticket.elder)

    const filterUnselectedDates = () =>
        availableTimes.filter((date) =>
            Object.values(date).some((value) => value === true)
        )

    const getRemainingTicketTime = () => {
        if (!availableTimes || !Array.isArray(availableTimes)) {
            return '-'
        }

        const today = moment().startOf('day')
        const dates = filterUnselectedDates().map((dateObj) =>
            moment(dateObj.date)
        )

        const latest =
            availableTimes[0].ticketTime === 'custom'
                ? moment(availableTimes[0].date).startOf('day')
                : moment.max(dates).startOf('day')

        if (latest.isBefore(today, 'd')) {
            return (
                <TextExpired>{i18n.t('tickets.ticketTimeEnded')}</TextExpired>
            )
        }
        const diff = latest.diff(today, 'days', true)

        return moment.duration(diff, 'days').humanize()
    }

    const openDeleteAlert = (ticketId) => {
        setTicketIdToDelete(ticketId)
    }

    const closeDeleteAlert = () => {
        setTicketIdToDelete()
    }

    const copyToClipboardTicketUrl = () => {
        const url = `${
            process.env.REACT_APP_MOBILE_APP_URL || 'https://app.2080.org.il'
        }/tickets/${ticket._id}`
        navigator.clipboard.writeText(url)
    }
    const onDelete = () => {
        doDeleteTicket(ticketIdToDelete)
        onRowClick(null)
    }

    const contextMenuItems = [
        'editTicket',
        'editVolunteer',
        'setMarkerColor',
        'sendToVolunteers',
        'copyToClipboardTicketUrl',
    ]
    const contextMenuActions = {
        editTicket: (ticketId) => {
            navigate(`/ticket/${ticketId}`, {state: ticket.elder})
        },
        editVolunteer: toggleAsignVolunteer,
        changeTicketStatus: toggleTicketStatus,
        setMarkerColor: toggleTicketMarkerColor,
        sendToVolunteers: toggleSendToVolunteers,
        deleteTicket: (ticketId) => {
            openDeleteAlert(ticketId)
        },
        copyToClipboardTicketUrl: copyToClipboardTicketUrl,
    }

    return (
        <>
            <ContextMenu
                ticketId={ticket._id}
                menuName="ticketMenu"
                items={
                    isAdmin
                        ? [
                              ...contextMenuItems,
                              'changeTicketStatus',
                              'deleteTicket',
                          ]
                        : contextMenuItems
                }
                actions={contextMenuActions}>
                <Container
                    data-testid={`${'dashboard_application'}`}
                    type={ticket.ticketType}
                    active={active.toString()}
                    markerColor={ticket.markerColor}
                    onClick={handleChange}>
                    <TicketText>{elderName}</TicketText>
                    <TicketText>
                        {moment(created).format('DD-MM-YYYY')}
                    </TicketText>
                    <TicketText>{i18n.t(`tickets.${category}`)}</TicketText>
                    <TicketText>
                        {i18n.t(`tickets.statuss.${status}`)}
                    </TicketText>
                    <TicketText>
                        {i18n.t(`tickets.ticketTypes.${ticketType}`)}
                    </TicketText>
                    <TicketText>{getRemainingTicketTime()}</TicketText>
                    <TicketText>{address?.city}</TicketText>
                </Container>
            </ContextMenu>
            <EditTicketStatus
                ticket={ticket}
                open={changeStatus}
                onClose={toggleTicketStatus}
            />
            <EditTicketMarkerColor
                ticket={ticket}
                open={changeMarkerColor}
                onClose={toggleTicketMarkerColor}
            />
            <SendTicketToVoulnteers
                open={sendToVolunteers}
                onClose={toggleSendToVolunteers}
                ticket={ticket}
            />
            <Dialog
                titlePadding="12px 12px 0 12px"
                open={!!ticketIdToDelete}
                onClose={closeDeleteAlert}
                title={<WarningIcon color="error" fontSize="large" />}>
                <TicketDeleteAlert onClose={closeDeleteAlert} onOk={onDelete} />
            </Dialog>

            <Dialog
                titlePadding="12px 12px 0 12px"
                open={asignVolunteer}
                onClose={toggleAsignVolunteer}
                title={i18n.t('tickets.asignVolunteerToTicket')}>
                <AsignVolunteerToTicket
                    onClose={toggleAsignVolunteer}
                    ticketData={ticket}
                />
            </Dialog>
        </>
    )
}

export default TicketsListItem

const Container = styled('div')`
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 15px 5px rgba(162, 162, 162, 0.2);
    padding: 24px 32px;
    cursor: pointer;
    user-select: none;
    border-right: 8px solid;
    margin-inline: ${({active}) => (active === 'true' ? '-16px' : 0)};
    border-right-color: ${({markerColor, type}) =>
        markerColor || Colors.APP_WHITE};
`
const TicketText = styled(Texts.RegularText)`
    min-width: 85px;
`

const TextExpired = styled(Texts.RegularText)`
    color: ${Colors.ERROR_TEXT};
`
