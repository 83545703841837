import {
    ColumnContainer,
    Title,
} from '../../../../Display/Table/TableTitleAndFilters/filtersStyles'
import {useI18n} from 'store/Store'
import Checkbox from 'components/Control/Checkbox'
import {isChecked, toggleFilters} from '../../Tickets/Filters/utils'
import {useUrlParams} from 'services/hooks'
import ChipSelect from 'components/Control/ChipSelect'

const ElderCity = ({itemKey, data: {cities}}) => {
    const {i18n} = useI18n()
    const {urlParams, setUrlParam} = useUrlParams()

    const onChange = (fieldName) => (event) => {
        updateFiltersParams(fieldName, event.target.name)
    }

    const updateFiltersParams = (fieldName, value) => {
        const filters = urlParams[fieldName]?.split(',') || []
        const newFilters = toggleFilters(filters, value)
        setUrlParam(fieldName, newFilters)
    }

    const onDeleteChip = (values) => {
        let filters

        if (values.length === 1) {
            filters = values[0]
        } else if (values.length > 1) {
            filters = values.join(',')
        }

        setUrlParam('address.city', filters)
    }

    const renderOption = (props, option) => {
        return (
            <Checkbox
                key={props['data-option-index']}
                data-testid={`option_${props['data-option-index'] + 1}`}
                name={option}
                checked={isChecked(urlParams, 'address.city', option)}
                onChange={onChange('address.city')}
                value={option}
                label={option}
            />
        )
    }
    const getOptionLabel = (option) => option

    const selectedCities = urlParams['address.city']?.split(',')

    return (
        <ColumnContainer>
            <Title>{i18n.t('users.address.city')}</Title>

            <ChipSelect
                disableClearable
                key={itemKey}
                itemName="city"
                name="volunteer-city-filter"
                options={cities}
                value={selectedCities}
                onChange={onDeleteChip}
                getOptionLabel={getOptionLabel}
                renderOptionFunc={renderOption}
                filterSelectedOptions={false}
                enableDeleteChip
            />
        </ColumnContainer>
    )
}

export default ElderCity
