import {React} from 'common'
import Toolbar from '@mui/material/Toolbar'
import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiAppBar from '@mui/material/AppBar'
import {useI18n, useIsRtl} from 'store/Store'
import {Link} from 'react-router-dom'
import Button from 'components/Control/Button'
import AddIcon from '@mui/icons-material/Add'
import HeaderActions from './Header/HeaderActions'

const Header = ({toggleDrawer, open, user, accounts, onClickCreate}) => {
    const {i18n} = useI18n()
    const isRtl = useIsRtl()
    const LOGO = '/2080_logo.png'

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar
                position="fixed"
                isrtl={isRtl ? 1 : 0}
                color="header"
                elevation={0}>
                <Toolbar>
                    <Link to="/">
                        <Logo src={LOGO} />
                    </Link>
                    <AppBarContent>
                        <StyledButton
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={onClickCreate}>
                            {i18n.t('header.newTicketButton')}
                        </StyledButton>
                        <HeaderActions user={user} />
                    </AppBarContent>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Header

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open, isrtl}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    borderBottom: '1px solid #D8D8D8',
}))

const AppBarContent = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const StyledButton = styled(Button)`
    border-radius: 0;
    gap: 6px;
    & .MuiButton-startIcon {
        margin: 0;
        font-size: 'inherit';
    }
`

const Logo = styled('img')`
    height: 27px;
    width: 109px;
    margin-left: 30px;
    margin-right: 30px;
`
