import {Navigate, Outlet, useLocation, useSearchParams} from 'react-router-dom'
import React, {useEffect} from 'react'
import {useGetCurrentUserQuery} from 'store/Store'
import {enrichUserForTracing} from 'sentry'

export const SKIP_AUTH = false

export const ProtectedRoute = () => {
    const location = useLocation()
    const {pathname, search} = location
    const {data, error} = useGetCurrentUserQuery()
    useEffect(() => {
        if (data) {
            enrichUserForTracing({
                email: data.email,
                name: `${data.firstName} ${data.lastName}`,
            })
        }
    }, [data])
    if (error?.status !== 401) {
        /*SKIP_AUTH || (data?.email && !(error?.status === 401)) */
        return <Outlet />
    }
    return (
        <Navigate
            to={`/login?redirectUri=${encodeURIComponent(
                `${pathname}${search}`
            )}`}
            replace
        />
    )
}

export const OutRoute = () => {
    const [searchParams] = useSearchParams()

    //  encodeURIComponent('/user/3785') => http://localhost:3960/login?redirectUri=%2Fuser%2F3785

    const redirectUri = searchParams.get('redirectUri') || '%2Fdashboard'

    const {data, error} = useGetCurrentUserQuery()
    if (!SKIP_AUTH && (!data?.phone || error?.status === 401)) {
        return <Outlet />
    }
    return <Navigate to={decodeURIComponent(redirectUri)} replace />
}
