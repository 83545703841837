import {styled} from '@mui/material'
import FormButtonGroup from 'components/Control/Form/FormButtonGroup'
import FormTextInput from 'components/Control/Form/FormTextInput'
import TimeSlotsSelection from 'components/Control/TimeSlotsSelection'
import {
    ticketDurations,
    ticketTimes,
    dayTimeSlots,
    daysCount,
    ticketTimeType,
} from 'constants/tickets'
import {useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import TicketTabSection from '../Tickets/TicketTabSection'
import moment from 'moment'
import {
    getDateOption,
    hoursToMinutes,
    minutesToHours,
    prevenNonNumeric,
    setValueOptions,
} from 'services/utils/formUtils'
import TicketFormError from './TicketFormError'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import FormTimePicker from 'components/Control/Form/FormTimePicker'

const TicketTimes = () => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const {
        control,
        watch,
        setValue,
        clearErrors,
        formState: {errors: formErrors},
    } = formContext
    const values = watch()
    const [activeDuration, setActiveDuration] = useState()
    const [ticketTime, setTicketTime] = useState()
    const durationInMinutes = watch('durationInMinutes')
    const availableTimes = watch('availableTimes')

    const getButtonsLabels = (buttons, inputName) =>
        buttons.map((key) => ({
            key,
            label: i18n.t(`ticketForm.${inputName}s.${key}`),
        }))

    const handleSelectDuration = (itemName) => (value) => {
        let duration = 'other'
        if (value === 'other') {
            setValue(itemName, '', setValueOptions)
        } else {
            clearErrors('durationInMinutes')
            setValue(itemName, value, setValueOptions)
            duration = value
        }

        setActiveDuration(duration)
    }

    useEffect(() => {
        if (!activeDuration) {
            const duration = ticketDurations.includes(`${durationInMinutes}`)
                ? `${durationInMinutes}`
                : 'other'
            setActiveDuration(duration)
        }
    }, [durationInMinutes, activeDuration])

    useEffect(() => {
        const timeValue = availableTimes && availableTimes[0]?.ticketTime
        if (!ticketTime && timeValue) {
            setTicketTime(timeValue)
        }
    }, [ticketTime, availableTimes])

    const setTicketType = (time) => {
        const type = ticketTimeType[time]
        setValue('ticketType', type, setValueOptions)
    }

    const handleSelectTime = (time) => {
        setTicketType(time)
        const days = setTimeSlots(getTimeSlotsFromSelection(time))
        const custom = {ticketTime: time}
        const updated =
            time === 'custom'
                ? [custom]
                : days.map((day) => ({...day, ticketTime: time}))
        setValue('availableTimes', updated, setValueOptions)

        setTicketTime(time)
    }

    const getTimeSlotsFromSelection = (time) => {
        const selectedTime = time ?? ticketTime
        const startValue = values.created
        const startDate = moment(startValue).startOf('day')
        const endDate = moment(startValue)
            .add(daysCount[selectedTime], 'days')
            .endOf('day')

        if (selectedTime === 'week_or_more') {
            startDate.add(1, 'weeks')
            endDate.subtract(1, 'day')
        }

        return datesRangeArray(startDate.toDate(), endDate.toDate())
    }

    const datesRangeArray = (startDate, endDate) => {
        let date = []
        while (startDate <= endDate) {
            date.push(moment(startDate).clone())
            startDate = moment(startDate).add(1, 'days').toDate()
        }

        return date
    }

    const setTimeSlots = (dates) => {
        const daySlots = dayTimeSlots.reduce(
            (obj, slot) => ({...obj, [slot]: false}),
            {}
        )

        const slots = dates.map((date) => ({
            date: date.format('YYYY-MM-DD'),
            ticketTime,
            ...daySlots,
        }))

        return slots
    }

    const toggleSelectTimeSlots = (date, selectedTimeSlot) => {
        const updated = availableTimes.map((day) => {
            const ticketDate = moment(day.date)
            const selectedDate = date.startOf('day')
            if (ticketDate.isSame(selectedDate, 'day')) {
                return {
                    ...day,
                    date: selectedDate.format('YYYY-MM-DD'),
                    [selectedTimeSlot]: !day[selectedTimeSlot],
                }
            }
            return day
        })

        setValue('availableTimes', updated, setValueOptions)
        formContext.trigger('availableTimes')
    }

    const onSetCustomDate = (value) => {
        const date = value.format('YYYY-MM-DD')
        const newValue = [{...availableTimes[0], date}]

        setValue('availableTimes', newValue, setValueOptions)
        const time = getDateOption(value)
        const ticketType = ticketTimeType[time]
        setValue('ticketType', ticketType, setValueOptions)
    }

    const onSetCustomTime = (value) => {
        const time = value?.format('HH:mm')
        const newValue = [{...availableTimes[0], time}]
        setValue('availableTimes', newValue, setValueOptions)
    }

    return (
        <Container>
            <TicketTabSection
                title={i18n.t('ticketForm.ticketDuratioTitle')}
                subtitle={i18n.t('ticketForm.ticketDuratioSubtitle')}>
                <FormButtonGroup
                    name="durationInMinutes"
                    buttons={getButtonsLabels(
                        ticketDurations,
                        'ticketDuration'
                    )}
                    handleChange={handleSelectDuration('durationInMinutes')}
                    activeItem={activeDuration}
                />
                {activeDuration === 'other' && (
                    <InputWrapper>
                        <FormTextInput
                            name="durationInMinutes"
                            type="number"
                            label={i18n.t(
                                'ticketForm.ticketDuration_other_label'
                            )}
                            control={control}
                            errors={formErrors}
                            transformOnChange={(event = {}) =>
                                hoursToMinutes(event?.target?.value)
                            }
                            transformValue={(event) =>
                                minutesToHours(
                                    durationInMinutes,
                                    event?.target?.value
                                )
                            }
                            onKeyPress={prevenNonNumeric}
                        />
                    </InputWrapper>
                )}

                <TicketFormError
                    fieldName="durationInMinutes"
                    shouldShowError={activeDuration !== 'other'}
                    errors={formErrors}
                />
            </TicketTabSection>
            <TicketTabSection subtitle={i18n.t('ticketForm.ticketTime_title')}>
                <FormButtonGroup
                    name="ticketTime"
                    buttons={getButtonsLabels(ticketTimes, 'ticketTime')}
                    handleChange={handleSelectTime}
                    activeItem={ticketTime}
                />

                {ticketTime === 'custom' && (
                    <CustomTicketTime>
                        <FormDatePicker
                            fullWidth
                            nested
                            name="availableTimes[0].date"
                            label={i18n.t('ticketForm.ticketTimes_custom_date')}
                            control={control}
                            errors={formErrors}
                            disablePast
                            setDate={onSetCustomDate}
                            valueFormat="DD-MM-YYYY"
                        />

                        <FormTimePicker
                            fullWidth
                            nested
                            name="availableTimes[0].time"
                            label={i18n.t('ticketForm.ticketTimes_custom_time')}
                            control={control}
                            errors={formErrors}
                            setTime={onSetCustomTime}
                            valueFormat="HH:mm"
                        />
                    </CustomTicketTime>
                )}

                {ticketTime && ticketTime !== 'custom' && (
                    <>
                        <TimeSlotsSelection
                            slotDays={getTimeSlotsFromSelection()}
                            handleSelectSlot={toggleSelectTimeSlots}
                        />

                        <TicketFormError
                            fieldName="availableTimes"
                            errors={formErrors}
                        />
                    </>
                )}
            </TicketTabSection>
        </Container>
    )
}

export default TicketTimes

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const CustomTicketTime = styled('div')`
    display: flex;
    margin-inline: auto;
    width: 50%;
    gap: 12px;
`

const InputWrapper = styled('div')`
    width: 30%;
    margin-inline: auto;
    margin-block: 24px;
`
