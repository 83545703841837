import {styled} from '@mui/material'
import {
    DatePicker as RawDatePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {Controller} from 'react-hook-form'
import {FormErrorText} from '.'
import Input from '../Input'
import moment from 'moment'
import {parseObjectPath} from 'services/utils/formUtils'

const FormDatePicker = ({
    name,
    control,
    rules,
    required,
    label,
    errors,
    nested,
    setDate,
    valueFormat,
    ...p
}) => {
    const errorMessage = nested
        ? parseObjectPath(name, errors)?.message
        : errors?.[name]?.message

    const parseInputValue = (value) =>
        value && value !== '' ? moment(value) : null

    return (
        <div style={{width: '100%'}}>
            <Controller
                name={name}
                control={control}
                rules={rules || {required}}
                render={({field: {onChange, value}}) => {
                    return (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                onChange={(val) =>
                                    setDate
                                        ? setDate(val)
                                        : onChange(val?.format('YYYY-MM-DD'))
                                }
                                value={parseInputValue(value)}
                                label={label}
                                slots={{textField: Input}}
                                slotProps={{
                                    popper: {
                                        style: {
                                            direction: 'rtl',
                                        },
                                    },
                                    textField: {
                                        required,
                                        name,
                                    },
                                }}
                                {...p}
                            />
                        </LocalizationProvider>
                    )
                }}
            />
            {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}
        </div>
    )
}

export default FormDatePicker

const DatePicker = styled(RawDatePicker)`
    & .MuiPickersCalendarHeader-labelContainer {
        background: red;
        margin: 0;
    }
    & button {
        padding: 0;
    }
`
