import Checkbox from 'components/Control/Checkbox'
import {useUrlParams} from 'services/hooks'
import {useI18n} from 'store/Store'
import {
    Container,
    Title,
} from 'components/Display/Table/TableTitleAndFilters/filtersStyles'
import {isChecked, toggleFilters} from 'services/utils/tableFilters'
import {underCares} from 'constants/users'

const UnderCare = () => {
    const {i18n} = useI18n()
    const {urlParams, setUrlParam} = useUrlParams()

    const onChange = (fieldName) => (event) => {
        updateFiltersParams(fieldName, event.target.name)
    }

    const updateFiltersParams = (fieldName, value) => {
        const filters = urlParams[fieldName]?.split(',') || []
        const newFilters = toggleFilters(filters, value)
        setUrlParam(fieldName, newFilters)
    }

    return (
        <Container>
            <div>
                <Title>{i18n.t('users.underCare')}</Title>
                {underCares.map((underCare) => (
                    <Checkbox
                        name={underCare}
                        checked={isChecked(urlParams, 'underCare', underCare)}
                        onChange={onChange('underCare')}
                        value={underCare}
                        label={i18n.t(`users.underCares.${underCare}`)}
                    />
                ))}
            </div>
        </Container>
    )
}

export default UnderCare
