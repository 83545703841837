export function calculateDistanceInMeters(coord1, coord2) {
    if (!coord1 || !coord2) return 0

    var lat1 = coord1.lat
    var lon1 = coord1.lng
    var lat2 = coord2.lat
    var lon2 = coord2.lng

    var R = 6371e3 // radius of the earth in meters
    var φ1 = lat1 * (Math.PI / 180) // convert latitude to radians
    var φ2 = lat2 * (Math.PI / 180) // convert latitude to radians
    var Δφ = (lat2 - lat1) * (Math.PI / 180) // difference in latitude
    var Δλ = (lon2 - lon1) * (Math.PI / 180) // difference in longitude

    var a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    var distance = R * c // distance in meters
    return Math.floor(distance)
}
