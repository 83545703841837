import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check'
import tables, {INITIAL_PAGE_SIZE} from 'constants/tables'
import {Texts} from 'appearance'
import {getFullName} from './formUtils'
import {dayTimeSlots} from 'constants/tickets'
import CopyClipIconAndMessage from 'components/Display/CopyClipIconAndMessage.js'

const getFullAddress = (addressValue) => {
    const {city, street, houseNumber, enterance, floor, apartmentNumber} =
        addressValue

    const addressArray = []
    if (street) {
        addressArray.push(`${street} ${houseNumber}`)
    }
    if (apartmentNumber) {
        addressArray.push(`דירה ${apartmentNumber}`)
    }
    if (floor) {
        addressArray.push(`קומה ${floor}`)
    }
    if (enterance) {
        addressArray.push(`כניסה ${enterance}`)
    }
    if (city) {
        addressArray.push(city)
    }
    return addressArray.join(', ')
}

const date = (value) => value && moment(value).format('D/M/YYYY')
const datetime = (value) => value && moment(value).format('D/M/YYYY HH:mm')
const number = (value) => value?.toFixed(2)
const boolean = (value) => (value ? <CheckIcon /> : null)
const email = (value) => <a href={`mailto:${value?.trim()}`}>{value?.trim()}</a>
const url = (value) => (
    <a href={value?.trim()} target="_blank" rel="noopener noreferrer">
        {value}
    </a>
)
const phone = (value) => value?.trim().replace('+972', '0')
const address = (value) => {
    const {name} = value || {}

    const fullAddress = getFullAddress(value || {})

    return (
        <div>
            {!!name && <Texts.BoldText>{name}</Texts.BoldText>}
            <Texts.RegularText>{fullAddress}</Texts.RegularText>
        </div>
    )
}
const largeText = (value) => <span style={{fontSize: 20}}>{value}</span>
const age = (birthDate) => moment().diff(moment(birthDate), 'years')
const gender = (value, i18n) => i18n.t(`users.genders.${value}`)
const underCare = (value, i18n) => i18n.t(`users.underCares.${value}`)
const holocaustSurvivor = (value, i18n) =>
    value ? i18n.t(`users.holocaustSurvivors.${value}`) : ''
const ticketStatus = (value, i18n, {cancellationReason}) => (
    <>
        <Texts.RegularText>
            {i18n.t(`tickets.statuss.${value}`)}
        </Texts.RegularText>
        {cancellationReason && (
            <Texts.RegularText variant="caption" color="gray" component="p">
                {i18n.t(`tickets.cancellationReasons.${cancellationReason}`)}
            </Texts.RegularText>
        )}
    </>
)
const ticketType = (value, i18n) => i18n.t(`tickets.ticketTypes.${value}`)
const elderName = (elder, i18n, rowData) => getFullName(rowData.elder)
const elderPhone = (elder, i18n, rowData) =>
    rowData.elder && phone(rowData.elder.phone)
const elderCity = (elder, i18n, rowData) => rowData?.elderCity || ''
const ticketTimes = (availableTimes, i18n, rowData) => {
    if (!availableTimes || !availableTimes.length) {
        return ''
    }

    const format = 'DD.MM.YYYY'

    if (availableTimes[0].ticketTime === 'custom') {
        return moment.utc(availableTimes[0].date).format(format)
    }

    const firstSelectedDay = availableTimes.find((day) => {
        const isDaySelected = dayTimeSlots.some((daySlot) => day[daySlot])
        const ticketCreationDate = moment(rowData.created).utc()
        const date = moment(day.date).utc()

        return isDaySelected && date.isSameOrAfter(ticketCreationDate, 'day')
    })

    const lastSelectedDate = availableTimes.findLast((day) =>
        dayTimeSlots.some((daySlot) => day[daySlot])
    )

    const startDate = firstSelectedDay?.date
    const lastDate = lastSelectedDate?.date

    const isStartAndEndDatesAreSame = moment(startDate)
        .utc()
        .isSame(moment(lastDate).utc(), 'day')

    if (isStartAndEndDatesAreSame) {
        return moment.utc(startDate).format(format)
    }

    return `${moment.utc(startDate).format(format)} - ${moment
        .utc(lastDate)
        .format(format)}`
}
const ticketAddress = (startAddress) => {
    if (startAddress) {
        return startAddress.name || startAddress.city || ''
    }

    return ''
}
const volunteers = (values) =>
    values?.map((volunteer) => getFullName(volunteer)).join(', ')
const region = (value, i18n) => i18n.t(`users.regions.${value}`)
const longText = (value) => <span className="long-text-cell">{value}</span>
const userStatus = (value, i18n) => i18n.t(`users.statuss.${value}`)
const fullName = (value, i18n, rowData) => getFullName(rowData)
const account = (account, i18n, rowData) =>
    rowData.userAccounts
        ? rowData.userAccounts.map(({account}) => account?.name).join(', ')
        : account?.name || ''
const ticketCategory = (value, i18n) => i18n.t(`tickets.${value}`)
const organization = (value) => value?.name || ''
const copyClipboardIcon = (value) => (
    <CopyClipIconAndMessage
        value={value}
        message={'organizations.copyLinkToClipboard'}
    />
)

export const formatKeyFunctionsByType = {
    date,
    datetime,
    number,
    boolean,
    email,
    url,
    phone,
    address,
    largeText,
    age,
    gender,
    underCare,
    ticketStatus,
    ticketType,
    elderName,
    elderPhone,
    ticketTimes,
    ticketAddress,
    volunteers,
    region,
    longText,
    userStatus,
    fullName,
    account,
    holocaustSurvivor,
    elderCity,
    ticketCategory,
    organization,
    copyClipboardIcon,
}

export const getItemId = (item) => item._id || item.id || item.key

const extractHeaderKeys = (data) =>
    data.length > 0 ? Object.keys(data[0]) : []

const sortByItemKey = (itemKey, direction) => (item1, item2) => {
    let value1 = item1[itemKey]
    let value2 = item2[itemKey]

    if (itemKey === 'isActive') {
        value1 = item1?.allowWhatsAppInquiries ? 1 : 0
        value2 = item2?.allowWhatsAppInquiries ? 1 : 0
    }

    const equality = direction === 'asc' ? value1 < value2 : value1 > value2

    return equality ? -1 : 1
}

const getParsedSort = (sort) => {
    if (sort) {
        return JSON.parse(sort)
    }
    return {}
}

export const getSortDirection = (itemKey, sort) => {
    const parsedSort = getParsedSort(sort)
    return parsedSort[itemKey]
}

export const setSortDirection = (itemName, itemKey, direction, setUrlParam) => {
    const parsedSort = {[itemKey]: direction}
    setUrlParam(`${itemName}_sort`, JSON.stringify(parsedSort))
}

const getVisibleData = (visibleKeys, data) => {
    if (visibleKeys) {
        return data?.map((row) => {
            return visibleKeys.reduce((accu, key) => {
                if (key === 'isActive') {
                    accu[key] = row?.allowWhatsAppInquiries ? 'Yes' : 'No'
                } else if (key === 'role' && row?.account?.role) {
                    accu[key] = row.account.role
                } else {
                    accu[key] = row[key]
                }
                return accu
            }, {})
        })
    }

    return data
}

const getSortedData = (sort, searchedData) => {
    const parsedSort = getParsedSort(sort)
    const sortKey = Object.keys(parsedSort)[0]

    let sortedData = [...searchedData]
    if (sortKey) {
        sortedData.sort(sortByItemKey(sortKey, parsedSort[sortKey]))
    }

    return sortedData
}

const extractTableData = (
    data,
    visibleKeys,
    page,
    pageSize,
    search,
    sort,
    paginationType
) => {
    page = Number(page || 0)
    pageSize = Number(pageSize || INITIAL_PAGE_SIZE)
    if (paginationType === 'local') {
        data = data.slice(page * pageSize, (page + 1) * pageSize)
    }

    const visibleData = getVisibleData(visibleKeys, data)

    if (paginationType === 'external') {
        return visibleData
    }

    const searchedData = visibleData?.filter((row) =>
        isSearchedRow(row, search)
    )

    return getSortedData(sort, searchedData)
}

export const isSearchedRow = (row, search) =>
    !search ||
    Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(search.toLowerCase())
    )

export const getTableData = (
    name,
    visibleKeys,
    data,
    page,
    pageSize,
    search,
    sort,
    paginationType
) => {
    let tableItems = []
    let totalItems
    if (Array.isArray(data)) {
        tableItems = data
        totalItems = data.length
    } else if (data?.data) {
        tableItems = data.data
        totalItems = data.total
    }

    const headerKeys = extractHeaderKeys(tableItems)
    const tableKeys = tables[name]?.visibleKeys || visibleKeys || headerKeys
    const tableData = extractTableData(
        tableItems,
        visibleKeys,
        page,
        pageSize,
        search,
        sort,
        paginationType
    )
    const keyTypes = tables[name]?.keyTypes || {}

    return {tableKeys, tableData, keyTypes, tableItems, totalItems}
}

export const sortByDate = (array, dateKey) =>
    array?.sort((a, b) => new Date(b[dateKey]) - new Date(a[dateKey]))
