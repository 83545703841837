import moment from 'moment'

export const isChecked = (urlParams, fieldName, value) => {
    const selected = urlParams[fieldName]?.split(',') || []
    return selected.includes(value)
}

export const toggleFilters = (filters, value) => {
    if (filters.join(',') === value) {
        return
    }
    const index = filters.indexOf(value)

    if (index === -1) {
        filters.push(value)
    } else {
        filters.splice(index, 1)
    }

    return filters.length ? filters.join(',') : undefined
}

const convertToMoment = (dateStr) =>
    moment.isMoment(dateStr) ? dateStr : moment(dateStr)

export const datesRangeArray = (startDate, endDate, format = 'YYYY-MM-DD') => {
    let dates = []
    let start = convertToMoment(startDate)
    const end = convertToMoment(endDate)
    while (start <= end) {
        dates.push(start.clone())
        start = start.add(1, 'days')
    }
    return dates.map((date) => date.format(format))
}

export const extractMainFilterKey = (array) => {
    return array.map((item) => (item.includes('.') ? item.split('.')[0] : item))
}

export const yearsToBirthDate = (age, position) => {
    const currentDate = new Date()
    const yearOffset = position === 'end' ? 0 : 1

    return new Date(
        currentDate.getFullYear() - age - yearOffset,
        currentDate.getMonth(),
        currentDate.getDate()
    )
}

export const dateToYears = (date, position) => {
    if (!date || date === 'null') {
        return ''
    }

    const currentDate = new Date()
    const inputDate = new Date(date)

    const yearsOffset = position === 'end' ? 1 : 0
    const yearsDiff =
        currentDate.getFullYear() - inputDate.getFullYear() + yearsOffset

    return yearsDiff - 1
}

export const removeParams = (paramNames) => {
    const searchParams = new URLSearchParams(window.location.search)

    paramNames.forEach((paramName) => {
        if (searchParams.has(paramName)) {
            searchParams.delete(paramName)
        }
    })

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`

    window.history.pushState(null, '', newUrl)
    window.location.reload()
}

export const getPreviousDay = (dateStr) => {
    const date = new Date(dateStr)
    return date
}

export const getNextDay = (dateStr) => {
    const date = new Date(dateStr)
    date.setDate(date.getDate() + 1)
    return date
}

export const filterParamsByPrefix = (currentParams, key) => {
    const keys = Object.keys(currentParams)
    return keys.filter((key) => key.startsWith(key))
}
