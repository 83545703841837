import {styled} from '@mui/material'
import {Texts} from 'appearance'
import {feedbackQuestions} from 'constants/tickets'
import {useI18n} from 'store/Store'

const Feedback = ({feedback}) => {
    const {i18n} = useI18n()

    const formatFeedbackAnswer = (question, answer) => {
        if (question === 'contact_details' || question === 'opinions_change') {
            return i18n.t(`tickets.feedback_answers.${answer}`)
        }
        return answer
    }

    return (
        <Container>
            {feedbackQuestions?.map((question) => (
                <FeedbackQuestion key={question}>
                    <QuestionName>
                        {`${i18n.t(`tickets.feedback_question_${question}`)}:`}
                    </QuestionName>

                    <DetailValue>
                        {formatFeedbackAnswer(question, feedback[question])}
                    </DetailValue>
                </FeedbackQuestion>
            ))}
        </Container>
    )
}

export default Feedback

const Container = styled('div')``

const FeedbackQuestion = styled('div')`
    display: flex;
    gap: 4px;
`

const QuestionName = styled(Texts.RegularText)`
    white-space: nowrap;
`

const DetailValue = styled(Texts.RegularText)`
    font-weight: 300;
`
