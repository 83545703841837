import {useState} from 'react'
import {styled} from '@mui/material'
import {useI18n} from 'store/Store'
import {
    ColumnContainer,
    Title,
} from '../../../../Display/Table/TableTitleAndFilters/filtersStyles'

import {DateRangePicker} from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import * as rdrLocales from 'react-date-range/dist/locale'

import {useUrlParams} from 'services/hooks'
import Button from 'components/Control/Button'
import {removeParams, toggleFilters} from 'services/utils/tableFilters'
import {formatEndDate} from '../../Tickets/Filters/utils'

const initialValue = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
}

const ElderCreationDate = () => {
    const {i18n} = useI18n()
    const {setUrlParam} = useUrlParams()

    const [range, setRange] = useState([initialValue])

    const handleSelectDateRange = ({selection}) => {
        setRange([selection])

        const {startDate, endDate} = selection
        updateFiltersParams('created[$gt]', startDate)
        updateFiltersParams('created[$lt]', formatEndDate(endDate))
    }

    const updateFiltersParams = (fieldName, value) => {
        const newFilters = toggleFilters([], value)
        setUrlParam(fieldName, newFilters)
    }

    const clearFilter = () => {
        setRange([initialValue])
        removeParams(['created[$gt]', 'created[$lt]'])
        window.location.reload()
    }

    return (
        <ColumnContainer>
            <TitleWrapper>
                <Title>{i18n.t('tickets.filters.ticketTime')}</Title>
                <Button variant="text" onClick={clearFilter}>
                    {i18n.t('general.clearDateFilter')}
                </Button>
            </TitleWrapper>

            <StyledDateRange
                locale={rdrLocales.he}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                onChange={handleSelectDateRange}
                ranges={range}
                showMonthArrow={false}
                showPreview={false}
                dateDisplayFormat={'dd-MM-yyyy'}
                startDatePlaceholder="מתאריך"
                endDatePlaceholder="עד תאריך"
            />
        </ColumnContainer>
    )
}

export default ElderCreationDate

const TitleWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
`

const StyledDateRange = styled(DateRangePicker)`
    direction: rtl;
    & .rdrDefinedRangesWrapper {
        display: none;
    }

    //RTL
    & .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfMonth .rdrInRange,
    .rdrEndEdge {
        right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        border-top-left-radius: 1.042em;
        border-bottom-left-radius: 1.042em;
        left: 2px;
    }
    & .rdrStartEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfMonth .rdrInRange {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        left: 0;

        border-top-right-radius: 1.042em;
        border-bottom-right-radius: 1.042em;
        right: 2px;
    }
`
