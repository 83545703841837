import {
    useCreateUserMutation,
    useSaveUserMutation,
    useGetUserQuery,
    useI18n,
} from 'store/Store'
import {useEffect, useMemo, useState} from 'react'
import Dialog from 'components/Display/DialogForm/Dialog'
import {DialogActions, DialogContent, styled} from '@mui/material'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {socialWorkerSchema} from 'services/utils/validations/validationSchemas'
import DialogButtons from '../Tickets/NewTicket/NewTicketActions'
import {formatPhone} from 'services/utils/formUtils'
import {phoneAlreadyExistsError} from 'services/utils/validations/validationUtils'
import CreateSocialWorker from './CreateSocialWorker'

const defaultValues = {
    type: 'social_worker',
    region: 'TEL_AVIV',
}

const SocialWorkerFormDialog = ({socialWorkerId, handleClose}) => {
    const {i18n} = useI18n()
    const [errorMessage, setErrorMessage] = useState(null)
    const isNew = !socialWorkerId || socialWorkerId === 'new'

    const {data: existingSocialWorker} = useGetUserQuery(
        {userId: socialWorkerId},
        {skip: isNew}
    )

    const [doCreateUser, {error: createError, data: createResponse}] =
        useCreateUserMutation()

    const [doUpdateUser, {error: updateError, data: updateResponse}] =
        useSaveUserMutation()

    const {
        handleSubmit,
        reset,
        control: formControl,
        formState: {errors: formErrors},
        setError,
    } = useForm({
        defaultValues,
        mode: 'all',
        resolver: yupResolver(socialWorkerSchema),
    })

    const socialWorker = useMemo(() => {
        if (socialWorkerId && existingSocialWorker) {
            return existingSocialWorker
        }
        return {}
    }, [socialWorkerId, existingSocialWorker])

    useEffect(() => {
        if (socialWorker && socialWorkerId && socialWorkerId !== 'new') {
            const values = {
                ...socialWorker,
                phone: formatPhone(socialWorker.phone),
            }
            reset(values)
        } else {
            reset(defaultValues)
        }
    }, [socialWorker, socialWorkerId, reset])

    const onCreate = () => {
        submit()
    }

    useEffect(() => {
        if (createResponse || updateResponse) {
            handleClose()
        }
    }, [createResponse, updateResponse, handleClose])

    useEffect(() => {
        const error = createError?.data?.error || updateError?.data?.error
        if (error === 'user already exists') {
            setError('phone', phoneAlreadyExistsError, {shouldFocus: true})
        } else {
            setErrorMessage(error)
        }
    }, [createError, updateError, setError])

    const onClose = () => {
        reset(defaultValues)
        handleClose()
    }

    const onSubmit = async (submittedData) => {
        if (socialWorkerId) {
            await doUpdateUser(submittedData)
        } else {
            await doCreateUser(submittedData)
        }
    }

    const submit = handleSubmit(onSubmit)

    const okButtonText = i18n.t('form.saveAndContinue')
    const cancelButtonText = i18n.t('form.cancel')

    return (
        <Dialog
            onClose={onClose}
            open={
                (!!socialWorkerId && socialWorker) || socialWorkerId === 'new'
            }
            title={i18n.t(
                `social_workers.${
                    isNew ? 'addSocialWorkerTitle' : 'editSocialWorkerTitle'
                }`
            )}>
            <DialogContent>
                <CreateSocialWorker
                    formControl={formControl}
                    formErrors={formErrors}
                    errorMessage={errorMessage}
                />
            </DialogContent>
            <ButtonWrapper>
                <DialogButtons
                    okButtonText={okButtonText}
                    cancelButtonText={cancelButtonText}
                    onOk={onCreate}
                    onCancel={onClose}
                />
            </ButtonWrapper>
        </Dialog>
    )
}

export default SocialWorkerFormDialog

const ButtonWrapper = styled(DialogActions)`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    padding: 0 18px 18px 18px;
`
