import {styled} from 'common'
import {
    Checkbox as RawCheckbox,
    FormGroup,
    FormControlLabel,
} from '@mui/material'

const Input = ({
    label,
    name,
    disabled,
    defaultChecked,
    onChange,
    checked,
    ...p
}) => {
    if (label) {
        return (
            <FormGroup {...p}>
                <FormControlLabel
                    sx={{marginRight: 0, marginLeft: 0}}
                    control={
                        <RawCheckbox
                            name={name}
                            size="small"
                            defaultChecked={defaultChecked}
                            onChange={onChange}
                            checked={checked}
                        />
                    }
                    label={label}
                    disabled={disabled}
                />
            </FormGroup>
        )
    }

    return <RawCheckbox {...p} defaultChecked />
}
export default styled(Input)``
