import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRowRaw from '@mui/material/TableRow'
import {useI18n, useIsRtl} from 'store/Store'
import {Texts} from 'appearance'
import TableSort from './TableSort'
import TableFilter from './TableFilter'

const TableHeadRow = ({
    itemName,
    onDelete,
    allowExpand,
    allowEdit,
    tableKeys,
}) => {
    const isRtl = useIsRtl()
    const {i18n} = useI18n()
    const tableName = `${itemName}s`

    return (
        <TableHead>
            <TableRowRaw>
                {tableKeys.map((key) => (
                    <TableCell
                        key={`table_head_${key}`}
                        align={isRtl ? 'right' : 'left'}>
                        <Texts.BoldText style={{display: 'inline'}}>
                            {i18n.t(`${tableName}.${key}`)}
                        </Texts.BoldText>
                        <TableSort itemName={itemName} itemKey={key} />
                        <TableFilter itemName={itemName} itemKey={key} />
                    </TableCell>
                ))}
                {(allowEdit || onDelete || allowExpand) && (
                    <TableCell
                        sx={{fontWeight: 'bold'}}
                        key={'actions'}></TableCell>
                )}
            </TableRowRaw>
        </TableHead>
    )
}

export default TableHeadRow
