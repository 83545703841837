import Alert from '@mui/material/Alert'
import Fade from '@mui/material/Fade'
import {useState} from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {useI18n} from 'store/Store'

// TODO: there are many more style and behivor options, see docs
// add here fade time, color, sevirity and more...
const CopyClipIconAndMessage = ({value, message}) => {
    const {i18n} = useI18n()
    const [showAlert, setShowAlert] = useState(false)
    return (
        <>
            <Fade
                in={showAlert}
                timeout={{enter: 700, exit: 1000}}
                addEndListener={() => {
                    setTimeout(() => {
                        setShowAlert(false)
                    }, 3000)
                }}
                style={{position: 'absolute', marginRight: 30, zIndex: 1}}>
                <Alert severity="info" icon={false} variant="filled">
                    &nbsp;&nbsp;{i18n.t(message)}
                </Alert>
            </Fade>

            <ContentCopyIcon
                onClick={() => {
                    navigator.clipboard.writeText(value)
                    setShowAlert(true)
                }}
            />
        </>
    )
}

export default CopyClipIconAndMessage
