import {yupResolver} from '@hookform/resolvers/yup'
import {DialogContent} from '@mui/material'
import Button from 'components/Control/Button'
import {FormErrorText} from 'components/Control/Form'
import FormSelect from 'components/Control/Form/FormSelect'
import Dialog from 'components/Display/DialogForm/Dialog'
import {
    cancellationInitiators,
    cancellationReasons,
    ticketStatuses,
} from 'constants/tickets'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {ticketCancellation} from 'services/utils/validations/validationSchemas'
import {useSaveTicketMutation} from 'store/slices/api/ticketsApi'
import {useI18n} from 'store/Store'
import styled from 'styled-components'

const EditTicketStatus = ({ticket, open, onClose}) => {
    const {i18n} = useI18n()
    const [errorMessage, setErrorMessage] = useState()
    const [doSave, {response: saveResponse, error: saveError}] =
        useSaveTicketMutation()

    const {
        control,
        handleSubmit,
        formState: {errors, isValid},
        watch,
    } = useForm({
        defaultValues: {
            status: ticket?.status,
        },
        mode: 'all',
        resolver: yupResolver(ticketCancellation),
    })

    const statusValue = watch('status')

    const onSubmit = ({status, cancellationReason, cancellationInitiator}) => {
        doSave({...ticket, status, cancellationReason, cancellationInitiator})
    }

    useEffect(() => {
        if (saveResponse) {
            onClose()
        }
    })

    useEffect(() => {
        if (saveError) {
            setErrorMessage(saveError?.data?.message)
        }
    }, [saveError])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={i18n.t('tickets.ticketType')}>
            <DialogContent>
                <FormSelect
                    itemName="tickets"
                    name="status"
                    label={i18n.t('tickets.ticketStatus')}
                    control={control}
                    fullWidth
                    initialValue=""
                    errors={errors}
                    options={ticketStatuses}
                />

                {statusValue === 'canceled' && (
                    <>
                        <FormSelect
                            itemName="tickets"
                            name="cancellationInitiator"
                            label={i18n.t('tickets.cancellationInitiator')}
                            control={control}
                            fullWidth
                            initialValue=""
                            errors={errors}
                            options={cancellationInitiators}
                        />
                        <FormSelect
                            itemName="tickets"
                            name="cancellationReason"
                            label={i18n.t('tickets.ticketStatus')}
                            control={control}
                            fullWidth
                            initialValue=""
                            errors={errors}
                            options={cancellationReasons}
                        />
                    </>
                )}

                {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}

                <DialogActions>
                    <Button color="secondary" onClick={onClose}>
                        {i18n.t('form.cancel')}
                    </Button>
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isValid}>
                        {i18n.t('form.saveAndContinue')}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default EditTicketStatus

const DialogActions = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`
