import {styled} from '@mui/material'
import {Controller} from 'react-hook-form'
import {FormErrorText} from '.'
import {
    TimePicker as RawTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {parseObjectPath} from 'services/utils/formUtils'
import moment from 'moment'
import Input from '../Input'

const FormTimePicker = ({
    name,
    control,
    rules,
    required,
    label,
    errors,
    nested,
    setTime,
    valueFormat,
    ...p
}) => {
    const errorMessage = nested
        ? parseObjectPath(name, errors)?.message
        : errors?.[name]?.message

    const parseValue = (value) => (value ? moment(value, valueFormat) : null)

    return (
        <div style={{width: '100%'}}>
            <Controller
                name={name}
                control={control}
                rules={rules || {required}}
                render={({field: {onChange, value}}) => {
                    return (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <TimePicker
                                onChange={setTime ?? onChange}
                                value={parseValue(value)}
                                label={label}
                                slots={{textField: Input}}
                                slotProps={{
                                    textField: {
                                        required,
                                    },
                                    digitalClockSectionItem: {
                                        style: {flexDirection: 'row-reverse'},
                                    },
                                }}
                                {...p}
                            />
                        </LocalizationProvider>
                    )
                }}
            />
            {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}
        </div>
    )
}

export default FormTimePicker

const TimePicker = styled(RawTimePicker)`
    & button {
        padding: 0;
    }
`
