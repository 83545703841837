import {styled} from '@mui/material'
import RawButton from '@mui/material/Button'
import {paramCase} from 'change-case'

const Button = ({children, dataTestId, disabled, name, ...p}) => {
    return (
        <StyledButton
            data-testid={`${name}_button`}
            disableElevation
            variant="contained"
            disabled={disabled}
            data-test-id={`button-${paramCase(
                children?.toString() || dataTestId || 'undefined'
            )}`}
            {...p}>
            {children}
        </StyledButton>
    )
}
export default Button

const StyledButton = styled(RawButton)`
    border-radius: 0;
    padding: 4px 12px;
    font-size: 16px;
    & .MuiButton-endIcon {
        margin: 0;
        margin-inline-start: 8px;
    }
`
