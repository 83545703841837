import {React} from 'common'
import List from '@mui/material/List'
import {useGetCurrentUserQuery, useIsRtl} from 'store/Store'
import SideMenuItem from 'components/Page/SideMenu/SideMenuItem'
import SideMenuDrawer from './SideMenu/SideMenuDrawer'
import {menuItems} from 'constants/sideMenu'
import {isSuperUser} from 'services/utils/misc'

const SideMenu = ({open, handleClose, toggleDrawer, accounts, user}) => {
    const isRtl = useIsRtl()
    const {data: currentUser} = useGetCurrentUserQuery()

    const filterMenuItems = () => {
        const superUserItems = ['accounts']
        const baseItems = menuItems.filter(
            (item) => !superUserItems.includes(item)
        )
        if (!currentUser) {
            return baseItems
        }
        const isSuper = isSuperUser(currentUser)

        return isSuper ? menuItems : baseItems
    }

    return (
        <SideMenuDrawer
            variant="permanent"
            open={open}
            anchor={isRtl ? 'right' : 'left'}>
            <List style={{marginTop: 64}}>
                {filterMenuItems().map((text, index) => (
                    <SideMenuItem
                        key={text}
                        open={open}
                        text={text}
                        index={index}
                    />
                ))}
            </List>
        </SideMenuDrawer>
    )
}

export default SideMenu
