import I18n from 'i18n-js'
import storage from 'services/storage'
import en from './locales/en'
import he from './locales/he'
import store, {setLanguage} from 'store/Store'
import moment from 'moment'
import 'moment/locale/he'

I18n.fallbacks = true
I18n.translations = {
    he,
    en,
}

export const getAvailableLanaguages = () => Object.keys(I18n.translations)

export const getLocale = () => I18n.locale

export const setLocale = (lang) => {
    moment.locale(lang)
    // set language in redux
    store.dispatch(setLanguage(lang))

    // update language in storage
    if (I18n.locale === lang) return
    I18n.locale = lang
    if (lang === 'he') {
        storage.setLanguage('he')
    } else {
        storage.setLanguage('en')
    }
    // window.location.reload(false);
}

let activeLanguage = storage.getLanguage()
if (!activeLanguage) {
    storage.setLanguage('he')
    activeLanguage = 'he'
}

setTimeout(() => setLocale(activeLanguage), 300)

moment.relativeTimeThreshold('d', 30)
moment.locale('he', {
    relativeTime: {
        s: 'היום',
        m: 'היום',
        mm: 'היום',
        h: 'היום',
        hh: 'היום',
        d: 'מחר',
        dd: '%d ימים',
    },
})

export default I18n
