import {styled} from '@mui/material'
import {Colors, Texts} from 'appearance'
import {useI18n} from 'store/Store'
import SearchResult from './SearchResult'

const SearchResults = ({results, onClose}) => {
    const {i18n} = useI18n()

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <ResultsTitleWrapper>
                <ResultsTitle>
                    {i18n.t('tickets.results_title', {
                        count: results?.length || 0,
                    })}
                </ResultsTitle>
            </ResultsTitleWrapper>

            {results?.length ? (
                <ResultsContainer>
                    {results?.map((result) => (
                        <SearchResult
                            key={result._id}
                            result={result}
                            onClose={onClose}
                        />
                    ))}
                </ResultsContainer>
            ) : (
                <NoResultsText>
                    {i18n.t('tickets.search_no_results')}
                </NoResultsText>
            )}
        </div>
    )
}

export default SearchResults

const ResultsTitleWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 16px;
`

const ResultsTitle = styled(Texts.Title)`
    color: ${Colors.APP_BLACK};
`

const ResultsContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

const NoResultsText = styled(Texts.MediumText)`
    font-size: 18px;
`
