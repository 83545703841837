import {yupResolver} from '@hookform/resolvers/yup'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import storage from 'services/storage'
import {
    loginPhoneSchema,
    registerSchema,
} from 'services/utils/validations/validationSchemas'
import {
    useLoginMutation,
    usePhoneLoginMutation,
    useVerifyMutation,
} from 'store/Store'

const useLogin = (source) => {
    const [submissionTime, setSubmissionTime] = useState()

    const [
        doVerify, // This is the mutation trigger
        {isLoading: isVerifyLoading, error: verifyError, data: verifyResponse}, // This is the destructured mutation result
    ] = useVerifyMutation()

    const [
        doPhoneLogin, // This is the mutation trigger
        {
            isLoading: isPhoneLoginLoading,
            error: phoneLoginError,
            data: phoneLoginResponse,
        }, // This is the destructured mutation result
    ] = usePhoneLoginMutation()

    const [
        doLogin, // This is the mutation trigger
        {isLoading: isLoginLoading, error: loginError, data: loginResponse}, // This is the destructured mutation result
    ] = useLoginMutation()

    const {
        getValues: getFormValues,
        setValue: setFormValue,
        handleSubmit,
        control: formControl,
        formState: {errors: formErrors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(loginPhoneSchema),
    })

    const {
        getValues: getVerifyFormValues,
        setValue: setVerifyFormValue,
        handleSubmit: handleVerifySubmit,
        control: verifyFormControl,
        formState: {errors: verifyFormErrors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(registerSchema),
        context: {source},
    })

    const onSubmitLogin = (submittedData) => {
        storage.setRememberMe(submittedData.rememberMe || false)
        doLogin(submittedData)
    }

    const onSubmitPhoneLogin = (submittedData) => {
        doPhoneLogin(submittedData)
        setSubmissionTime(new Date())
    }

    const onSubmitVerify = (submittedData) => {
        console.log('verify data - submit', submittedData)
        doVerify(submittedData)
    }

    const submitLogin = handleSubmit(onSubmitLogin)
    const submitPhoneLogin = handleSubmit(onSubmitPhoneLogin)
    const submitVerify = handleVerifySubmit(onSubmitVerify)

    const transformErrorMessage = (error) => {
        const message = error?.data?.error || error?.data?.message
        if (!message) {
            return
        }
        if (message.includes('invalid code')) {
            return 'קוד שגוי'
        }
        if (message.includes('invalid phone')) {
            return 'מספר הטלפון לא תקין'
        }
        return message
    }

    return {
        formControl,
        verifyFormControl,
        submitLogin,
        submitPhoneLogin,
        submitVerify,
        isLoading: isPhoneLoginLoading || isLoginLoading || isVerifyLoading,
        error: transformErrorMessage(
            loginError || phoneLoginError || verifyError
        ),
        formErrors,
        verifyFormErrors,
        getFormValues,
        setFormValue,
        getVerifyFormValues,
        setVerifyFormValue,
        response: loginResponse || phoneLoginResponse,
        verifyResponse,
        submissionTime,
    }
}

export default useLogin
