import {Controller} from 'react-hook-form'
import {FormErrorText} from '.'
import AutocompleteInput from '../AutocompleteInput'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import {IconButton, InputLabel, Tooltip, styled} from '@mui/material'
import {useI18n} from 'store/Store'
import FormTextInput from './FormTextInput'
import {Colors, Texts} from 'appearance'
import {createAddressError} from 'services/utils/validations/validationUtils'
import {getFullAddress} from 'services/utils/formUtils'
import {useState} from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {GOOGLE_MAPS_API_KEY} from 'services/storage'

const FormAddressComponent = ({
    itemName,
    name,
    label,
    control,
    errors,
    rules,
    required,
    disabled,
    setAddressValue,
    withSubInputs,
}) => {
    const {i18n} = useI18n()
    const [inputValue, setInputValue] = useState('')
    if (Object.keys(errors)?.length > 0) console.log('errors', errors)
    const {placesService, placePredictions, getPlacePredictions} =
        usePlacesService({
            apiKey: GOOGLE_MAPS_API_KEY,
            language: 'iw',
            options: {
                types: ['address'],
                componentRestrictions: {
                    country: 'il',
                },
            },
        })

    const getAddressComponent = (type, addressComponents) =>
        addressComponents.find((comp) => comp.types.includes(type))?.long_name

    const formatPlacesAddress = (placeDetails, inputHouseNumber) => {
        const {
            address_components: addressComponents,
            geometry: {location},
        } = placeDetails

        const houseNumber =
            getAddressComponent('street_number', addressComponents) ||
            inputHouseNumber
        const street = getAddressComponent('route', addressComponents)
        const city = getAddressComponent('locality', addressComponents)
        const lat = location.lat()
        const lng = location.lng()
        return {houseNumber, street, city, lat, lng}
    }

    const handleSelect = (event, value) => {
        const inputHouseNumber = getInputHaouseNumber(value)
        placesService?.getDetails(
            {
                placeId: value?.place_id,
            },
            (placeDetails) => {
                const address = formatPlacesAddress(
                    placeDetails,
                    inputHouseNumber
                )
                setAddressValue({...address})
            }
        )
    }
    const getInputHaouseNumber = (selectedValue) => {
        // this is to override google. if the user typed a number and it's not in the google response than we use it anyway
        if (!selectedValue) return undefined
        const {structured_formatting} = selectedValue
        if (
            structured_formatting?.main_text_matched_substrings?.length === 1 &&
            structured_formatting?.main_text
        ) {
            const mainText = structured_formatting.main_text
            const validMainText =
                structured_formatting.main_text_matched_substrings[0]
            const invalidStreetNum = mainText.substring(validMainText.length)
            return invalidStreetNum.trim()
        }
        return undefined
    }
    const getOptionLabel = (option) =>
        option.description ?? getFullAddress(option)

    const handleInputChange = (event, newInputValue) => {
        if (newInputValue === '') {
            setAddressValue(newInputValue)
        }
        setInputValue(newInputValue)
        getPlacePredictions({input: newInputValue})
    }

    const errorMessage = createAddressError(errors?.address)

    return (
        <Container>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Label required={required}>{label}</Label>
                <Tooltip
                    placement="bottom-start"
                    title={
                        <div style={{direction: 'rtl'}}>
                            <TooltipText>{`פורמט מומלץ: <רחוב> <מספר בית>, <עיר>`}</TooltipText>
                            <TooltipText>{`דוגמה: יגאל אלון 98, תל אביב`}</TooltipText>
                        </div>
                    }>
                    <IconButton>
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <InputsContainer>
                <div style={{flex: 3}}>
                    <Controller
                        name={name}
                        control={control}
                        rules={rules || {required}}
                        render={({field: {value}}) => (
                            <AutocompleteInput
                                placeholder={i18n.t(
                                    'users.address.steetHouseNumberAndApartment'
                                )}
                                // key={key}
                                itemName={itemName}
                                name={name}
                                defaultValue={value}
                                value={value}
                                onChange={handleSelect}
                                inputValue={inputValue}
                                onInputChange={handleInputChange}
                                options={placePredictions}
                                disabled={disabled}
                                getOptionLabel={getOptionLabel}
                                filterOptions={(options) => options}
                            />
                        )}
                    />
                    {errors?.[name] && (
                        <FormErrorText>{errorMessage}</FormErrorText>
                    )}
                </div>

                {withSubInputs && (
                    <>
                        <FormTextInput
                            name="address.floor"
                            control={control}
                            placeholder={i18n.t(`users.address.floor`)}
                            InputProps={{style: {fontSize: '0.9em'}}}
                            style={{flex: 1}}
                            errors={
                                errors?.address?.floor
                                    ? {
                                          'address.floor': {
                                              message:
                                                  errors.address.floor.message,
                                          },
                                      }
                                    : {}
                            }
                        />
                        <FormTextInput
                            name="address.apartmentNumber"
                            control={control}
                            errors={errors}
                            placeholder={i18n.t(
                                `users.address.apartmentNumber`
                            )}
                            InputProps={{style: {fontSize: '0.9em'}}}
                            style={{flex: 1}}
                        />
                        <FormTextInput
                            name="address.enterance"
                            control={control}
                            errors={errors}
                            placeholder={i18n.t(`users.address.enterance`)}
                            InputProps={{style: {fontSize: '0.9em'}}}
                            style={{flex: 1}}
                        />
                    </>
                )}
            </InputsContainer>
        </Container>
    )
}

export default FormAddressComponent

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: inherit;
`

const InputsContainer = styled('div')`
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    width: 100%;
    & > * {
        box-sizing: border-box;
    }
`
export const Label = styled(InputLabel)`
    color: ${Colors.PRIMARY_COLOR};
    font-size: 16px;
`
export const TooltipText = styled(Texts.RegularText)`
    font-size: 12px;
`
