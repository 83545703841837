import Checkbox from 'components/Control/Checkbox'
import {useUrlParams} from 'services/hooks'
import {useI18n} from 'store/Store'
import {
    Container,
    Title,
} from 'components/Display/Table/TableTitleAndFilters/filtersStyles'
import {isChecked, toggleFilters} from 'services/utils/tableFilters'
import {holocaustSurvivor} from 'constants/users'

const HolocaustSurvivor = () => {
    const {i18n} = useI18n()
    const {urlParams, setUrlParam} = useUrlParams()

    const onChange = (fieldName) => (event) => {
        updateFiltersParams(fieldName, event.target.name)
    }

    const updateFiltersParams = (fieldName, value) => {
        const filters = urlParams[fieldName]?.split(',') || []
        const newFilters = toggleFilters(filters, value)
        setUrlParam(fieldName, newFilters)
    }

    return (
        <Container>
            <div>
                <Title>{i18n.t('users.holocaustSurvivor')}</Title>
                {holocaustSurvivor.map((holocaustSurvivor) => (
                    <Checkbox
                        name={holocaustSurvivor}
                        checked={isChecked(
                            urlParams,
                            'holocaustSurvivor',
                            holocaustSurvivor
                        )}
                        onChange={onChange('holocaustSurvivor')}
                        value={holocaustSurvivor}
                        label={i18n.t(
                            `users.holocaustSurvivors.${holocaustSurvivor}`
                        )}
                    />
                ))}
            </div>
        </Container>
    )
}

export default HolocaustSurvivor
