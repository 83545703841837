import {useGetAccountsQuery, useI18n} from 'store/Store'
import {
    ColumnContainer,
    Title,
} from '../../../../Display/Table/TableTitleAndFilters/filtersStyles'
import {useUrlParams} from 'services/hooks'
import {isChecked, toggleFilters} from '../../Tickets/Filters/utils'
import Checkbox from 'components/Control/Checkbox'
import ChipSelect from 'components/Control/ChipSelect'

const Account = ({itemKey}) => {
    const {i18n} = useI18n()
    const {urlParams, setUrlParam} = useUrlParams()
    const filterName = 'userAccounts.account'
    const {data: accounts} = useGetAccountsQuery()

    const userAccounts = accounts?.data
        .map((account) => account)
        .filter((account) => account.volunteersCount)
        .sort((a, b) => a.name.localeCompare(b.name))

    const onChange = (fieldName) => (event) => {
        updateFiltersParams(fieldName, event.target.name)
    }

    const updateFiltersParams = (fieldName, value) => {
        const filters = urlParams[fieldName]?.split(',') || []
        const newFilters = toggleFilters(filters, value)
        setUrlParam(fieldName, newFilters)
    }

    const onDeleteChip = (values) => {
        let filters

        if (values.length === 1) {
            filters = values[0]
        } else if (values.length > 1) {
            filters = values.join(',')
        }

        setUrlParam(filterName, filters)
    }

    const renderOption = (props, option) => {
        const key = props['data-option-index']
        return (
            <Checkbox
                key={key}
                data-testid={`option_${key + 1}`}
                name={option._id}
                checked={isChecked(urlParams, filterName, option._id)}
                onChange={onChange(filterName)}
                value={option._id}
                label={option.name}
            />
        )
    }

    const getOptionLabel = (option) =>
        userAccounts.find((account) => account._id === option)?.name

    const selectedAccounts = urlParams[filterName]?.split(',')

    return (
        <ColumnContainer>
            <Title>{i18n.t('users.account')}</Title>

            <ChipSelect
                disableClearable
                key={itemKey}
                itemName="account"
                name="volunteer-account-filter"
                options={userAccounts}
                optionKey="_id"
                value={selectedAccounts}
                onChange={onDeleteChip}
                getOptionLabel={getOptionLabel}
                renderOptionFunc={renderOption}
                filterSelectedOptions={false}
                enableDeleteChip
            />
        </ColumnContainer>
    )
}

export default Account
