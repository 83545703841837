import Table from 'components/Display/Table'
import {Page, PageContent} from 'components/Page'
import {
    useDeleteTicketMutation,
    useGetTicketsQuery,
} from 'store/slices/api/ticketsApi'
import {useUrlParams} from 'services/hooks'
import {useState} from 'react'
import Dialog from 'components/Display/DialogForm/Dialog'
import TicketDialog from './TicketDialog'

const TicketHistory = () => {
    const {urlParams} = useUrlParams()
    const [ticketId, setTicketId] = useState()

    return (
        <Page menuTitle={'accounts'}>
            <PageContent>
                <Table
                    allowDownload
                    itemName="ticket"
                    paginationType="external"
                    useGetQuery={useGetTicketsQuery}
                    useDeleteMutation={useDeleteTicketMutation}
                    query={{elder: urlParams.elder}}
                    onRowClick={setTicketId}
                />

                <Dialog
                    maxWidth="xl"
                    onClose={() => setTicketId(null)}
                    open={!!ticketId}>
                    <TicketDialog ticketId={ticketId} />
                </Dialog>
            </PageContent>
        </Page>
    )
}

export default TicketHistory
