import {List} from '@mui/material'
import {Children, createContext, forwardRef, useContext} from 'react'
import {FixedSizeList} from 'react-window'
import RawInfiniteLoader from 'react-window-infinite-loader'

const OuterElementContext = createContext({})

const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
})

const InfiniteLoader = forwardRef((props, ref) => {
    const {
        isItemLoaded,
        itemCount,
        loadMoreItems,
        children,
        RowComponent,
        ...other
    } = props

    const itemSize = props.itemSize || 36
    const LISTBOX_PADDING = props.listBoxPadding || 8

    const getHeight = () => {
        const visibleItems = Math.min(itemCount, 8)
        return visibleItems * itemSize
    }
    const height = getHeight() + LISTBOX_PADDING * 2

    const itemData = props?.children && Children.toArray(props.children)

    return (
        <OuterElementContext.Provider value={other}>
            <RawInfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                threshold={5}>
                {({onItemsRendered, ref}) => (
                    <FixedSizeList
                        ref={ref}
                        itemCount={itemCount}
                        onItemsRendered={onItemsRendered}
                        outerElementType={OuterElementType}
                        innerElementType={List}
                        itemSize={itemSize}
                        height={height}
                        overscanCount={4}
                        {...props}>
                        {({index, style, ...props}) => (
                            <RowComponent
                                {...props}
                                index={index}
                                style={style}
                                data={itemData}
                            />
                        )}
                    </FixedSizeList>
                )}
            </RawInfiniteLoader>
        </OuterElementContext.Provider>
    )
})

export default InfiniteLoader
