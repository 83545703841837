import {CircularProgress, InputAdornment} from '@mui/material'
import {i18n, React} from 'common'
import {FormInput} from 'components/Control/Form'
import useDebounce from 'logic/Forms/useDebounce'
import {useEffect, useState} from 'react'
import {useUrlParams} from 'services/hooks'

const TableSearch = ({itemName, useSearch, isLoading, ...p}) => {
    const {urlParams, setUrlParam} = useUrlParams()
    const {[`${itemName}_search`]: search} = urlParams

    const [inputValue, setInputValue] = useState(search || '')

    const debouncedSearch = useDebounce(inputValue, 500)

    useEffect(() => {
        if (debouncedSearch !== search) {
            setUrlParam(`${itemName}_search`, debouncedSearch)
            setUrlParam(`${itemName}_page`, 0)
        }
    }, [debouncedSearch, search, setUrlParam, itemName])

    const handleChange = (event) => {
        setInputValue(event.target.value)
    }

    if (!useSearch) {
        return null
    }

    return (
        <div {...p}>
            <FormInput
                name={`${itemName}Search`}
                onChange={handleChange}
                value={inputValue}
                placeholder={`${i18n.t('header.search')}...`}
                InputProps={{
                    endAdornment: isLoading && (
                        <InputAdornment position="start">
                            <CircularProgress size={20} />
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                }}
            />
        </div>
    )
}

export default TableSearch
