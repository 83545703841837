import {styled} from '@mui/material'
import {Colors, Texts} from 'appearance'

const TicketTabSection = ({title, subtitle, children, testKey}) => {
    return (
        <Container data-testid={`${testKey}_section`}>
            <BoldTitle>{title}</BoldTitle>
            <Title>{subtitle}</Title>

            {children}
        </Container>
    )
}

export default TicketTabSection

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    border: ${`1px solid ${Colors.BORDER}`};
    padding: 12px;
`

const Title = styled(Texts.RegularText)`
    font-size: 16px;
`
const BoldTitle = styled(Texts.BoldText)`
    font-size: 18px;
`
