import TicketDetails from 'components/Page/Dashboard/Tickets/Tabs/TicketDetails'
import ElderDetails from 'components/Page/Dashboard/Tickets/Tabs/ElderDetails'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import FlagIcon from '@mui/icons-material/Flag'
import CloseIcon from '@mui/icons-material/Close'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import {City} from '@styled-icons/fa-solid/City'
import {Receipt} from '@styled-icons/fluentui-system-filled/Receipt'
import {More} from '@styled-icons/material-sharp/More'
import {PeopleFill} from '@styled-icons/bootstrap/PeopleFill'
import {FacebookCircle} from '@styled-icons/boxicons-logos/FacebookCircle'
import {SmartphoneOutline} from '@styled-icons/evaicons-outline/SmartphoneOutline'
import {LocalHospital} from '@styled-icons/material-sharp/LocalHospital'
import {Home} from '@styled-icons/boxicons-solid/Home'
import {Phone} from '@styled-icons/boxicons-solid/Phone'
import {Route} from '@styled-icons/fa-solid/Route'
import {DeliveryDining} from '@styled-icons/material/DeliveryDining'
import {Light} from '@styled-icons/material-outlined/Light'
import {Smartphone} from '@styled-icons/feather/Smartphone'
import {People} from '@styled-icons/fluentui-system-filled/People'
import {SuitcaseMedical} from '@styled-icons/fa-solid/SuitcaseMedical'
import {Headset} from '@styled-icons/bootstrap/Headset'
import {PhoneCall} from '@styled-icons/boxicons-solid/PhoneCall'
import {HeartPulseFill} from '@styled-icons/bootstrap/HeartPulseFill'
import {ShoppingCart} from '@styled-icons/heroicons-solid/ShoppingCart'
import {Cup} from '@styled-icons/remix-fill/Cup'
import {Shop} from '@styled-icons/bootstrap/Shop'
import {Tools} from '@styled-icons/entypo/Tools'
import {CashStack} from '@styled-icons/bootstrap/CashStack'
import {Home as ElderHome} from '@styled-icons/boxicons-regular/Home'
import {PersonWalking} from '@styled-icons/fa-solid/PersonWalking'
import {Bus} from '@styled-icons/boxicons-solid/Bus'
import {Taxi} from '@styled-icons/boxicons-solid/Taxi'
import {Car} from '@styled-icons/boxicons-solid/Car'
import {CloseOutline} from '@styled-icons/evaicons-outline/CloseOutline'
import CustomIcon from 'components/Display/CustomIcon'
import VolunteerDetails from 'components/Page/Dashboard/Tickets/Tabs/VolunteerDetails'

export const ticketTabs = {
    elderDetails: ElderDetails,
    ticketDetails: TicketDetails,
    volunteerDetails: VolunteerDetails,
}

export const validatedFormFields = {
    ticketSource: [
        'issuer',
        'howDidYouHearAboutUs',
        'issuerPhone',
        'issuerName',
        'issuerSource',
    ],
    ticketDetails: [
        'category',
        'subCategory',
        'subCategoryOptions',
        'paymentType',
        'categoryLocationName',
        'categoryLocationAddress',
        'categoryLocationWorkingHours',
        'paymentType',
    ],
    ticketTime: ['durationInMinutes', 'availableTimes'],
    ticketRoute: [
        'startAddress',
        'endAddress',
        'destinationAddress',
        'street',
        'houseNumber',
        'city',
        'categoryLocationName',
        'categoryLocationAddress',
        'categoryLocationWorkingHours',
        'transportMethod',
    ],
}

export const referralTypes = [
    'city_hall',
    'social_worker',
    'relative',
    'friend',
    'social_networks',
    'flyer',
    'sms',
    'HMO',
    'other',
]

export const ticketIcons = {
    startAddress: LocationOnIcon,
    destinationAddress: FlagIcon,
    endAddress: LocationOnIcon,
    elder: () => <CustomIcon name="old-man" />,
    social_worker: () => <CustomIcon name="prayer" />,
    volunteer: () => <CustomIcon name="help-2" />,
    relative: () => <CustomIcon name="family" />,
    other: More,
    city_hall: City,
    friend: PeopleFill,
    social_networks: FacebookCircle,
    flyer: Receipt,
    sms: SmartphoneOutline,
    HMO: LocalHospital,
    indoor: Home,
    phone_assistance: Phone,
    escort: Route,
    delivery: DeliveryDining,
    indoor_assistance: Light,
    digital: Smartphone,
    social: People,
    nursing_care: SuitcaseMedical,
    remote_assistance: Headset,
    conversation: PhoneCall,
    medical_escort: HeartPulseFill,
    shopping: ShoppingCart,
    shopping_escort: ShoppingCart,
    social_escort: Cup,
    shopping_delivery: ShoppingCart,
    medicines: SuitcaseMedical,
    food_delivery: Shop,
    equipment: Tools,
    noPayment: CloseIcon,
    cash: CashStack,
    bit: SmartphoneOutline,
    bankTransfer: AccountBalanceIcon,
    coupon: CardMembershipIcon,
    elderHome: ElderHome,
    otherLocation: More,
    none: CloseOutline,
    walk: PersonWalking,
    public: Bus,
    taxi: Taxi,
    car: Car,
}

export const allowAddItems = ['social_worker', 'relative']
export const allowSearchItems = [...allowAddItems, 'volunteer']

export const ticketSourceTypes = [
    'ministryForSocialEquality',
    'otherOrganization',
    'friend',
    'neighbor',
    'careGiver',
    'other',
]

export const ticketTypeCategories = [
    'indoor',
    'phone_assistance',
    'escort',
    'delivery',
]

export const paymentTypes = [
    'noPayment',
    'cash',
    'bit',
    'bankTransfer',
    'coupon',
    'other',
]

export const ticketDurations = ['15', '30', '60', '90', '120', 'other']

export const ticketTimes = [
    'custom',
    '24_hours',
    '3_days',
    '7_days',
    'week_or_more',
]

export const daysCount = {
    '24_hours': 1,
    '3_days': 2,
    '7_days': 6,
    week_or_more: 14,
}

export const ticketTypes = {
    indoor: {
        indoor_assistance: [
            'change_lightbulb',
            'paper_organization',
            'gardening',
            'moving_furnitur',
            'climb_ladder',
            'dog_walk',
            'other',
        ],
        digital: [
            'general',
            'guidance',
            'appointment',
            'forms',
            'translation',
            'other',
        ],
        social: ['coffee', 'game', 'walkAroundTheHouse', 'other'],
        nursing_care: [],
    },
    phone_assistance: {
        remote_assistance: ['appointment', 'forms', 'other'],
        conversation: [],
    },
    escort: {
        medical_escort: ['doctor', 'checkups', 'surgery', 'vaccine', 'other'],
        shopping_escort: ['groceries', 'medicines', 'equipment', 'other'],
        social_escort: ['play', 'concert', 'postoffice', 'other'],
    },
    delivery: {
        shopping_delivery: ['small', 'large'],
        medicines: [],
        food_delivery: [],
        equipment: ['electric_equipment', 'medical', 'furniture', 'other'],
    },
}

export const dayTimeSlots = ['morning', 'noon', 'evening']

export const pickupLocations = ['elderHome', 'otherLocation']
export const transportMethods = ['walk', 'public', 'taxi', 'car']

export const ticketClassification = ['urgent', 'regular', 'weekly', 'future']

export const relationships = [
    'legal_guardian',
    'child',
    'spouse',
    'grandchild',
    'sibling',
    'other',
]

export const dashboardStatuses = [
    'draft',
    'waitingForVolunteer',
    'preCoordination',
    'preMeeting',
]

export const ticketStatuses = [...dashboardStatuses, 'canceled', 'completed']

export const cancellationInitiators = ['elder', 'call_center', 'volunteer']

export const cancellationReasons = [
    'volunteerNotFound',
    'solvedBeforeVolunteer',
    'solvedAfterVolunteer',
    'notRelevant',
    'notWorkingOut',
    'other',
]

export const ticketTimeType = {
    custom: 'regular',
    '24_hours': 'urgent',
    '3_days': 'regular',
    '7_days': 'weekly',
    week_or_more: 'future',
}

export const feedbackQuestions = [
    'contact_details',
    'opinions_change',
    'meaningfule_meetup',
    'community_engagement',
    'free_text',
]
