import {userSchemas} from 'services/utils/validations/validationSchemas'
import {yupResolver} from '@hookform/resolvers/yup'
import {DialogActions, DialogContent, styled} from '@mui/material'
import Button from 'components/Control/Button'

import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useCreateUserMutation, useI18n} from 'store/Store'
import {FormErrorText} from 'components/Control/Form'
import {phoneAlreadyExistsError} from 'services/utils/validations/validationUtils'
import {fieldsComponents, formFields} from 'constants/forms'
import {setValueOptions} from 'services/utils/formUtils'

const getFieldKey = (field) =>
    field.fieldType === 'multiple' || field.fieldType === 'address'
        ? field.mainInputName
        : field.key

const AddUserForm = ({type, onClose, showButtons = true, setIssuer}) => {
    const {i18n} = useI18n()
    const [errorMessage, setErrorMessage] = useState(null)

    const [doCreateUser, {error: createError, data: createResponse}] =
        useCreateUserMutation()

    const {
        control,
        formState: {errors: formErrors},
        handleSubmit,
        setError,
        setValue,
    } = useForm({
        defaultValues: {type},
        mode: 'all',
        resolver: yupResolver(userSchemas[type]),
    })

    const onSubmit = async (submittedData) => {
        const response = await doCreateUser(submittedData)
        setIssuer(response.data)
    }

    const submit = handleSubmit(onSubmit)

    useEffect(() => {
        if (createResponse) {
            onClose()
        }
    }, [createResponse, onClose])

    useEffect(() => {
        const error = createError?.data?.error
        if (error === 'user already exists') {
            setError('phone', phoneAlreadyExistsError, {shouldFocus: true})
        } else {
            setErrorMessage(error)
        }
    }, [createError, setError])

    const setRegion = (city) => {
        setValue('region', city, setValueOptions)
    }

    const setAddressValue = (address) => {
        setValue('address', {...address}, setValueOptions)
    }

    return (
        <DialogContent>
            {formFields[`${type}s`]?.map((field) => {
                const Component = fieldsComponents[field.fieldType]
                const key = getFieldKey(field)

                return (
                    <Component
                        key={field.key}
                        inputKey={field.key}
                        itemName="users"
                        name={key}
                        mainInputName={field.mainInputName}
                        label={i18n.t(`users.${field.labelKey ?? field.key}`)}
                        control={control}
                        required={field.required}
                        errors={formErrors}
                        options={field.options}
                        setAddressValue={setAddressValue}
                        setRegion={setRegion}
                        subInputs={field.subInputs}
                        subInputsSizing={field.subInputsSizing}
                        formatFunc={field.formatFunc}
                        optionKey={field.optionKey}
                    />
                )
            })}
            {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}

            {showButtons && (
                <ButtonsContainer>
                    <Button color="secondary" onClick={onClose}>
                        {i18n.t('form.cancel')}
                    </Button>
                    <Button onClick={submit}>{i18n.t('form.ok')}</Button>
                </ButtonsContainer>
            )}
        </DialogContent>
    )
}

export default AddUserForm

const ButtonsContainer = styled(DialogActions)`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 16px;
`
