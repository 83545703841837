import {ListItemButton} from '@mui/material'
import {cloneElement} from 'react'

const Row = ({index, data, style}) => {
    const item = data?.[index]
    const sx = {...style, direction: 'rtl'}
    return item && <ListItemButton sx={sx}>{cloneElement(item)}</ListItemButton>
}

export default Row
