import baseApi, {
    transformResponseFromData,
    // transformResponseFromMessage,
    // provideTags,
    // provideTag,
} from 'store/slices/api/baseApi'

const authApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCurrentUser: build.query({
            query: () => ({url: 'auth/getCurrentUser', method: 'GET'}),
            transformResponse: transformResponseFromData,
            providesTags: ['CurrentUser'],
        }),
        login: build.mutation({
            query: ({email, password}) => {
                return {
                    url: `auth/login`,
                    method: 'POST',
                    body: {email, password},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        phoneLogin: build.mutation({
            query: ({phone}) => {
                return {
                    url: `auth/phoneLogin`,
                    method: 'POST',
                    body: {phone},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        verify: build.mutation({
            query: (submittedData) => {
                console.log('verify data - mutation', submittedData)
                const {code, id, firstName, lastName} = submittedData

                return {
                    url: `auth/phoneVerify`,
                    method: 'POST',
                    body: {code, id, firstName, lastName},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        logout: build.mutation({
            query: () => ({
                url: `auth/logout`,
                method: 'POST',
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        signup: build.mutation({
            query: (signupData) => {
                return {
                    url: `auth/signup`,
                    method: 'POST',
                    body: signupData,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        signupWithInvite: build.mutation({
            query: ({submittedData, token}) => {
                return {
                    url: `auth/signup/:token`,
                    method: 'POST',
                    body: {...submittedData, token},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        forgotPassword: build.mutation({
            query: ({email}) => {
                console.log('signup mutation req', {email})
                return {
                    url: `auth/forgotPassword`,
                    method: 'POST',
                    body: {email},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        resetPassword: build.mutation({
            query: ({password, token}) => {
                console.log('signup mutation req', {password, token})
                return {
                    url: 'auth/resetPassword',
                    method: 'POST',
                    body: {password, token},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['CurrentUser'],
        }),
        setUserAccount: build.mutation({
            query: ({accountId, accountName}) => {
                console.log('switch currentUser over to', {
                    accountId,
                    accountName,
                })
                return {
                    url: 'auth/setUserAccount',
                    method: 'POST',
                    body: {accountId, accountName},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: [
                'CurrentUser',
                {type: 'User', id: 'LIST'},
                {type: 'Invite', id: 'LIST'},
            ],
        }),
    }),
    overrideExisting: false,
})

export default authApi

export const {
    useLoginMutation,
    usePhoneLoginMutation,
    useVerifyMutation,
    useGetCurrentUserQuery,
    useLogoutMutation,
    useSignupMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useSignupWithInviteMutation,
    useSetUserAccountMutation,
    // useGetVersionsQuery,
    // useStatsQuery,
    // useUpdateVersionsMutation,
} = authApi
