import {useI18n, useGetUsersQuery} from 'store/Store'
import {DataGrid} from '@mui/x-data-grid'
import {useMemo, useState} from 'react'
import {styled, Checkbox, FormControlLabel} from '@mui/material'
import {calculateDistanceInMeters} from 'services/utils/locationUtils'
import {timeSince} from 'services/utils/dateUtils'

const SendTicketToVoulnteersMessageStep = ({form, ticket}) => {
    const {i18n} = useI18n()

    const {
        data: volunteers,
        isFetching: isFetchingVolunteers,
        isError: isErrorVolunteers,
    } = useGetUsersQuery({
        type: 'volunteer',
        'address.city': ticket.startAddress?.city || ticket.elderCity,
        allowWhatsAppInquiries: true,
    })

    const {
        data: admins,
        isFetching: isFetchingAdmins,
        isError: isErrorAdmins,
    } = useGetUsersQuery({
        role: 'ADMIN',
    })

    const [filterGender, setFilterGender] = useState({
        male: true,
        female: true,
    })
    const [filterHasCar, setFilterHasCar] = useState(false)

    const combinedUsers = useMemo(() => {
        if (!volunteers?.data || !admins?.data) return []

        const {male, female} = filterGender

        return [...volunteers.data, ...admins.data].filter((user) => {
            const genderFilter =
                (user.gender === 'MALE' && male) ||
                (user.gender === 'FEMALE' && female)
            const hasCarFilter = !filterHasCar || user.hasCar

            return genderFilter && hasCarFilter
        })
    }, [volunteers, admins, filterGender, filterHasCar])

    if (isErrorVolunteers || isErrorAdmins) {
        return (
            <ErrorMessage>
                {i18n.t('sendToVolunteersForm.failedToFetchVolunteers')}
            </ErrorMessage>
        )
    }

    const handleGenderChange = (gender) => {
        setFilterGender((prev) => ({
            ...prev,
            [gender]: !prev[gender],
        }))
    }

    const handleHasCarChange = () => {
        setFilterHasCar((prev) => !prev)
    }

    return (
        <>
            <FilterContainer>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filterGender.male}
                            onChange={() => handleGenderChange('male')}
                        />
                    }
                    label={i18n.t('users.genders.MALE')}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filterGender.female}
                            onChange={() => handleGenderChange('female')}
                        />
                    }
                    label={i18n.t('users.genders.FEMALE')}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filterHasCar}
                            onChange={handleHasCarChange}
                        />
                    }
                    label={i18n.t('users.isCar')}
                />
            </FilterContainer>

            <VolunteersTable
                ticket={ticket}
                volunteers={
                    isFetchingVolunteers || isFetchingAdmins
                        ? []
                        : combinedUsers
                }
                loading={isFetchingVolunteers || isFetchingAdmins}
                form={form}
            />
        </>
    )
}

const VolunteersTable = ({volunteers, loading, form, ticket}) => {
    const {i18n} = useI18n()
    const columns = useMemo(
        () => [
            {
                field: 'fullName',
                headerName: i18n.t('sendToVolunteersForm.fullName'),
                sortable: false,
                width: 160,
                valueGetter: (params) =>
                    `${params.row.firstName || ''} ${
                        params.row.lastName || ''
                    }`,
            },
            {
                field: 'lastWhatsappMessage',
                headerName: i18n.t('users.lastWhatsappMessage'),
                sortable: true,
                type: 'date',
                valueFormatter: (params) =>
                    params.value ? timeSince(new Date(params.value)) : '-',
                width: 160,
            },
            {
                field: 'lastWhatsappMessageSubject',
                headerName: i18n.t('users.lastWhatsappMessageSubject'),
                valueGetter: (params) =>
                    params.row.lastMessageSubject
                        ? i18n.t(`tickets.${params.row.lastMessageSubject}`)
                        : '-',
                width: 160,
            },
            {
                field: 'distance',
                headerName: i18n.t('users.distance'),
                sortable: true,
                type: 'number',
                valueGetter: (params) =>
                    calculateDistanceInMeters(
                        params.row.address,
                        (ticket.startAddress?.lat
                            ? ticket.startAddress
                            : undefined) || ticket.elder.address
                    ),
                width: 160,
            },
            {
                field: 'created',
                headerName: i18n.t('users.creationDate'),
                sortable: true,
                type: 'date',
                valueFormatter: (params) =>
                    new Date(params.value).toLocaleDateString(),
                width: 160,
            },
            {
                field: 'address',
                headerName: i18n.t('volunteers.address'),
                sortable: false,
                width: 160,
                valueGetter: (params) =>
                    `${params.row.address?.street || ''} ${
                        params.row.address?.city || ''
                    }`,
            },
            {
                field: 'gender',
                headerName: i18n.t('users.gender'),
                sortable: true,
                width: 120,
                valueGetter: (params) =>
                    i18n.t(`users.genders.${params.row.gender}`) || '-',
            },
            {
                field: 'isCar',
                headerName: i18n.t('users.isCar'),
                sortable: true,
                width: 160,
                valueGetter: (params) =>
                    params.row.hasCar
                        ? i18n.t('common.yes')
                        : i18n.t('common.no'),
            },
            {
                field: 'role',
                headerName: i18n.t('users.roleType'),
                sortable: true,
                width: 120,
                valueGetter: (params) =>
                    params.row.role === 'ADMIN'
                        ? i18n.t('users.userTypes.ADMIN')
                        : i18n.t('users.userTypes.USER'),
            },
        ],
        [i18n, ticket.startAddress, ticket.elder.address]
    )

    return (
        <DataGrid
            loading={loading}
            density="compact"
            getRowId={(row) => row._id}
            rows={volunteers}
            columns={columns}
            initialState={{
                sorting: {
                    sortModel: [
                        {field: 'distance', sort: 'desc'},
                        {field: 'lastWhatsappMessage', sort: 'asc'},
                    ],
                },
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
            }}
            autoHeight
            pageSizeOptions={[5]}
            checkboxSelection
            sortingMode="client"
            paginationMode="client"
            onRowSelectionModelChange={(selectedVolunteers) => {
                form.setValue(
                    'volunteers',
                    selectedVolunteers.map((vId) =>
                        volunteers.find((v) => v._id === vId)
                    )
                )
            }}
        />
    )
}

const FilterContainer = styled('div')`
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
`

const ErrorMessage = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-block: 12px;
`

export default SendTicketToVoulnteersMessageStep
