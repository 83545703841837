import {Controller} from 'react-hook-form'
import {FormErrorText} from '.'
import AutocompleteInput from '../AutocompleteInput'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import {InputLabel, styled} from '@mui/material'
import {Colors, Texts} from 'appearance'
import {createAddressError} from 'services/utils/validations/validationUtils'
import {useState} from 'react'
import {GOOGLE_MAPS_API_KEY} from 'services/storage'

const CitiesListInput = ({
    itemName,
    name,
    label,
    control,
    errors,
    rules,
    required,
    disabled,
    key,
    setRegion,
}) => {
    const [inputValue, setInputValue] = useState('')

    const {placesService, placePredictions, getPlacePredictions} =
        usePlacesService({
            apiKey: GOOGLE_MAPS_API_KEY,
            language: 'he',
            options: {
                types: ['(cities)'],
                fields: ['address_components'],
                libraries: ['geocode', 'places', 'maps'],
                componentRestrictions: {
                    country: 'il',
                },
            },
        })

    const formatPlacesAddress = (addressComponents) => {
        const cityComponent = addressComponents.find((comp) =>
            comp.types.includes('locality')
        )
        return cityComponent?.long_name || ''
    }

    const handleSelect = (event, value) => {
        placesService?.getDetails(
            {
                placeId: value?.place_id,
            },
            (placeDetails) => {
                const city = formatPlacesAddress(
                    placeDetails.address_components
                )
                setRegion(city)
            }
        )
    }

    const getOptionLabel = (option) => {
        return option.structured_formatting?.main_text ?? option
    }

    const handleInputChange = (event, newInputValue) => {
        if (newInputValue === '') {
            setRegion(newInputValue)
        }

        setInputValue(newInputValue)
        getPlacePredictions({input: newInputValue})
    }

    const errorMessage = createAddressError(errors?.address)

    return (
        <Container>
            <Label required={required}>{label}</Label>
            <Controller
                name={name}
                control={control}
                rules={rules || {required}}
                render={({field: {value}}) => (
                    <AutocompleteInput
                        key={key}
                        itemName={itemName}
                        name={name}
                        defaultValue={value}
                        value={value}
                        onChange={handleSelect}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        options={placePredictions}
                        disabled={disabled}
                        getOptionLabel={getOptionLabel}
                        InputProps={{
                            style: {
                                height: '36px',
                            },
                        }}
                    />
                )}
            />
            {errors?.[name] && <FormErrorText>{errorMessage}</FormErrorText>}
        </Container>
    )
}

export default CitiesListInput

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: inherit;
`

export const Label = styled(InputLabel)`
    color: ${Colors.PRIMARY_COLOR};
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 4px;
`
export const TooltipText = styled(Texts.RegularText)`
    font-size: 12px;
`
