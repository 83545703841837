import {ADMIN_ROLES, adminManagedUserTypes} from 'constants/users'

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const isSuperUser = (user) =>
    user?.isSuper || user?.userAccounts?.[0]?.account?.isSuper

export const isAdmin = (user) => {
    if (!user) {
        return
    }
    const userAccounts = user?.accounts ? [user.account, ...user.accounts] : []
    const hasAdminAccount = userAccounts.some((acc) =>
        ADMIN_ROLES.includes(acc?.role)
    )
    return user?.isSuper || hasAdminAccount
}

export const isSpecialist = (user) => user?.type === 'specialist'

export const hasPermissions = (selectedType, user) => {
    const isUserTypeManaged = adminManagedUserTypes.includes(selectedType)
    const isAdminUser = isAdmin(user)

    return !isUserTypeManaged || isAdminUser
}

export const isAdminActions = (selectedType) =>
    ['specialist', 'admin'].includes(selectedType)
