import {styled} from '@mui/material'
import FormSelect from 'components/Control/Form/FormSelect'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {ticketSourceTypes} from 'constants/tickets'
import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'

const OtherTicketSource = () => {
    const {i18n} = useI18n()
    const {
        control,
        formState: {errors},
    } = useFormContext()

    return (
        <Container>
            <FormSelect
                itemName="tickets"
                name="issuerSource"
                label={i18n.t('ticketForm.ticketSource_choose_source')}
                control={control}
                fullWidth
                errors={errors}
                options={ticketSourceTypes}
            />
            <FormTextInput
                name="issuerName"
                label={i18n.t('ticketForm.ticketSources.name')}
                control={control}
                fullWidth
                errors={errors}
            />
            <FormTextInput
                name="issuerPhone"
                label={i18n.t('ticketForm.ticketSources.phone')}
                control={control}
                fullWidth
                errors={errors}
            />
        </Container>
    )
}

export default OtherTicketSource

const Container = styled('div')`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
    & > div {
        flex: 1;
    }
`
