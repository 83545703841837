import moment from 'moment'

export const isChecked = (urlParams, fieldName, value) => {
    const selected = urlParams[fieldName] || ''
    return selected.split(',').includes(value)
}

export const toggleFilters = (filters, value) => {
    if (filters.join(',') === value) {
        return
    }
    const index = filters.findIndex((filter) => filter.includes(value))

    if (index === -1) {
        filters.push(value)
    } else {
        filters.splice(index, 1)
    }
    return filters.filter((item) => item && item !== '').length
        ? filters.join(',')
        : undefined
}

const convertToMoment = (dateStr) =>
    moment.isMoment(dateStr) ? dateStr : moment(dateStr)

export const datesRangeArray = (startDate, endDate, format = 'YYYY-MM-DD') => {
    let dates = []
    let start = convertToMoment(startDate)
    const end = convertToMoment(endDate)
    while (start <= end) {
        dates.push(start.clone())
        start = start.add(1, 'days')
    }
    return dates.map((date) => date.format(format))
}

export const extractMainFilterKey = (array) => {
    const separators = ['.', '[']
    return array.map((item) =>
        separators.reduce((acc, sep) => acc.split(sep)[0], item)
    )
}

export const removeParams = (paramNames) => {
    const searchParams = new URLSearchParams(window.location.search)

    paramNames.forEach((paramName) => {
        if (searchParams.has(paramName)) {
            searchParams.delete(paramName)
        }
    })

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`

    window.history.replaceState(null, '', newUrl)

    const paramsObject = Array.from(searchParams.entries()).reduce(
        (obj, [key, value]) => {
            obj[key] = value
            return obj
        },
        {}
    )

    return paramsObject
}

export const formatEndDate = (date) => moment(date).endOf('day').toDate()
