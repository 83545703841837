import {Popover, TableSortLabel, styled} from '@mui/material'
import tables from 'constants/tables'
import {Filter} from '@styled-icons/fa-solid/Filter'
import {Colors} from 'appearance'
import {useState} from 'react'
import {useUrlParams} from 'services/hooks'
import {extractMainFilterKey} from 'components/Page/Dashboard/Tickets/Filters/utils'
import {useGetTicketsCitiesQuery} from 'store/slices/api/ticketsApi'
import {useGetUsersCitiesQuery} from 'store/Store'

const TableFilter = ({itemName, itemKey}) => {
    const tableName = `${itemName}s`
    const {urlParams} = useUrlParams()

    const isFilterActive = () => {
        const activeFilters = extractMainFilterKey(Object.keys(urlParams))
        return activeFilters.includes(itemKey)
    }

    const [anchorEl, setAnchorEl] = useState(null)

    const tableFilters = tables[tableName].filterComponents
    const filtersKeys = tableFilters ? Object.keys(tableFilters) : []

    const {data: elderCities} = useGetTicketsCitiesQuery(
        {},
        {skip: !filtersKeys.includes('elderCity')}
    )
    const {data: cities} = useGetUsersCitiesQuery(
        {
            type: itemName === 'elder' ? 'elder' : 'volunteer',
        },
        {skip: !filtersKeys.includes('address')}
    )

    if (!tables[tableName].filteredKeys?.includes(itemKey)) {
        return null
    }

    const FilterComponent = tableFilters?.[itemKey]

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <>
            <StyledTableSortLabel
                aria-describedby={itemKey}
                active={isFilterActive()}
                onClick={handleClick}
                IconComponent={() => <Filter size={12} />}
            />

            <Popover
                id={itemKey}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <FilterComponent
                    itemName={itemName}
                    itemKey={itemKey}
                    data={{
                        cities: itemName === 'ticket' ? elderCities : cities,
                    }}
                />
            </Popover>
        </>
    )
}

export default TableFilter

const StyledTableSortLabel = styled(TableSortLabel)`
    margin-inline: 4px;
    margin-bottom: 2px;
    .MuiTableSortLabel-icon {
        opacity: 1;
    }
    &.Mui-active {
        color: ${Colors.PRIMARY_COLOR};
    }
`
