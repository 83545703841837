import {styled} from '@mui/material'
import {Texts} from 'appearance'
import i18n from 'i18n-js'
import {formatPhone, getFullName} from 'services/utils/formUtils'
import Feedback from './Feedback'

const VolunteerDetails = ({volunteer, feedback}) => {
    return (
        <>
            <DetailsRow>
                <DetailName>{i18n.t('tickets.volunteerName')}:</DetailName>
                <DetailValue>{getFullName(volunteer)}</DetailValue>
            </DetailsRow>
            <DetailsRow>
                <DetailName paragraph>
                    {i18n.t('tickets.phoneNumber')}:
                </DetailName>
                <DetailValue paragraph>
                    {formatPhone(volunteer?.phone)}
                </DetailValue>
            </DetailsRow>

            {feedback?.map((feedbackItem) => (
                <DetailsRow style={{alignItems: 'start'}}>
                    <DetailName>{i18n.t('tickets.feedback')}:</DetailName>
                    <Feedback feedback={feedbackItem} />
                </DetailsRow>
            ))}
        </>
    )
}

export default VolunteerDetails

const DetailsRow = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;
`

const DetailName = styled(Texts.RegularText)`
    font-weight: 500;
    flex-shrink: 0;
`

const DetailValue = styled(Texts.RegularText)`
    font-weight: 300;
`
