import {React} from 'common'
import {Page, PageContent} from 'components/Page'
import ChatTemplateSender from 'components/Page/Dashboard/Volunteers/chats/ChatTemplateSender'
import {useGetUserQuery, useGetCustomerDataQuery} from 'store/Store'
import {useParams} from 'react-router-dom'
import {Texts} from 'appearance'
import Button from 'components/Control/Button'
import moment from 'moment'

const UserChats = () => {
    const {userId} = useParams()
    const {data, isFetching, isError} = useGetUserQuery({userId})
    const {
        data: landbotData,
        isFetching: landbotFetching,
        isError: landbotError,
    } = useGetCustomerDataQuery({phone: data?.phone}, {skip: !data?.phone})
    return (
        <Page menuTitle={'volunteers'}>
            <PageContent style={{padding: 56, paddingInline: 100}}>
                {isError || landbotError ? 'שגיאה בטעינה, נסה שנית' : null}
                {isFetching || landbotFetching ? (
                    'Loading...'
                ) : (
                    <UserChatsContent user={data} landbotData={landbotData} />
                )}
            </PageContent>
        </Page>
    )
}

const UserChatsContent = ({user, landbotData}) => {
    if (!user || !landbotData) return null
    return (
        <>
            <Texts.Title>
                {user.firstName} {user.lastName}
            </Texts.Title>
            <Texts.MediumText>
                תאריך הודעה אחרונה{' '}
                {moment(landbotData.data.last_message).format(
                    'DD/MM/YYYY HH:mm'
                )}
            </Texts.MediumText>
            <Texts.MediumText>
                הודעה אחרונה: {landbotData.data.last_message_content}
            </Texts.MediumText>
            <Texts.MediumText>טלפון: {user.phone}</Texts.MediumText>
            <Button
                onClick={() =>
                    window.open(`https://app.landbot.io/gui/chats_v4`)
                }>
                Landbot
            </Button>
            <ChatTemplateSender customerId={landbotData.data.id} />
        </>
    )
}

export default UserChats
