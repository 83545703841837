import {isAdmin, isSpecialist} from 'services/utils/misc'
import {React} from 'common'
import {Page, PageContent} from 'components/Page'
import DashboardStatus from 'components/Page/Dashboard/DashboardStatus'
import TicketsList from 'components/Page/Dashboard/Tickets/TicketsList'
import {useGetTicketsQuery} from 'store/slices/api/ticketsApi'
import {useGetCurrentUserQuery, useI18n, useLogoutMutation} from 'store/Store'
import {Texts} from 'appearance'
import styled from 'styled-components'
import Button from 'components/Control/Button'
import {useMemo, useState} from 'react'
import {dashboardStatuses} from 'constants/tickets'
import {useEffect} from 'react'

const Dashboard = () => {
    const {i18n} = useI18n()
    const [activeStatus, setActiveStatus] = useState('draft')
    const [activeTicket, setActiveTicket] = useState(null)

    const {data: user, error, isLoading} = useGetCurrentUserQuery()
    const {data: tickets, refetch: refetchTickets} = useGetTicketsQuery({
        status: dashboardStatuses,
        sort: JSON.stringify({created: 'desc'}),
    })
    const [doLogout] = useLogoutMutation()

    useEffect(() => {
        const delayFetch = setTimeout(() => {
            refetchTickets()
        }, 500)

        return () => clearTimeout(delayFetch)
    }, [refetchTickets])

    console.log('dashboard refresh - route2', {
        user,
        error,
        tickets: tickets?.data.filter((t) => t.status === 'draft'),
    })

    const onRowClick = (rowId) => {
        const newValue = activeTicket === rowId ? null : rowId
        setActiveTicket(newValue)
    }

    const closeTicketDetails = () => {
        setActiveTicket(null)
    }

    const filteredTickets = useMemo(() => {
        return (
            tickets?.data.filter(
                (ticket) => ticket.status === activeStatus && ticket.elder
            ) || []
        )
    }, [tickets, activeStatus])

    const statusesCount = tickets?.data?.reduce((acc, obj) => {
        const status = obj.status
        if (acc[status]) {
            acc[status]++
        } else {
            acc[status] = 1
        }
        return acc
    }, {})

    const onStatusClick = (status) => {
        setActiveStatus(status)
        closeTicketDetails()
    }

    if (!isLoading && !isAdmin(user) && !isSpecialist(user)) {
        return (
            <NoAccess>
                {i18n.t('auth.noAccess')}
                <Button variant="text" onClick={doLogout}>
                    {i18n.t('header.logout')}
                </Button>
            </NoAccess>
        )
    }
    return (
        <Page menuTitle={'dashboard'}>
            <PageContent>
                <DashboardStatus
                    statusesCount={statusesCount}
                    activeStatus={activeStatus}
                    onClick={onStatusClick}
                    closeTicketDetails={closeTicketDetails}
                />
                <TicketsList
                    tickets={filteredTickets}
                    activeTicket={activeTicket}
                    onRowClick={onRowClick}
                    closeTicketDetails={closeTicketDetails}
                    isAdmin={isAdmin(user)}
                />
            </PageContent>
        </Page>
    )
}

export default Dashboard

const NoAccess = styled(Texts.RegularText)`
    width: 100%;
    padding-top: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
`
