import {Divider, Icon} from '@mui/material'
import {Colors, Texts} from 'appearance'
import {ticketIcons} from 'constants/tickets'
import {useI18n} from 'store/Store'
import styled from 'styled-components'

const TabContentRow = ({rowData, rowKey, icon, showDivider = true}) => {
    const {i18n} = useI18n()

    return (
        <>
            <Row>
                {ticketIcons[rowKey] && (
                    <StyledIcon fontSize="inherit" component={icon} />
                )}
                <TextArea key={rowKey}>
                    <RowTitle>{i18n.t(`ticketForm.${rowKey}`)}</RowTitle>
                    <RowText>{rowData}</RowText>
                </TextArea>
            </Row>
            {showDivider && <Divider />}
        </>
    )
}

export default TabContentRow

const Row = styled('div')`
    display: flex;
    padding: 8px;
    gap: 24px;
    font-size: 18px;
`

const TextArea = styled('div')`
    display: flex;
    flex-direction: column;
`

const RowTitle = styled(Texts.RegularText)`
    color: ${Colors.APP_GRAY_LIGHT};
    font-size: 12px;
`
const RowText = styled(Texts.RegularText)`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
`

const StyledIcon = styled(Icon)`
    color: ${Colors.SECONDARY_COLOR};
`
