import {yupResolver} from '@hookform/resolvers/yup'
import {React} from 'common'
import Dialog from 'components/Display/DialogForm/Dialog'
import Table from 'components/Display/Table'
import {Page, PageContent} from 'components/Page'
import {useCallback, useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {isNew} from 'services/utils/formUtils'
import {accountSchema} from 'services/utils/validations/validationSchemas'
import {
    useDeleteAccountMutation,
    useGetAccountQuery,
    useGetAccountsQuery,
    useI18n,
} from 'store/Store'
import Account from './Account'
import AccountDialog from './AccountDialog'
import ConfirmationDialog from 'components/Display/ConfirmationDialog'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import ErrorDialog from 'components/Display/ErrorDialog'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const getId = (accountId, deleteAccount, accountDialogId) =>
    accountId || deleteAccount || accountDialogId

//TODO: this is basically the same as organizations - make them one component
const Accounts = () => {
    const {i18n} = useI18n()
    const [accountId, setAccountId] = useState()
    const [accountDialogId, setAccountDialogId] = useState()
    const [deleteAccount, setDeleteAccount] = useState()
    const [cannotDeleteAccount, setCannotDeleteAccount] = useState()

    const id = getId(accountId, deleteAccount, accountDialogId)
    const {data: account} = useGetAccountQuery(id, {
        skip: isNew(id),
    })

    const [doDeleteAccount] = useDeleteAccountMutation()

    const clearAccountId = useCallback(() => {
        setAccountId(null)
    }, [])

    const methods = useForm({
        defaultValues: {...account},
        mode: 'all',
        resolver: yupResolver(accountSchema),
    })

    const {reset} = methods

    useEffect(() => {
        if (account && id && id !== 'new') {
            reset({...account})
        } else {
            reset({})
        }
    }, [account, reset, id])

    const onDeleteAccount = (accountId) => {
        setDeleteAccount(accountId)
    }

    const handleDeleteAccount = async () => {
        try {
            const deleteResponse = await doDeleteAccount(deleteAccount)
            setDeleteAccount()
            clearAccountId()
            setAccountDialogId()

            if (
                deleteResponse?.error?.data?.error ===
                'cannot delete user with existing tickets'
            ) {
                setCannotDeleteAccount(deleteAccount)
                return
            }
        } catch (error) {
            console.log('delete account error', {error})
        }
    }

    const onEditFromDialog = (accountId) => {
        setAccountDialogId(null)
        setAccountId(accountId)
    }

    return (
        <Page menuTitle={'accounts'}>
            <PageContent>
                <Table
                    itemName="account"
                    allowEdit
                    allowDelete
                    deletePrompt
                    allowAdd
                    addFunction={() => setAccountId('new')}
                    editFunction={setAccountId}
                    deleteFunction={onDeleteAccount}
                    paginationType="external"
                    useGetQuery={useGetAccountsQuery}
                    useDeleteMutation={useDeleteAccountMutation}
                    onRowClick={setAccountDialogId}
                />

                <FormProvider {...methods}>
                    <Dialog
                        onClose={clearAccountId}
                        open={(!!accountId && !!account) || accountId === 'new'}
                        title={i18n.t(
                            `${
                                isNew(accountId)
                                    ? 'accounts.dialogTitle'
                                    : 'accounts.dialogTitleEdit'
                            }`
                        )}>
                        <Account
                            objectId={accountId}
                            handleClose={clearAccountId}
                            onDeleteObject={onDeleteAccount}
                            objectType="account"
                        />
                    </Dialog>
                </FormProvider>

                <Dialog
                    maxWidth="lg"
                    onClose={() => setAccountDialogId(null)}
                    open={!!accountDialogId}
                    title={i18n.t('accounts.accountDetailsDialogTitle', {
                        name: account?.name,
                    })}>
                    <AccountDialog
                        account={account}
                        handleEdit={onEditFromDialog}
                        handleClose={() => setAccountDialogId(null)}
                        onDeleteAccount={onDeleteAccount}
                    />
                </Dialog>

                <ConfirmationDialog
                    open={!!deleteAccount && account}
                    text={i18n.t(`users.deleteConfirmationText`, {
                        name: account?.name,
                    })}
                    onOk={handleDeleteAccount}
                    okButtonOptions={{
                        text: i18n.t('form.delete'),
                        icon: <DoNotDisturbIcon />,
                        color: 'error',
                    }}
                    handleClose={() => setDeleteAccount(null)}
                />

                <ErrorDialog
                    open={!!cannotDeleteAccount}
                    text={i18n.t('accounts.cannotDeleteError', {
                        name: account?.name,
                    })}
                    handleClose={() => setCannotDeleteAccount(null)}
                    buttonOptions={{
                        icon: <KeyboardArrowRightIcon />,
                        color: 'secondary',
                    }}
                />
            </PageContent>
        </Page>
    )
}

export default Accounts
