import moment from 'moment'

export const parseDate = ({
    day: d,
    month: m,
    year: y,
    format = 'YYYY.MM.DD',
}) => {
    const day = moment().day(d)
    const month = moment().month(m)
    const year = moment().year(y)

    return moment(`${year}-${month}-${day}`).format(format)
}

export const validateNumbersRange = (number, start, end) => {
    return number >= start && number <= end
}

export const hoursToMinutes = (value) => value && +value * 60

export const minutesToHours = (value) => value && +value / 60

export const formatPhone = (phone) => {
    if (phone && !phone.includes('+972')) {
        return phone
    }
    return phone ? `0${phone.split('+972')[1]}` : ''
}

export const getFullName = (user, name) => {
    const {firstName = '', lastName = ''} = user ?? {}
    return name ?? `${firstName} ${lastName}`.trim()
}

export const getFullAddress = (address) => {
    const {city = '', street = '', houseNumber = ''} = address ?? {}
    return `${street} ${houseNumber} ${city}`.trim()
}
export const formatAddressFloor = (address) => {
    if (!address || !address.floor) {
        return address
    }
    if (address.floor.toString().trim() === 'קרקע') {
        return {...address, floor: 0}
    } else if (isNaN(address.floor)) {
        const {floor, ...newAddress} = address
        return newAddress
    }
    return address
}

export const formatDate = (date, format) => date && moment(date).format(format)

export const parseObjectPath = (path, obj, separator = '.') => {
    if (!path || !obj) {
        return
    }
    try {
        return path.split(separator).reduce((a, v) => a[v], obj)
    } catch (err) {
        return
    }
}
export const prevenNonNumeric = (event) => {
    const notAllowed = ['Minus', 'Equal', 'Period', 'KeyE']
    if (notAllowed.includes(event.code)) {
        event.preventDefault()
    }
}

export const setValueOptions = {
    shouldDirty: true,
    shouldTouch: true,
    shouldValidate: true,
}

export const isNew = (id) => !id || id === 'new'

export const hideField = (showOnCreate, showOnEdit, id) => {
    if (id === 'new') {
        return showOnCreate === false
    }
    return showOnEdit === false
}

export const getFieldKey = (field) =>
    field.fieldType === 'multiple' || field.fieldType === 'address'
        ? field.mainInputName
        : field.key

export const getOkButtonText = (id, i18n) => {
    return id === 'new'
        ? i18n.t('form.saveAndContinue')
        : i18n.t('form.updateDetails')
}

export const getDateOption = (date) => {
    const today = moment().startOf('day')
    const dateStartOfDay = date.startOf('day')
    const diff = dateStartOfDay.diff(today, 'days')

    if (diff < 1) {
        return '24_hours'
    } else if (diff < 3) {
        return '3_days'
    } else if (diff < 6) {
        return '7_days'
    } else {
        return 'week_or_more'
    }
}

export const convertToISOString = (date) => {
    const dateObj = new Date(date)
    const adjustedTime = dateObj.getTime() - dateObj.getTimezoneOffset() * 60000
    return new Date(adjustedTime).toISOString()
}

export const setUserTypeInUserAccounts = (userAccounts, newType) => {
    if (!userAccounts) {
        return
    }
    const updatedUserAccounts = [...userAccounts]
    updatedUserAccounts[0].type = newType
    return updatedUserAccounts
}

export const getUserType = (user) => user?.userAccounts?.[0]?.type || user?.type
export const getUserRole = (user) => user?.userAccounts?.[0]?.role || user?.role

export const filterNonDOMProps = (props) => {
    // TODO: basically should remove all props from p that are not valid DOM props OR rebuild with only valid props (how to do seconed option?)
    // TODO: call this whenever {...p} is passed to low-level DOM elements
    const {
        withSubInputs,
        subInputsSizing,
        subInputs,
        itemName,
        mainInputName,
        setAddressValue,
        setFormValue,
        showOnCreate,
        inputKey,
        ...validDivProps
    } = props
    return validDivProps
}
