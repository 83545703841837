const en = {
    dir: 'ltr',
    header: {
        search: 'Search',
    },
    auth: {
        phone: 'Phone',
        name: 'Name',
        code: 'Code',
        welcome: 'Welcome to ',
        sendCode: 'Send Verification Code',
        noAccess:
            'Unauthorized access detected. Please contact the 20-80s back office to gain access to the system.',
    },
    menu: {
        dashboard: 'Dashboard',
        // organizations: 'Organizations',
        // organization: 'Organization',
        animals: 'Animals',
        all_mail: 'All mail',
        trash: 'Trash',
        spam: 'Spam',
        users: 'Users',
        accounts: 'Accounts',
    },
    form: {
        update: 'Update',
        create: 'Create',
        link: 'link',
        add: 'Add',
        keyError: 'valid {{key}} is required.',
        not: 'Not',
    },
    general: {
        invalid_password: 'Password must contain a minimum of 8 characters',
        invalid_verify_password: 'Passwords do not match',
    },
    tables: {
        users: 'Users',
        invites: 'Invites',
        accounts: 'Accounts',
    },
    accounts: {
        _id: 'ID',
        firstName: 'firstName',
        lastName: 'lastName',
        apiKey: 'Api Key',
        created: 'Created',
        updated: 'Updated',
        role: 'Role',
        confirmed: 'Confirmed',
    },
    users: {
        _id: 'ID',
        name: 'Name',
        email: 'Email',
        created: 'Created',
        role: 'Role',
    },
    invites: {
        _id: 'ID',
        name: 'Name',
        email: 'Email',
        created: 'Created',
        role: 'Role',
    },
    // organizations: {
    //     _id: 'ID',
    //     created: 'Created',
    //     updated: 'Updated',
    //     description: 'Description',
    //     name: 'Name',
    //     order: 'Order',
    //     address: 'Address',
    //     area: 'Area',
    //     contact: 'Contact',
    //     email: 'Email',
    //     facebookUrl: 'Facebook URL',
    //     homeUrl: 'Home Url',
    //     isActive: 'Active',
    //     lastActive: 'Last Active',
    //     lastAdminMsg: 'Last Admin Message',
    //     logoUrl: 'Logo URL',
    //     password: 'Password',
    //     phone: 'Phone',
    //     times: 'Times',
    //     username: 'Username',
    // },
}

export default en
