import {React} from 'common'
import {styled} from '@mui/material'
import {fieldsComponents, formFields} from 'constants/forms'
import {getFieldKey, getOkButtonText, hideField} from 'services/utils/formUtils'
import {
    useCreateAccountMutation,
    useI18n,
    useSaveAccountMutation,
    useCreateOrganizationMutation,
    useSaveOrganizationMutation,
} from 'store/Store'
import {useFormContext} from 'react-hook-form'
import {useState} from 'react'
import {FormErrorText} from 'components/Control/Form'
import DialogButtons from 'components/Page/Dashboard/Tickets/NewTicket/NewTicketActions'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import EditIcon from '@mui/icons-material/Edit'

const Account = ({objectId, handleClose, onDeleteObject, objectType}) => {
    const {i18n} = useI18n()

    const [errorMessage, setErrorMessage] = useState()

    const [doCreateAccount] = useCreateAccountMutation()
    const [doUpdateAccount] = useSaveAccountMutation()
    const [doCreateOrganization] = useCreateOrganizationMutation()
    const [doUpdateOrganization] = useSaveOrganizationMutation()

    const formContext = useFormContext()
    const {
        control: formControl,
        formState: {errors: formErrors},
        handleSubmit,
        setValue,
    } = formContext

    const setCity = (city) => {
        setValue('city', city)
    }
    const onSubmit = async (values) => {
        let response
        if (objectId && objectId !== 'new') {
            if (objectType === 'account') {
                response = await doUpdateAccount(values)
            } else {
                response = await doUpdateOrganization(values)
            }
        } else {
            if (objectType === 'account') {
                response = await doCreateAccount(values)
            } else {
                response = await doCreateOrganization(values)
            }
        }

        if (response?.error) {
            setErrorMessage(response?.error?.data?.error)
            return
        }
        handleClose()
    }
    const deleteObject = () => {
        onDeleteObject(objectId)
    }
    const submit = handleSubmit(onSubmit)

    return (
        <Container>
            {formFields[objectType]?.map((field) => {
                const Component = fieldsComponents[field.fieldType]
                const key = getFieldKey(field)

                if (hideField(field.showOnCreate, field.showOnEdit, objectId)) {
                    return <></>
                }
                return (
                    <Component
                        showOnCreate={field.showOnCreate || objectId !== 'new'}
                        key={field}
                        itemName={objectType + 's'}
                        name={key}
                        mainInputName={field.mainInputName}
                        label={i18n.t(
                            `${objectType}s.${field.labelKey ?? field.key}`
                        )}
                        control={formControl}
                        required={field.required}
                        errors={formErrors}
                        subInputs={field.subInputs}
                        subInputsSizing={field.subInputsSizing}
                        formatFunc={field.formatFunc}
                        optionKey={field.optionKey}
                        setRegion={setCity}
                    />
                )
            })}
            {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}
            <DialogButtons
                okButtonOptions={{
                    text: getOkButtonText(objectId, i18n),
                    icon: <EditIcon />,
                }}
                cancelButtonOptions={
                    objectId !== 'new' && {
                        text: i18n.t('form.delete'),
                        icon: <DisabledByDefaultIcon />,
                        color: 'error',
                    }
                }
                onOk={submit}
                onCancel={deleteObject}
            />
        </Container>
    )
}

export default Account

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    overflow: auto;
`
