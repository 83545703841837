const colors = {
    APP_BACKGROUND: '#F1F1F4',
    APP_WHITE: '#fefefe',
    APP_BLACK: '#000',
    APP_GRAY: '#f4f4f4',
    APP_GRAY_LIGHT: '#A1A1BD',
    APP_GRAY_DARK: '#525268',
    SECONDARY_COLOR: '#757575',
    BORDER: '#DFDFDF',

    PRIMARY_COLOR: '#416CFE',
    PRIMARY_TEXT: '#45484b',
    ERROR_TEXT: '#DB4437',
    SUCCESS: '#12A746',

    TICKET_TYPE_regular: '#757575',
    TICKET_TYPE_urgent: '#FF3333',
    TICKET_TYPE_weekly: '#FFDD1A',
    TICKET_TYPE_future: '#ffa500',
}

export default colors
