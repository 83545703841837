import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const chatbotApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getSentChats: build.query({
            query: ({ticketId}) => {
                const url = constructUrl(
                    `chatbot/conversation/ticket/${ticketId}/messages`,
                    {}
                )

                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('ChatbotConversation'),
        }),
        getCustomerData: build.query({
            query: ({phone}) => {
                const url = constructUrl(
                    `chatbot/conversation/customer/${phone}`,
                    {}
                )

                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
        }),
        getTemplates: build.query({
            query: () => {
                const url = constructUrl(`chatbot/conversation/templates`, {})

                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
        }),
        sendMessages: build.mutation({
            query: (payload) => {
                console.log('save user mutation req', payload)
                return {
                    url: `chatbot/conversation/sendMessage/helpRequest`,
                    method: 'POST',
                    body: payload,
                }
            },
            transformResponse: transformResponseFromData,
            // invalidatesTags: ['User', 'Ticket'],
        }),
        sendMessageTempalteToCustomer: build.mutation({
            query: (payload) => {
                return {
                    url: `chatbot/conversation/sendMessage/template/${payload.customerId}`,
                    method: 'POST',
                    body: payload.body,
                }
            },
            transformResponse: transformResponseFromData,
            // invalidatesTags: ['User', 'Ticket'],
        }),
        triggerStartBot: build.mutation({
            query: (payload) => {
                return {
                    url: `chatbot/conversation/triggerStartBot`,
                    method: 'PUT',
                    body: payload,
                }
            },
            transformResponse: transformResponseFromData,
        }),
    }),
    overrideExisting: false,
})

export default chatbotApi

export const {
    useGetSentChatsQuery,
    useSendMessagesMutation,
    useSendMessageTempalteToCustomerMutation,
    useGetTemplatesQuery,
    useGetCustomerDataQuery,
    useTriggerStartBotMutation,
} = chatbotApi
