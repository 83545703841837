import {styled} from 'common'
import {Styles} from 'appearance'

const PageContent = styled.div`
    width: 100%;
    padding: ${Styles.ROW_PADDING} ${Styles.SIDE_PADDING};
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: ${`calc(100vh - 64px)`};

    ${({centered}) =>
        centered &&
        `
        align-items: center;
    `}

    ${({flexed}) =>
        flexed &&
        `
        flex: 1;
    `}
`
export default PageContent
