const Styles = {
    SIDE_PADDING: '20px',
    ROW_PADDING: '10px',
    DRAWER_WIDTH: 240,
    DRAWER_WIDTH_CLOSED: 65,
    SMALL_DROP_SHADDOW: 'box-shadow: 0px 2px 16px rgba(21, 13, 67, 0.06)',
    BIG_SHADDOW: 'box-shadow: 0px 4px 24px rgba(21, 13, 67, 0.12)',
}

export default Styles
