import baseApi, {
    transformResponseFromData,
    // transformResponseFromMessage,
    // provideTags,
    provideTag,
} from 'store/slices/api/baseApi'

const invitesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        sendInvite: build.mutation({
            query: ({accountId, email, role}) => {
                console.log(
                    `inviting ${email} to accountId ${accountId} as ${role}`
                )
                return {
                    url: 'invites',
                    method: 'POST',
                    body: {accountId, email, role},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Invite', result),
        }),
        deleteInvite: build.mutation({
            query: (id) => {
                console.log(`deleting invitedId ${id}`)
                return {
                    url: `invites/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Invite'),
        }),
        getInvites: build.query({
            query: () => ({
                url: `invites`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Invite'),
        }),
    }),
})

export default invitesApi

export const {
    useSendInviteMutation,
    useGetInvitesQuery,
    useDeleteInviteMutation,
} = invitesApi
