import {
    Dialog as RawDialog,
    DialogTitle,
    IconButton,
    styled,
} from '@mui/material'
import {useIsRtl} from 'store/Store'
import CloseIcon from '@mui/icons-material/Close'
import {Texts} from 'appearance'

const Dialog = ({
    open,
    onClose,
    children,
    title,
    titlePadding,
    maxWidth,
    ...p
}) => {
    const isRtl = useIsRtl()

    return (
        <RawDialog
            {...p}
            maxWidth={maxWidth}
            onClose={onClose}
            open={open}
            fullWidth
            dir={isRtl ? 'rtl' : 'ltr'}>
            <StyledDialogTitle style={{padding: titlePadding}}>
                <Title>{title}</Title>
                <CloseIconButton onClick={onClose}>
                    <CloseIcon style={{height: 18, width: 18}} />
                </CloseIconButton>
            </StyledDialogTitle>
            {children}
        </RawDialog>
    )
}

export default Dialog

const Title = styled(Texts.Title)`
    font-size: 28px;
`

const StyledDialogTitle = styled(DialogTitle)`
    display: flex;
    justify-content: space-between;
`

const CloseIconButton = styled(IconButton)`
    border-radius: 0;
    height: 30px;
    width: 30px;
    background: rgba(0, 0, 0, 0.08);
    &:hover {
        background: rgba(0, 0, 0, 0.08);
    }
`
