import {yupResolver} from '@hookform/resolvers/yup'
import {styled} from '@mui/material'
import {Texts} from 'appearance'
import Button from 'components/Control/Button'
import InfiniteScrollAutocomplate from 'components/Control/InfiniteScroll/InfiniteScrollAutocomplate'
import useDebounce from 'logic/Forms/useDebounce'
import {useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {
    formatPhone,
    getFullName,
    setValueOptions,
} from 'services/utils/formUtils'
import {assignVolunteer} from 'services/utils/validations/validationSchemas'
import {useSaveTicketMutation} from 'store/slices/api/ticketsApi'
import {useGetVolunteersQuery, useI18n} from 'store/Store'

const AsignVolunteerToTicket = ({onClose, ticketData}) => {
    const {i18n} = useI18n()

    const pageSize = 20
    const [page, setPage] = useState(0)

    const form = useForm({
        defaultValues: {volunteer: ticketData?.volunteers[0]},
        mode: 'all',
        resolver: yupResolver(assignVolunteer),
    })

    const {
        control,
        formState: {errors, isValid},
        handleSubmit,
        watch,
        setValue,
    } = form

    const volunteerValue = watch('volunteer')
    const [searchValue, setSearchValue] = useState()
    const [volunteerToDelete, setVolunteerToDelete] = useState()
    const [showConfirmation, setShowConfirmation] = useState(false)
    const debouncedValue = useDebounce(searchValue, 500)

    const [doSaveTicket] = useSaveTicketMutation()
    const {data: volunteers, isFetching} = useGetVolunteersQuery(
        {
            search: debouncedValue,
            pageSize,
            page,
        },
        {skip: !debouncedValue || !debouncedValue?.length}
    )

    const formatUser = (user) => {
        return typeof user === 'object'
            ? `${getFullName(user)} (${formatPhone(user?.phone)})`
            : user
    }

    const handleInputChange = (event, value) => {
        setPage(0)
        setSearchValue(value)
    }

    const onSubmit = ({volunteer}) => {
        doSaveTicket({
            ...ticketData,
            volunteers: volunteer._id === 'unassign' ? [] : [volunteer._id],
        })
        onClose()
    }

    const submit = handleSubmit(onSubmit)

    const onClickContinue = () => {
        setShowConfirmation(true)
    }

    const handleSelectVolunteer = (event, value) => {
        console.log('value', value)
        setValue('volunteer', value, setValueOptions)
        setSearchValue()
    }

    const handleUnassignVolunteer = () => {
        setVolunteerToDelete(volunteerValue)
        setValue('volunteer', {_id: 'unassign'}, setValueOptions)
        setSearchValue()
        setShowConfirmation(true)
    }

    return (
        <Container>
            <Content>
                {showConfirmation ? (
                    <Texts.RegularText>
                        {volunteerToDelete
                            ? `האם את/ה בטוח/ה שברצונך להסיר את ${formatUser(
                                  volunteerToDelete
                              )} מפניה זו?`
                            : `האם את/ה בטוח/ה שברצונך לשייך את ${formatUser(
                                  volunteerValue
                              )} לפניה זו?`}
                    </Texts.RegularText>
                ) : (
                    <FormProvider {...form}>
                        <InfiniteScrollAutocomplate
                            key="volunteer"
                            name="volunteer"
                            control={control}
                            errors={errors}
                            defaultValue={ticketData?.volunteers[0]}
                            getOptionLabel={formatUser}
                            label={i18n.t(`tickets.search_exising`, {
                                item: i18n.t(`tickets.volunteer`),
                            })}
                            InputProps={{
                                style: {height: '36px', padding: '10px'},
                            }}
                            handleSelectValue={handleSelectVolunteer}
                            handleInputChange={handleInputChange}
                            inputValue={searchValue}
                            page={page}
                            setPage={setPage}
                            pageSize={pageSize}
                            options={volunteers?.data}
                            total={volunteers?.total}
                            isLoading={isFetching}
                        />
                    </FormProvider>
                )}
            </Content>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    {i18n.t('form.cancel')}
                </Button>
                {!showConfirmation && (
                    <Button color="secondary" onClick={handleUnassignVolunteer}>
                        {i18n.t('tickets.unassignVolunteer')}
                    </Button>
                )}
                <Button
                    onClick={showConfirmation ? submit : onClickContinue}
                    disabled={!isValid}>
                    {i18n.t(`form.${showConfirmation ? 'ok' : 'continue'}`)}
                </Button>
            </DialogActions>
        </Container>
    )
}

export default AsignVolunteerToTicket

const Container = styled('div')`
    padding: 12px;
`

const Content = styled('div')``

const DialogActions = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`
