import {React, styled} from 'common'
import {Controller} from 'react-hook-form'
import {FormErrorText} from 'components/Control/Form'

import {TextField} from '@mui/material'
import moment from 'moment'

const FormCalendarInput = ({
    name,
    label,
    control,
    errors,
    required,
    type,
    disabled,
}) => {
    const calendarType = type === 'date' ? 'date' : 'datetime-local'
    const dateFormat = type === 'date' ? 'yyyy-MM-DD' : 'yyyy-MM-DDTHH:mm'
    return (
        <Container>
            <Controller
                name={name}
                control={control}
                rules={{required}}
                render={({field: {onChange, value}}) => {
                    return (
                        <TextField
                            id={`transactions-${label}`}
                            label={label}
                            type={calendarType}
                            defaultValue={moment(value).format(dateFormat)}
                            sx={{width: 220}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                            onChange={(e) => {
                                const d = e.target.value
                                if (
                                    moment(d, 'YYYY-M-D').isAfter(
                                        moment('2000', 'YYYY')
                                    )
                                ) {
                                    onChange(
                                        moment(d, 'YYYY-M-D').format(dateFormat)
                                    )
                                }
                            }}
                            disabled={disabled}
                        />
                    )
                }}
            />
            {errors[name] && (
                <FormErrorText>valid name is required.</FormErrorText>
            )}
        </Container>
    )
}

export default FormCalendarInput

const Container = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
`

// import {React} from 'common'
// import {TextField} from '@mui/material'
// import moment from 'moment'

// const DatePicker = ({label, setDate}) => {
//     return (
//         <TextField
//             id={`transactions-${label}`}
//             label={label}
//             type="date"
//             defaultValue={null}
//             sx={{width: 220}}
//             InputLabelProps={{
//                 shrink: true,
//             }}
//             onChange={(e) => {
//                 const d = e.target.value
//                 if (moment(d, 'YYYY-M-D').isAfter(moment('2000', 'YYYY'))) {
//                     setDate(moment(d, 'YYYY-M-D'))
//                     console.log('from', e.target.value)
//                 }
//             }}
//         />
//     )
// }

// export default DatePicker
