import {createTheme} from '@mui/material'
import {Colors} from 'appearance'
import {heIL} from '@mui/material/locale'

const theme = createTheme(
    {
        direction: 'rtl',
        status: {
            danger: '#e53e3e',
        },
        palette: {
            primary: {
                main: Colors.PRIMARY_COLOR,
                darker: '#053e85',
            },
            secondary: {
                main: Colors.SECONDARY_COLOR,
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff',
            },
            header: {
                main: '#fff',
            },
            white: {
                main: '#fff',
            },
            dark: {
                main: '#413839',
                contrastText: '#fff',
            },
        },
        typography: {
            fontFamily: `'Open Sans', sans-serif`,
        },
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    popper: {
                        direction: 'rtl',
                    },
                },
            },
        },
    },
    heIL
)

export const ltrTheme = createTheme({
    ...theme,
    direction: 'ltr',
})

export default theme
