import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'

// import {composeWithDevTools} from 'redux-devtools-extension'

import {createLogger} from 'redux-logger'

import baseApi from './slices/api/baseApi'
import app from './slices/app'

import rtkQueryErrorHandler from './middleware/rtkQueryErrorHandler'

// create and filter specific actions
const logger = createLogger({
    collapsed: true,
    predicate: (getState, action) =>
        process.env.REACT_APP_REDUX_DEVTOOLS === 'true' &&
        action.type !== 'SET_PROCESSING',
})

const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
        app,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(logger)
            .concat(baseApi.middleware)
            .concat(rtkQueryErrorHandler),
    // devTools: false, (or .env...) - did not work
})

setupListeners(store.dispatch)

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export const RootState = typeof store.getState
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export const AppDispatch = typeof store.dispatch

export * from './slices/api/authApi'
export * from './slices/api/accountsApi'
export * from './slices/api/organizationsApi'
export * from './slices/api/usersApi'
export * from './slices/api/invitesApi'
export * from './slices/api/chatbotApi'
export * from './slices/app'

export default store
