import {DialogActions, DialogContent, styled} from '@mui/material'
import {Texts} from 'appearance'
import Button from 'components/Control/Button'
import {useI18n} from 'store/Store'

const TicketDeleteAlert = ({onClose, onOk}) => {
    const {i18n} = useI18n()

    return (
        <Container>
            <Content>
                <Title>{i18n.t('tickets.deleteTicketAlertTitle')}</Title>
                <Text>{i18n.t('tickets.deleteTicketAlertDescription')}</Text>
            </Content>
            <Actions disableSpacing>
                <Button color="secondary" onClick={onClose}>
                    {i18n.t('form.cancel')}
                </Button>
                <Button color="error" onClick={onOk}>
                    {i18n.t('tickets.deleteTicketButton')}
                </Button>
            </Actions>
        </Container>
    )
}

export default TicketDeleteAlert

const Container = styled('div')``

const Content = styled(DialogContent)`
    padding: 0 12px;
    padding-bottom: 24px;
`

const Title = styled(Texts.BoldText)`
    font-size: 18px;
`

const Text = styled(Texts.RegularText)``

const Actions = styled(DialogActions)`
    justify-content: space-between;
    padding: 12px;
`
