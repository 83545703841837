import {useI18n} from 'store/Store'
import {
    ColumnContainer,
    Title,
} from '../../../../Display/Table/TableTitleAndFilters/filtersStyles'
import {useUrlParams} from 'services/hooks'
import Checkbox from 'components/Control/Checkbox'
import {isChecked, toggleFilters} from './utils'
import ChipSelect from 'components/Control/ChipSelect'

const City = ({itemKey, data: {cities}}) => {
    const {i18n} = useI18n()
    const {urlParams, setUrlParam} = useUrlParams()

    const onChange = (fieldName) => (event) => {
        updateFiltersParams(fieldName, event.target.name)
    }

    const onDeleteChip = (values) => {
        let filters

        if (values.length === 1) {
            filters = values[0]
        } else if (values.length > 1) {
            filters = values.join(',')
        }

        setUrlParam('elderCity', filters)
    }

    const updateFiltersParams = (fieldName, value) => {
        const filters = urlParams[fieldName]?.split(',') || []
        const newFilters = toggleFilters(filters, value)
        setUrlParam(fieldName, newFilters)
    }

    const renderOption = (props, option) => {
        return (
            <Checkbox
                key={props['data-option-index']}
                data-testid={`option_${props['data-option-index'] + 1}`}
                name={option}
                checked={isChecked(urlParams, 'elderCity', option)}
                onChange={onChange('elderCity')}
                value={option}
                label={option}
            />
        )
    }

    const getOptionLabel = (option) => option

    const selectedCities = urlParams['elderCity']?.split(',')

    return (
        <ColumnContainer>
            <Title>{i18n.t('users.address.city')}</Title>

            <ChipSelect
                disableClearable
                itemName="city"
                name="city-filter"
                value={selectedCities}
                onChange={onDeleteChip}
                options={cities}
                getOptionLabel={getOptionLabel}
                renderOptionFunc={renderOption}
                filterSelectedOptions={false}
                enableDeleteChip
            />
        </ColumnContainer>
    )
}

export default City
