import * as yup from 'yup'
import 'yup-phone'
import {
    isIsraeliIdValid,
    showLocationInputs,
    showSelectAssistance,
} from './validationUtils'
import moment from 'moment'

const requiredText = 'שדה חובה'
const invalidIsraeiId = 'מספר ת.ז לא תקין'
const emailError = 'כתובת מייל לא תקינה'
const numberError = 'ערכים מספריים בלבד'
const floorError = 'קומה לא תקינה'

const phoneSchema = yup.string().phone('IL', false, 'מספר הטלפון לא תקין')

const requiredPhone = yup.lazy((value) => {
    if (!value || value.trim() === '') {
        return yup.string().required(requiredText)
    }
    return phoneSchema
})
const notRequiredPhoneSchema = yup.lazy((value) => {
    if (!value || value.trim() === '') {
        return yup.string().notRequired()
    }
    return phoneSchema
})

// const floorSchema2 = yup.lazy((value) => {
//     if (!value || value.trim() === '' || value === 'קרקע') {
//         return yup.string().notRequired()
//     }
//     return yup.number().required('asjdajsh ahs ')
// })
const floorSchema = yup
    .string()
    .test('check valid floor', floorError, (value) => {
        if (!value || value.trim() === '' || value === 'קרקע') return true
        return !isNaN(value) // TODO: couldn't get yup.number() correctlly
    })

const addressSchemaRequired = yup.object().shape({
    street: yup.string().required('נדרש'),
    houseNumber: yup.string().required('נדרש'),
    city: yup.string().required('נדרש'),
    floor: floorSchema,
})
const addressSchema = yup.object().shape({
    street: yup.string(),
    houseNumber: yup.string(),
    city: yup.string(),
    name: yup.string(),
    workingHours: yup.string().notRequired(),
})

const israeliIdSchema = yup
    .string()
    .test('check israeli id', invalidIsraeiId, (value) =>
        isIsraeliIdValid(value)
    )

const over18 = yup
    .string()
    .test('birth date over 18', 'חייב להיות מעל גיל 18', (value) => {
        return moment().diff(moment(value), 'years') >= 18
    })

const onlyNumbers = yup.number().typeError('מספרים בלבד')

// Custom methods
yup.addMethod(yup.number, 'emptyNumber', function () {
    return this.transform((value, originalValue) =>
        originalValue === '' ? undefined : value
    )
})

export const loginPhoneSchema = yup.object({
    phone: phoneSchema.required(requiredText),
})

export const verifyCodeSchema = yup.object({
    code: yup.string().required(requiredText),
})

export const registerSchema = yup.object({
    firstName: yup.string().when('$source', (source, schema) => {
        if (source === 'signup') {
            return schema.required(requiredText)
        }
        return yup.string().notRequired()
    }),
    lastName: yup.string().when('$source', (source, schema) => {
        if (source === 'signup') {
            return schema.required(requiredText)
        }
        return yup.string().notRequired()
    }),
    code: yup.string().required(requiredText),
})

export const elderSchema = yup.object({
    firstName: yup.string().required(requiredText),
    lastName: yup.string().required(requiredText),
    phone: phoneSchema.required(requiredText),
    additionalPhone: yup.lazy((additionalPhone) => {
        if (!additionalPhone || additionalPhone.trim() === '') {
            return yup.string().notRequired()
        }
        return phoneSchema
    }),
    gender: yup.string().required(requiredText),
    address: addressSchemaRequired.required(requiredText),
    birthDate: yup.date().typeError('תאריך לא תקין'),
    underCare: yup.string().required(requiredText),
})

export const ticketSchema = yup.object({
    issuer: yup.object().when('issuerType', {
        is: (val) => val === 'other',
        then: yup.object().nullable().notRequired(),
        otherwise: yup.object().nullable().required(requiredText),
    }),
    issuerSource: yup.string().when('issuerType', {
        is: (val) => val === 'other',
        then: yup.string().required(requiredText),
    }),
    issuerName: yup.string().when('issuerType', {
        is: (val) => val === 'other',
        then: yup.string().required(requiredText),
    }),
    issuerPhone: yup.string().when('issuerType', {
        is: (val) => val === 'other',
        then: yup.string().required(requiredText),
    }),
    howDidYouHearAboutUs: yup.string().required(requiredText),
    category: yup.string().required(requiredText),
    subCategory: yup.string().required(requiredText),
    subCategoryOptions: yup.array().when(['category', 'subCategory'], {
        is: showSelectAssistance,
        then: yup.array().min(1, 'חובה לבחור לפחות אחד'),
    }),
    categoryLocationName: yup.string().when(['category', 'subCategory'], {
        is: showLocationInputs,
        then: yup.string(),
    }),
    categoryLocationAddress: yup.object().when(['category', 'subCategory'], {
        is: showLocationInputs,
        then: addressSchema.nullable(),
    }),
    categoryLocationWorkingHours: yup
        .string()
        .when(['category', 'subCategory'], {
            is: showLocationInputs,
            then: yup.string(),
        }),
    paymentType: yup.string().required(requiredText),
    durationInMinutes: yup
        .number()
        .typeError(numberError)
        .required(requiredText),
    availableTimes: yup.lazy((availableTimes) => {
        if (!availableTimes || !availableTimes[0]) {
            return yup.mixed().required(requiredText)
        }

        const ticketTime = availableTimes[0].ticketTime

        if (ticketTime === 'custom') {
            return yup.array().of(
                yup.object({
                    date: yup.string().required(requiredText),
                    time: yup.string().required(requiredText),
                })
            )
        }

        return yup.array().test('availableTimes', requiredText, () => {
            return availableTimes.some((dayObj) =>
                Object.values(dayObj).some((daySlot) => daySlot === true)
            )
        })
    }),
    startAddress: yup.lazy((values, {parent}) => {
        if (!showLocationInputs(parent?.category, parent?.subCategory)) {
            return yup.mixed()
        }
        return addressSchema
    }),
    destinationAddress: yup.lazy((values, {parent}) => {
        if (!showLocationInputs(parent?.category, parent?.subCategory)) {
            return yup.mixed()
        }
        return addressSchema
    }),

    endAddress: yup.lazy((values, {parent}) => {
        if (!showLocationInputs(parent?.category, parent?.subCategory)) {
            return yup.mixed()
        }
        if (parent.endAddressType === 'none') {
            return yup.object().nullable()
        }
        return addressSchema.nullable()
    }),
    transportMethod: yup.string().when(['category', 'subCategory'], {
        is: showLocationInputs,
        then: yup.string().required(requiredText),
    }),
})

export const volunteerSchema = yup.object({
    firstName: yup.string().required(requiredText),
    lastName: yup.string().required(requiredText),
    email: yup.string().email(emailError),
    phone: phoneSchema.required(requiredText),
    additionalPhone: yup.lazy((additionalPhone) => {
        if (!additionalPhone || additionalPhone.trim() === '') {
            return yup.string().notRequired()
        }
        return phoneSchema
    }),
    gender: yup.string().required(requiredText),
    address: addressSchemaRequired,
    birthDate: yup.lazy((birthdate) => {
        if (!birthdate || birthdate.trim() === '') {
            return yup.string().notRequired()
        }
        return over18
    }),
    status: yup.string().required(requiredText),
})

export const socialWorkerSchema = yup.object({
    firstName: yup.string().required(requiredText),
    lastName: yup.string().required(requiredText),
    email: yup.string().email('כתובת מייל לא תקינה'),
    phone: phoneSchema.required(requiredText),
    additionalPhone: yup.lazy((additionalPhone) => {
        if (!additionalPhone || additionalPhone.trim() === '') {
            return yup.string().notRequired()
        }
        return phoneSchema
    }),
    gender: yup.string().required(requiredText),
})

export const adminSchema = yup.object({
    firstName: yup.string().required(requiredText),
    lastName: yup.string().required(requiredText),
    email: yup.string().email('כתובת מייל לא תקינה').required(requiredText),
    phone: requiredPhone,
    nationalId: israeliIdSchema.required(requiredText),
})

export const specialistSchema = yup.object({
    firstName: yup.string().required(requiredText),
    lastName: yup.string().required(requiredText),
    nationalId: israeliIdSchema.required(requiredText),
    phone: requiredPhone,
    email: yup.string().email('כתובת מייל לא תקינה').required(requiredText),
})

export const relativeSchema = yup.object({
    firstName: yup.string().required(requiredText),
    lastName: yup.string().required(requiredText),
    email: yup.string().email('כתובת מייל לא תקינה'),
    phone: phoneSchema.required(requiredText),
    gender: yup.string().required(requiredText),
})

export const accountSchema = yup.object({
    name: yup.string().required(requiredText),
    contactName: yup.string(),
    contactPhone: notRequiredPhoneSchema,
    contactEmail: yup.string().email(emailError),
})
export const organizationSchema = yup.object({
    name: yup.string().trim().required(requiredText),
    contactName: yup.string(),
    contactPhone: notRequiredPhoneSchema,
    contactEmail: yup.string().trim().email(emailError),
    city: yup.string(),
})

export const userSchemas = {
    elder: elderSchema,
    social_worker: socialWorkerSchema,
    volunteer: volunteerSchema,
    admin: adminSchema,
    specialist: specialistSchema,
    relative: relativeSchema,
}

export const assignVolunteer = yup.object({
    volunteer: yup.object().nullable().required(requiredText),
})

export const ticketCancellation = yup.object({
    status: yup.string().required(requiredText),
    cancellationInitiator: yup.string().when('status', {
        is: (status) => status === 'canceled',
        then: yup.string().required(requiredText),
    }),
    cancellationReason: yup.string().when('status', {
        is: (status) => status === 'canceled',
        then: yup.string().required(requiredText),
    }),
})

export const rangeFilterSchema = yup.object({
    lt: yup.lazy((lt) => {
        if (!lt || lt.trim() === '') {
            return yup.number().emptyNumber().notRequired()
        }
        return onlyNumbers.test(
            'greater than',
            'ערך לא תקין',
            (value, context) =>
                context.parent.gte ? context.parent.gte > value : true
        )
    }),
    gte: yup.lazy((gte) => {
        if (!gte || gte.trim() === '') {
            return yup.number().emptyNumber().notRequired()
        }
        return onlyNumbers.test(
            'greater than',
            'ערך לא תקין',
            (value, context) =>
                context.parent.lt ? context.parent.lt < value : true
        )
    }),
})
