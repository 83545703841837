import {styled, TableSortLabel} from '@mui/material'
import tables from 'constants/tables'
import {useUrlParams} from 'services/hooks'
import {getSortDirection, setSortDirection} from 'services/utils/tables'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import SouthIcon from '@mui/icons-material/South'

const TableSort = ({itemName, itemKey}) => {
    const tableName = `${itemName}s`
    const sortKey = tables[tableName].sortableKeys?.[itemKey]
    const {urlParams, setUrlParam} = useUrlParams()
    const {[`${itemName}_sort`]: sort} = urlParams
    const direction = getSortDirection(sortKey, sort)
    const active = !!direction

    const toggleDirection = () => {
        setSortDirection(
            itemName,
            sortKey,
            direction === 'asc' ? 'desc' : 'asc',
            setUrlParam
        )
    }

    const sortableKeys =
        tables[tableName].sortableKeys &&
        Object.keys(tables[tableName].sortableKeys)
    if (!sortableKeys?.includes(itemKey)) {
        return null
    }

    return (
        <StyledTableSortLabel
            direction={direction}
            onClick={toggleDirection}
            IconComponent={active ? SouthIcon : ImportExportIcon}
        />
    )
}

export default TableSort

const StyledTableSortLabel = styled(TableSortLabel)`
    .MuiTableSortLabel-icon {
        opacity: 1;
    }
`
