import {styled} from '@mui/material'
import Button from 'components/Control/Button'

const DialogButtons = ({
    okButtonText,
    onOk,
    cancelButtonText,
    onCancel,
    cancelButtonOptions,
    okButtonOptions,
}) => {
    const singleItem = !(cancelButtonOptions || cancelButtonText)

    return (
        <ActionsContainer singleItem={singleItem}>
            {!singleItem && (
                <CancelButton
                    name="cancel"
                    endIcon={cancelButtonOptions?.icon}
                    onClick={onCancel}
                    color={cancelButtonOptions?.color ?? 'secondary'}>
                    {cancelButtonOptions?.text ?? cancelButtonText}
                </CancelButton>
            )}
            <OkButton
                name="submit"
                color={okButtonOptions?.color}
                endIcon={okButtonOptions?.icon}
                onClick={onOk}>
                {okButtonOptions?.text ?? okButtonText}
            </OkButton>
        </ActionsContainer>
    )
}

export default DialogButtons

const ActionsContainer = styled('div')`
    display: flex;
    justify-content: ${(props) =>
        props.singleItem ? 'flex-end' : 'space-between'};
    width: 100%;
    margin-top: 12px;
`

const OkButton = styled(Button)``
const CancelButton = styled(Button)``
