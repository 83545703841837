import {React} from 'common'
import {useEffect, useState} from 'react'
import {
    useGetTemplatesQuery,
    useSendMessageTempalteToCustomerMutation,
} from 'store/Store'
import {Texts} from 'appearance'
import Button from 'components/Control/Button'
import Select from 'components/Control/Select'
import {Input, FormLabel, Alert, LinearProgress} from '@mui/material'
import Snackbar from '@mui/material/Snackbar'

const ChatTemplateSender = ({customerId}) => {
    const {data, isFetching} = useGetTemplatesQuery()
    const [
        sendMessage,
        {error: sendError, data: sendResponse, isLoading: sendingMessages},
    ] = useSendMessageTempalteToCustomerMutation()
    const templatesMap = data?.data?.templates.reduce((acc, t) => {
        acc[t.name] = t
        return acc
    }, {})
    useEffect(() => {
        setShowMessageBar(true)
    }, [sendResponse, sendError])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [showMessageBar, setShowMessageBar] = useState(false)
    if (isFetching) return <LinearProgress />

    return (
        <>
            <Snackbar
                open={!!showMessageBar}
                autoHideDuration={6000}
                onClose={() => setShowMessageBar(false)}>
                <Alert
                    onClose={() => setShowMessageBar(false)}
                    severity={sendError ? 'error' : 'success'}
                    variant="filled"
                    sx={{width: '100%'}}>
                    {sendError ? 'שליחת הודעה נכשלה' : 'הודעה נשלחה בהצלחה'}
                </Alert>
            </Snackbar>
            <TemplatePicker
                templates={data?.data.templates}
                onTemplateSelected={setSelectedTemplate}
            />
            <>
                {selectedTemplate ? (
                    <TemplateForm
                        sendingMessages={sendingMessages}
                        onSendMessage={(params) =>
                            sendMessage({
                                customerId: customerId,
                                body: {
                                    template_id:
                                        templatesMap[selectedTemplate].id,
                                    template_params: {
                                        body: {
                                            params: params,
                                        },
                                        buttons: [null],
                                        template_language: 'he',
                                    },
                                },
                            })
                        }
                        template={templatesMap[selectedTemplate]}
                    />
                ) : null}
            </>
        </>
    )
}

const TemplatePicker = ({templates, onTemplateSelected}) => {
    const options = templates?.map((t) => t.name)
    return (
        <>
            <Select
                doNotTranslate
                label={'בחר תבנית'}
                options={options}
                onChange={(e) => onTemplateSelected(e.target.value)}
            />
        </>
    )
}

const TemplateForm = ({template, onSendMessage, sendingMessages}) => {
    const [varaiblesState, setVariables] = useState([])
    return (
        <>
            <Texts.Title>{template.name || ''}</Texts.Title>
            <Texts.MediumText>
                {interpolateString(template.text, varaiblesState) || ''}
            </Texts.MediumText>
            {Array.from({length: template.params_number || 0}).map((_, i) => (
                <FormLabel key={i}>
                    פרמטר {i}
                    <Input
                        onChange={(e) =>
                            setVariables((curr) => {
                                const newState = [...curr]
                                newState[i] = e.target.value
                                return newState
                            })
                        }
                    />
                </FormLabel>
            ))}
            <Button
                disabled={sendingMessages}
                onClick={() => onSendMessage(varaiblesState)}>
                שליחה
            </Button>
        </>
    )
}

function interpolateString(template, variables) {
    return template.replace(
        /\{\{(\d+)\}\}/g,
        (match, index) => variables[index - 1] || '__'
    )
}

export default ChatTemplateSender
