import {React} from 'common'
import Table from 'components/Display/Table'
import {Page, PageContent} from 'components/Page'
import VolunteerFormDialog from 'components/Page/Dashboard/Volunteers/VolunteerFormDialog'
import {useCallback, useState} from 'react'
import {useDeleteUserMutation, useGetVolunteersQuery} from 'store/Store'
import {WhatsappSquare} from '@styled-icons/boxicons-logos/WhatsappSquare'
import {useNavigate} from 'react-router-dom'

const Volunteers = () => {
    const [volunteerId, setVolunteerId] = useState(null)
    const navigate = useNavigate()
    const clearVolunteerId = useCallback(() => {
        setVolunteerId(null)
    }, [])
    return (
        <Page menuTitle={'volunteers'}>
            <PageContent>
                <Table
                    itemName="volunteer"
                    allowEdit
                    allowDelete
                    allowAdd
                    addFunction={() => setVolunteerId('new')}
                    editFunction={setVolunteerId}
                    allowDownload
                    paginationType="external"
                    useGetQuery={useGetVolunteersQuery}
                    useDeleteMutation={useDeleteUserMutation}
                    customRowActions={{
                        key: 'chats',
                        icon: () => <WhatsappSquare size={24} />,
                        onClick: (r) => navigate(`/user/${r.id}/chats`),
                    }}
                />
            </PageContent>
            <VolunteerFormDialog
                volunteerId={volunteerId}
                handleClose={clearVolunteerId}
            />
        </Page>
    )
}

export default Volunteers
