import {React} from 'common'
import {Texts} from 'appearance'
import TableSearch from './TableTitleAndFilters/TableSearch'
import TableAddButton from './TableTitleAndFilters/TableAddButton'
import TableButtonsGroup from './TableButtonsGroup'
import {styled} from '@mui/material'
import Button from 'components/Control/Button'
import {useI18n} from 'store/Store'
import tables from 'constants/tables'

const TableTitleWithSearch = ({
    title,
    itemName,
    itemType,
    useSearch,
    children,
    allowAdd,
    addFunction,
    hideTitle,
    selectedToggleButton,
    buttonsGroupOptions,
    handleChange,
    textAlign,
    clearAllFilters,
    isLoading,
}) => {
    const {i18n} = useI18n()
    if (!title || hideTitle) {
        return null
    }

    const isTableWithFilters = !!(
        tables[`${itemName}s`].filteredKeys ||
        tables[`${itemName}s`].filteredKeys?.length
    )

    return (
        <Container>
            <TitleContainer>
                <Title style={{textAlign}}>{title}</Title>
                <TableButtonsGroup
                    tableName={`${itemType}s`}
                    value={selectedToggleButton}
                    options={buttonsGroupOptions}
                    handleChange={handleChange}
                />
            </TitleContainer>
            {children}
            <SearchArea>
                <TableSearch
                    useSearch={useSearch}
                    itemName={itemName}
                    style={{width: '15%'}}
                    isLoading={isLoading}
                />

                <div>
                    {isTableWithFilters && (
                        <Button variant="text" onClick={clearAllFilters}>
                            {i18n.t('general.clearAllFilters')}
                        </Button>
                    )}
                    <TableAddButton
                        itemName={itemName}
                        show={allowAdd}
                        addFunction={addFunction}
                    />
                </div>
            </SearchArea>
        </Container>
    )
}

export default TableTitleWithSearch

const Container = styled('div')`
    width: 100%;
    margin-bottom: 10px;
`

const TitleContainer = styled('div')`
    flex: 1;
`

const Title = styled(Texts.Title)``

const SearchArea = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: start;
`
