import {CircularProgress} from '@mui/material'
import {FormErrorText} from '../Form'
import InfiniteLoader from './InfiniteLoader'
import {Controller} from 'react-hook-form'
import AutocompleteInput from '../AutocompleteInput'
import {useCallback, useEffect, useState} from 'react'
import Row from './Row'

const InfiniteScrollAutocomplate = ({
    name,
    label,
    disabled,
    InputProps,
    onChange,
    getOptionLabel,
    value,
    inputValue,
    inputKey,
    placeholder,
    defaultValue,
    renderOption,
    required,
    errors,
    control,
    itemName,
    handleInputChange,
    handleSelectValue,
    options,
    total,
    isLoading,
    page,
    pageSize,
    setPage,
    ...props
}) => {
    const [loadedOptions, setLoadedOptions] = useState(options || [])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (!isLoading) {
            setLoadedOptions((prevLoadedOptions) => [
                ...prevLoadedOptions,
                ...(options || []),
            ])
        }
    }, [isLoading, options])

    const totalPages = Math.ceil(total / pageSize)
    const hasNextPage = page < totalPages
    const isItemLoaded = (index) => index < options?.length

    const loadMoreItems = useCallback(() => {
        if (!isLoading && hasNextPage) {
            setPage((prev) => prev + 1)
        }
    }, [isLoading, hasNextPage, setPage])

    const onInputChange = (event, value, reason) => {
        setLoadedOptions([])
        if (reason !== 'reset') {
            setOpen(!!value?.length)
        }
        handleInputChange(event, value)
    }
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value}}) => (
                    <AutocompleteInput
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        isLoading={isLoading}
                        inputKey={inputKey}
                        itemName={itemName}
                        name={name}
                        label={label}
                        value={value}
                        defaultValue={value}
                        onChange={handleSelectValue}
                        inputValue={inputValue}
                        onInputChange={onInputChange}
                        options={loadedOptions}
                        disabled={disabled}
                        getOptionLabel={getOptionLabel}
                        filterOptions={(options) => options}
                        InputProps={{
                            style: {height: '40px', padding: '0 8px'},
                            endAdornment: isLoading && (
                                <CircularProgress color="inherit" size={20} />
                            ),
                        }}
                        ListboxComponent={InfiniteLoader}
                        ListboxProps={{
                            getOptionLabel,
                            inputValue,
                            isItemLoaded,
                            itemCount: total,
                            loadMoreItems,
                            isLoading,
                            RowComponent: Row,
                        }}
                        renderOption={renderOption}
                        {...props}
                    />
                )}
            />
            {errors?.[name] && (
                <FormErrorText>{errors[name].message}</FormErrorText>
            )}
        </>
    )
}

export default InfiniteScrollAutocomplate
