import {DialogContent} from '@mui/material'
import Button from 'components/Control/Button'
import Dialog from 'components/Display/DialogForm/Dialog'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {
    useI18n,
    useSendMessagesMutation,
    useTriggerStartBotMutation,
} from 'store/Store'
import styled from 'styled-components'
import SendTicketToVoulnteersMessageStep from './SendTicketToVolunteersMessageStep'
import SendTicketToVolunteersPickVolunteersStep from './SendTicketToVolunteersPickVolunteersStep'
import SendTicketToVolunteersSendMessagesStep from './SendTicketToVolunteersSendMessagesStep'

const SendTicketToVoulnteers = ({ticket, open, onClose}) => {
    const {i18n} = useI18n()
    const [step, setStep] = useState('messageTemplate')
    const [
        sendTemplateMessage,
        {
            error: sendTemplateError,
            data: sendTemplateResponse,
            isLoading: sendTemplateLoading,
            reset,
        },
    ] = useSendMessagesMutation()

    const [
        sendMessage,
        {error: sendError, data: sendResponse, isLoading: sendingMessages},
    ] = useTriggerStartBotMutation()

    const onCloseDialog = (...args) => {
        setStep('messageTemplate')
        form.reset()
        reset()
        onClose(...args)
    }
    const form = useForm({
        defaultValues: {
            details: ticket.detailsComments,
            subject: i18n.t(`tickets.${ticket.subCategory}`),
            volunteers: [],
            isBotSend: false,
        },
        mode: 'all',
    })
    const hasRepsone =
        sendError || sendTemplateError || sendResponse || sendTemplateResponse
    const onSubmit = (data) => {
        if (step === 'messageTemplate') {
            setStep('pickVolunteers')
            return
        }
        if (step === 'pickVolunteers') {
            setStep('sendMessages')
            return
        }
        if (step === 'sendMessages' && !hasRepsone) {
            if (data.isBotSend) {
                sendMessage({
                    volunteers: data.volunteers,
                    ticketId: ticket._id,
                })
            } else {
                sendTemplateMessage({
                    volunteers: data.volunteers,
                    helpRequest: {
                        ticketId: ticket._id,
                        subject: data.subject,
                        details: data.details,
                    },
                })
            }
            return
        }
        onCloseDialog()
    }
    function btnTextByStep() {
        if (sendingMessages) {
            return i18n.t('sendToVolunteersForm.sendingMessages')
        }
        if (step === 'messageTemplate') {
            return i18n.t('sendToVolunteersForm.pickVolunteers')
        }
        if (step === 'pickVolunteers') {
            return i18n.t('sendToVolunteersForm.continue')
        }
        if (step === 'sendMessages') {
            if (hasRepsone) {
                return i18n.t('sendToVolunteersForm.close')
            }

            return i18n.t('sendToVolunteersForm.sendMessages')
        }
    }
    const disableSend =
        !form.formState.isValid || sendTemplateLoading || sendingMessages

    return (
        <Dialog
            open={open}
            onClose={onCloseDialog}
            title={i18n.t('ticketMenu.sendToVolunteers')}>
            <DialogContent>
                {step === 'messageTemplate' ? (
                    <SendTicketToVoulnteersMessageStep
                        ticket={ticket}
                        form={form}
                    />
                ) : null}
                {step === 'pickVolunteers' ? (
                    <SendTicketToVolunteersPickVolunteersStep
                        ticket={ticket}
                        form={form}
                    />
                ) : null}
                {step === 'sendMessages' ? (
                    <SendTicketToVolunteersSendMessagesStep
                        ticket={ticket}
                        form={form}
                        error={sendError}
                        response={sendTemplateResponse}
                    />
                ) : null}
                <DialogActions>
                    <Button color="secondary" onClick={onCloseDialog}>
                        {i18n.t('form.cancel')}
                    </Button>
                    <Button
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={disableSend}>
                        {btnTextByStep()}
                    </Button>
                    {step === 'sendMessages' && !hasRepsone && (
                        <Button
                            onClick={form.handleSubmit(() =>
                                onSubmit({...form.getValues(), isBotSend: true})
                            )}
                            disabled={disableSend}>
                            {i18n.t('sendToVolunteersForm.sendBot')}
                        </Button>
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default SendTicketToVoulnteers

const DialogActions = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`
