import {HandsPraying} from '@styled-icons/fa-solid/HandsPraying'

import {Elderly} from '@styled-icons/material-rounded/Elderly'
import {VolunteerActivism} from '@styled-icons/material-rounded/VolunteerActivism'
import {FamilyRestroom} from '@styled-icons/material/FamilyRestroom'
import {More} from '@styled-icons/material-sharp/More'

const OWNER = 'OWNER'
const ADMIN = 'ADMIN'
const USER = 'USER'

export const ORDERED_ROLES = [USER, ADMIN, OWNER]
export const ADMIN_ROLES = [ADMIN, OWNER]

export const userTypes = [
    'elder',
    'social_worker',
    'volunteer',
    'relative',
    'other',
]

export const userTypesIcons = {
    elder: Elderly,
    social_worker: HandsPraying,
    volunteer: VolunteerActivism,
    relative: FamilyRestroom,
    other: More,
}

const MALE = 'MALE'
const FEMALE = 'FEMALE'
const OTHER = 'OTHER'

export const genders = [MALE, FEMALE, OTHER]

const YES = 'YES'
const NO = 'NO'
const UNKNOWN = 'UNKNOWN'

export const underCares = [YES, NO, UNKNOWN]

export const regions = [
    'TEL_AVIV',
    'HAIFA',
    'BEER_SHEVA',
    'LOD',
    'NATANYA',
    'HEDERA',
]

export const userTableTypes = [
    'volunteer',
    'admin',
    'specialist',
    'social_worker',
    'relative',
]
export const statuses = [
    'registered',
    'unverified',
    'pending_support',
    'suspended',
    'verified',
]

export const holocaustSurvivor = [YES, NO, UNKNOWN]

export const userRoles = ['ADMIN', 'USER']

export const adminManagedUserTypes = ['admin', 'specialist', 'volunteer']

export const optionEmptyOrganization = {name: 'ללא', _id: 'empty'}
