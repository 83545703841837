import {Controller} from 'react-hook-form'
import ToggleButtonGroup from '../ButtonGroup'

const FormButtonGroup = ({
    name,
    control,
    rules,
    required,
    itemName,
    label,
    buttons,
    initialValue,
    handleChange,
    activeItem,
}) => {
    const onChange = (event, value) => {
        handleChange(value)
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={rules || {required}}
            render={({field: {value}}) => (
                <ToggleButtonGroup
                    itemName={itemName}
                    name={name}
                    label={label}
                    activeItem={activeItem}
                    onChange={onChange}
                    buttons={buttons}
                    initialValue={initialValue}
                />
            )}
        />
    )
}

export default FormButtonGroup
