import {styled} from '@mui/material'
import {Controller} from 'react-hook-form'
import {FormErrorText} from '.'
import Input from '../Input'

const TextArea = ({
    name,
    label,
    control,
    errors,
    rules,
    required,
    disabled,
    width,
    fullWidth,
    type,
    initialValue = ' ',
}) => {
    return (
        <Container>
            <Controller
                name={name}
                control={control}
                rules={rules || {required}}
                render={({field: {onChange, value}}) => (
                    <Input
                        onChange={onChange}
                        value={value || initialValue}
                        fullWidth={fullWidth}
                        label={label}
                        required={required}
                        disabled={disabled}
                        type={type}
                        multiline
                        rows={2}
                        // maxRows={4} // it contradicts rows
                        InputProps={{
                            style: {height: 85},
                            'data-testid': `${name}_field`,
                        }}
                    />
                )}
            />
            {errors?.[name] && (
                <FormErrorText>{errors[name].message}</FormErrorText>
            )}
        </Container>
    )
}

export default TextArea

const Container = styled('div')``
