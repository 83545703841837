import {styled} from '@mui/material'
import {Texts} from 'appearance'

const TabLabel = ({title, subtitle, testKey}) => {
    return (
        <LabelContainer data-testid={`${testKey}_tab`}>
            <TabTitle>{title}</TabTitle>
            <TabSubtitle>{subtitle}</TabSubtitle>
        </LabelContainer>
    )
}

export default TabLabel

const LabelContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    height: 100%;
`

const TabTitle = styled(Texts.BoldText)`
    font-size: 18px;
`

const TabSubtitle = styled(Texts.RegularText)`
    font-size: 16px;
`
