import {styled} from 'common'

const Spacer = styled.div`
    width: ${(p) => p.w || 0}px;
    height: ${(p) => p.h || 0}px;

    ${({flexed}) =>
        flexed &&
        `
        flex: 1;
    `}
`

export default Spacer
