import {styled, Chip, Autocomplete as RawAutocomplete} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {Colors} from 'appearance'
import {useI18n, useIsRtl} from 'store/Store'
import {Label} from './Form'
import Input from './Input'

const ChipSelect = ({
    name,
    label,
    value,
    required,
    options,
    onChange,
    optionKey,
    getOptionLabel,
    renderOptionFunc,
    enableDeleteChip,
    disableClearable,
    fixedOptions = [],
    filterSelectedOptions = true,
}) => {
    const {i18n} = useI18n()
    const isRTL = useIsRtl()

    const isInFixedOptions = (option) => {
        return fixedOptions.some(
            (fixedOption) => fixedOption._id === option._id
        )
    }

    const handleChange = (event, newValues) => {
        const values = [
            ...fixedOptions,
            ...newValues.filter((option) => !isInFixedOptions(option)),
        ]
        onChange(values)
    }

    const fallbackLabelFormat = (option) => option[optionKey]

    const formattedLabel = (option) =>
        getOptionLabel ? getOptionLabel(option) : fallbackLabelFormat(option)

    const renderOption = (props, option) => {
        return (
            <li
                {...props}
                data-testid={`option_${props['data-option-index'] + 1}`}>
                {formattedLabel(option)}
            </li>
        )
    }

    const isOptionSelected = (option, value) => {
        if (typeof option === 'string') {
            return option === value
        }

        if (typeof option === 'object') {
            if (optionKey !== undefined) {
                return (
                    option[optionKey] === value[optionKey] ||
                    option._id === value._id
                )
            }
            return option._id === value._id
        }

        return false
    }

    return (
        <>
            {label && <Label required={required}>{label}</Label>}
            <Autocomplete
                name={name}
                multiple
                noOptionsText={i18n.t('form.autocompleteNoOptionsText')}
                options={options ?? []}
                value={value ?? [...fixedOptions]}
                onChange={handleChange}
                disableClearable={disableClearable}
                filterSelectedOptions={filterSelectedOptions}
                isOptionEqualToValue={isOptionSelected}
                getOptionLabel={(option) => formattedLabel(option)}
                renderOption={renderOptionFunc ?? renderOption}
                renderInput={(params) => <Input {...params} name={name} />}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <StyledChip
                            data-testid={`${option}_tag`}
                            label={formattedLabel(option)}
                            {...getTagProps({index})}
                            onDelete={
                                enableDeleteChip &&
                                getTagProps({index}).onDelete
                            }
                            deleteIcon={enableDeleteChip && <CloseIcon />}
                            disabled={fixedOptions && isInFixedOptions(option)}
                        />
                    ))
                }
                ListboxProps={{style: {direction: isRTL ? 'rtl' : 'ltr'}}}
            />
        </>
    )
}

export default ChipSelect

const Autocomplete = styled(RawAutocomplete)`
    & .MuiFilledInput-root.MuiInputBase-hiddenLabel .MuiAutocomplete-input {
        height: 40px;
        padding: 0;
    }

    &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root
        .MuiFilledInput-root {
        padding-inline: 4px;
    }
    & .MuiAutocomplete-endAdornment {
        position: unset;
        padding-inline: 4px;
    }

    & .MuiAutocomplete-tag {
        height: 30px;
        border-radius: 2px;
        background: ${Colors.SECONDARY_COLOR};
        color: ${Colors.APP_WHITE};
    }
    & .MuiAutocomplete-tag svg {
        color: ${Colors.BORDER};
        height: 20px;
        width: 20px;
    }
    &.MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-hiddenLabel {
        padding-right: 8px;
    }
`
const StyledChip = styled(Chip)`
    height: 30px;
    & .MuiChip-deleteIcon {
        margin: 0 -6px 0 5px;
    }
`
