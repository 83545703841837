export function formatDateTime(date) {
    const validDate = date instanceof Date ? date : new Date(date)

    const day = String(validDate.getDate()).padStart(2, '0')
    const month = String(validDate.getMonth() + 1).padStart(2, '0')
    const year = validDate.getFullYear()
    const hours = String(validDate.getHours()).padStart(2, '0')
    const minutes = String(validDate.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} ${hours}:${minutes}`
}

export function timeSince(date) {
    let seconds = Math.floor((new Date() - date) / 1000)

    let interval = Math.floor(seconds / 31536000)

    if (interval > 1) {
        return interval + ' שנים'
    }
    interval = Math.floor(seconds / 2592000)
    if (interval > 1) {
        return interval + ' חודשים'
    }
    interval = Math.floor(seconds / 86400)
    if (interval > 1) {
        return interval + ' ימים'
    }
    interval = Math.floor(seconds / 3600)
    if (interval > 1) {
        return interval + ' שעות'
    }
    interval = Math.floor(seconds / 60)
    if (interval > 1) {
        return interval + ' דקות'
    }
    return Math.floor(seconds) + ' שניות'
}
