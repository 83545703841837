import {Controller} from 'react-hook-form'
import {FormErrorText} from '.'
import ChipSelect from '../ChipSelect'

const FormChipSelect = ({
    itemName,
    name,
    label,
    control,
    errors,
    rules,
    options,
    required,
    disabled,
    initialValue,
    handleChange,
    getOptionLabel,
    optionKey,
    fixedOptions,
    ...p
}) => {
    return (
        <div {...p}>
            <Controller
                name={name}
                control={control}
                rules={rules || {required}}
                render={({field: {value}}) => (
                    <ChipSelect
                        itemName={itemName}
                        name={name}
                        label={label}
                        value={value}
                        onChange={handleChange}
                        options={options}
                        disabled={disabled}
                        initialValue={initialValue}
                        getOptionLabel={getOptionLabel}
                        optionKey={optionKey}
                        fixedOptions={fixedOptions}
                        enableDeleteChip
                    />
                )}
            />
            {errors?.[name] && (
                <FormErrorText>{errors[name].message}</FormErrorText>
            )}
        </div>
    )
}

export default FormChipSelect
