import styled from 'styled-components'
const SendTicketToVolunteerMessagePreview = ({
    detials = '',
    subject = '',
    ticket,
}) => {
    const elderName = ticket?.elder?.firstName
    const elderStreet = ticket?.elder?.address.street
    const elderCity = ticket?.elder?.address.city
    const text = `היי מתנדב, הנה הזדמנות לעשות משהו טוב השבוע! 
${elderName}, רוצה ${subject}.
${detials}
הבית של ${elderName} נמצא ברחוב ${elderStreet} ב${elderCity}.
רוצה לעשות משהו טוב?`

    return (
        <MessageContainer>
            <MessageText>{text}</MessageText>
            <MessageTime>{new Date().toLocaleTimeString()}</MessageTime>
        </MessageContainer>
    )
}

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #dcf8c6;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
`

const MessageText = styled.p`
    font-size: 16px;
    margin: 0;
    white-space: pre-wrap;
`

const MessageTime = styled.span`
    font-size: 12px;
`

export default SendTicketToVolunteerMessagePreview
