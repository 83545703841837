import {React} from 'common'
import Table from 'components/Display/Table'
import {Page, PageContent} from 'components/Page'
import ElderFormDialog from 'components/Page/Dashboard/Elders/ElderFormDialog'
import {useCallback, useState} from 'react'
import {useDeleteUserMutation, useGetEldersQuery} from 'store/Store'
import {FileInfo} from '@styled-icons/remix-fill/FileInfo'

const Elders = () => {
    const [elderId, setElderId] = useState(null)
    const clearElderId = useCallback(() => {
        setElderId(null)
    }, [])

    const onHistoryClick = () => (id) => {
        window.open(`/ticketHistory?elder=${id}`)
    }

    return (
        <Page menuTitle={'accounts'}>
            <PageContent>
                <Table
                    itemName="elder"
                    allowEdit
                    allowDelete
                    allowAdd
                    addFunction={() => setElderId('new')}
                    editFunction={setElderId}
                    allowDownload
                    paginationType="external"
                    useGetQuery={useGetEldersQuery}
                    useDeleteMutation={useDeleteUserMutation}
                    query={{type: 'elder'}}
                    customRowActions={[
                        {
                            key: 'navigate',
                            icon: () => <FileInfo size={24} />,
                            onClick: onHistoryClick(),
                        },
                    ]}
                />
            </PageContent>
            <ElderFormDialog elderId={elderId} handleClose={clearElderId} />
        </Page>
    )
}

export default Elders
