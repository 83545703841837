import {useGetUsersQuery, useI18n} from 'store/Store'
import {styled} from '@mui/material'
import {useFormContext} from 'react-hook-form'
import {useEffect, useState} from 'react'
import {
    formatPhone,
    getFullName,
    setValueOptions,
} from 'services/utils/formUtils'
import useDebounce from 'logic/Forms/useDebounce'
import InfiniteScrollAutocomplate from 'components/Control/InfiniteScroll/InfiniteScrollAutocomplate'
import {noEmptyNamesQuery} from 'store/slices/api/utils'

const SearchUser = ({userType}) => {
    const {i18n} = useI18n()
    const [searchValue, setSearchValue] = useState()
    const pageSize = 20
    const [page, setPage] = useState(0)
    const debouncedValue = useDebounce(searchValue, 500)

    const formContext = useFormContext()
    const {
        control,
        formState: {errors},
        setValue,
    } = formContext

    const {data: users, isFetching} = useGetUsersQuery(
        {
            ...noEmptyNamesQuery,
            search: debouncedValue,
            type: userType,
            pageSize,
            page,
        },
        {skip: !debouncedValue || !debouncedValue?.length}
    )

    useEffect(() => {
        if (userType) {
            setSearchValue('')
        }
    }, [userType])

    const formatUsers = (user) => {
        return userType === 'volunteer'
            ? `${getFullName(user)} (${formatPhone(user?.phone)})`
            : getFullName(user)
    }

    const handleChange = (event, value) => {
        setPage(0)
        setSearchValue(value)
    }

    const handleSelectUser = (event, value) => {
        setValue('issuer', value, setValueOptions)
        setSearchValue()
    }

    return (
        <Container>
            <InfiniteScrollAutocomplate
                key={userType}
                name="issuer"
                control={control}
                errors={errors}
                getOptionLabel={formatUsers}
                label={i18n.t(`tickets.search_exising`, {
                    item: i18n.t(`tickets.${userType}`),
                })}
                InputProps={{
                    style: {height: '36px', padding: '10px'},
                }}
                handleSelectValue={handleSelectUser}
                handleInputChange={handleChange}
                inputValue={searchValue}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                options={users?.data}
                total={users?.total}
                isLoading={isFetching}
            />
        </Container>
    )
}

export default SearchUser

const Container = styled('div')`
    margin-top: 16px;
    width: 30%;
    margin-inline: auto;
`
