import {capitalCase} from 'change-case'
import FormCalendarInput from 'components/Control/Form/FormCalendarInput'
import FormTextInput from 'components/Control/Form/FormTextInput'
import tables from 'constants/tables'

export const isNew = (itemId) => !itemId || itemId === 'new'

export const extractError = (saveError, createError) =>
    saveError?.data?.message || createError?.data?.message

export const getFormTitle = (itemId, itemName) => {
    let action = 'Update'
    if (isNew(itemId)) {
        action = 'Create'
    }
    return `${action} ${capitalCase(itemName)}`
}

export const getItemLabel = (itemKey, itemName) => {
    if (itemKey === 'name') {
        return `${capitalCase(itemName)} Name`
    }
    return capitalCase(itemKey)
}

const formComponents = {
    text: FormTextInput,
    date: FormCalendarInput,
    datetime: FormCalendarInput,
    boolean: FormTextInput,
}

export const getFormComponent = (itemKey, itemNames) => {
    const {keyTypes} = tables[itemNames]
    return formComponents[keyTypes[itemKey]] || FormTextInput
}

export const getShouldShowItemKey = (item, itemKey, disabled) =>
    !!item?.[itemKey] || item?.[itemKey] === false || !disabled
