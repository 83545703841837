import {i18n} from 'common'
import {getFullAddress, getFullName} from 'services/utils/formUtils'
import TabContentRow from './TabContentRow'

const ElderDetails = ({ticket}) => {
    const {elder} = ticket
    const elderName = getFullName(elder)
    const elderAddress = getFullAddress(elder.address)

    return (
        <div>
            <TabContentRow
                rowData={elderName}
                rowKey="name"
                showDivider={true}
            />

            <TabContentRow
                rowData={i18n.t(`users.genders.${elder.gender}`)}
                rowKey="gender"
                showDivider={true}
            />

            <TabContentRow
                rowData={elderAddress}
                rowKey="address"
                showDivider={false}
            />
        </div>
    )
}

export default ElderDetails
