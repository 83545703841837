import {InputLabel, styled} from '@mui/material'
import {Colors, Texts} from 'appearance'
import {useWatch} from 'react-hook-form'

const StaticText = ({name, label, control, formatFunc, showOnCreate, ...p}) => {
    const value = useWatch({name, control})

    if (!showOnCreate) {
        return null
    }

    return (
        <Container>
            <Label>{label}</Label>
            <Texts.RegularText>{formatFunc(value)}</Texts.RegularText>
        </Container>
    )
}

export default StaticText

const Container = styled('div')`
    display: flex;
    flex-direction: column;
`

const Label = styled(InputLabel)`
    color: ${Colors.PRIMARY_COLOR};
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 4px;
`
