import {styled} from '@mui/material'
import {Texts} from 'appearance'
import DialogButtons from 'components/Page/Dashboard/Tickets/NewTicket/NewTicketActions'
import {formFields} from 'constants/forms'
import {useI18n} from 'store/Store'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'

const AccountDialog = ({account, objectType, onDeleteAccount, handleEdit}) => {
    const {i18n} = useI18n()

    // const copyLink = () => {
    //     navigator.clipboard.writeText('link')
    // }

    const getValue = (field) => {
        const {formatFunc, key} = field
        return formatFunc ? formatFunc(account?.[key]) : account?.[key]
    }

    return (
        <Container>
            {formFields.account?.map((field) => {
                console.log({field, getValue: getValue(field)})
                return (
                    <div>
                        <Title>
                            {i18n.t(`accounts.${field.labelKey ?? field.key}`)}
                        </Title>
                        <Value>{getValue(field)}</Value>
                    </div>
                )
            })}
            {/* <InviteLinkTitle>
                {i18n.t('accounts.inviteLinkTitle')}
            </InviteLinkTitle>
            <InviteLinkContainer>
                <Texts.RegularText>
                    https://20-80.com/invitations/signup?invite_code=codelovers01
                </Texts.RegularText>
                <Button
                    name="invite"
                    endIcon={<ContentCopyIcon />}
                    onClick={copyLink}>
                    {i18n.t('form.copy')}
                </Button>
            </InviteLinkContainer> */}
            <DialogButtons
                okButtonOptions={{
                    text: i18n.t('form.updateDetails'),
                }}
                cancelButtonOptions={{
                    text: i18n.t('form.delete'),
                    icon: <DisabledByDefaultIcon />,
                    color: 'error',
                }}
                onOk={() => handleEdit(account._id)}
                onCancel={() => onDeleteAccount(account._id)}
            />
        </Container>
    )
}

export default AccountDialog

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    overflow: auto;
`

const Title = styled(Texts.BoldText)``
const Value = styled(Texts.RegularText)``

// const InviteLinkContainer = styled('div')`
//     display: flex;
//     align-items: center;
//     gap: 50px;
//     & button {
//         background: #9c3587;
//     }
//     margin-bottom: 36px;
// `
// const InviteLinkTitle = styled(Texts.BoldText)`
//     margin-top: 36px;
//     font-size: 18px;
// `
