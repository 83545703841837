import api from 'services/api'
import {Link, styled} from '@mui/material'
import Button from 'components/Control/Button'
import {useGetTicketQuery} from 'store/slices/api/ticketsApi'
import TicketDetailes from './TicketDetailes'
import TicketsListItem from './TicketsListItem'
import {shouldSkipFetchTicket} from 'services/utils/tickets'

const TicketsList = ({
    tickets,
    activeTicket,
    onRowClick,
    closeTicketDetails,
    isAdmin,
}) => {
    const {data: ticket} = useGetTicketQuery(activeTicket, {
        skip: shouldSkipFetchTicket(activeTicket),
    })

    return (
        <>
            <Container>
                <Tickets>
                    {tickets?.map((ticket) => (
                        <TicketsListItem
                            key={ticket._id}
                            isAdmin={isAdmin}
                            ticket={ticket}
                            active={(activeTicket === ticket._id).toString()}
                            onRowClick={onRowClick}
                        />
                    ))}
                </Tickets>
                {activeTicket && ticket && (
                    <TicketDetailes
                        ticket={ticket}
                        closeTicketDetails={closeTicketDetails}
                    />
                )}
            </Container>
            <DownloadLink href={`${api.baseUrl}/tickets/download`}>
                <DownloadButton name="excel">Excel</DownloadButton>
            </DownloadLink>
        </>
    )
}

export default TicketsList

const Container = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 48px;
`

const Tickets = styled('div')`
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const DownloadLink = styled(Link)`
    align-self: flex-end;
    margin-top: 20px;
`

const DownloadButton = styled(Button)`
    width: 100px;
    align-self: flex-end;
`
