import {InputLabel, styled} from '@mui/material'
import {Controller} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {FormErrorText} from '.'
import Input from '../Input'

const MultipleInputsComponent = ({
    itemName,
    mainInputName,
    name,
    subInputs,
    subInputsSizing,
    control,
    rules,
    required,
    initialValue,
    disabled,
    type,
    errors,
    label: inputsLabel,
}) => {
    const {i18n} = useI18n()

    return (
        <Container>
            {inputsLabel && (
                <Label focused required={required}>
                    {inputsLabel}
                </Label>
            )}
            <InputsContainer>
                {subInputs.map((inputName, index) => (
                    <div key={inputName} style={{flex: subInputsSizing[index]}}>
                        <Controller
                            name={`${name}.${inputName}`}
                            control={control}
                            rules={rules || {required}}
                            render={({field: {onChange, value}}) => (
                                <Input
                                    name={name}
                                    onChange={onChange}
                                    value={value || initialValue}
                                    placeholder={i18n.t(
                                        `${itemName}s.${mainInputName}.${inputName}`
                                    )}
                                    required={required}
                                    disabled={disabled}
                                    type={type}
                                    InputProps={{style: {fontSize: '0.9em'}}}
                                />
                            )}
                        />
                        {errors?.[name] && (
                            <FormErrorText>
                                {errors[name]?.[inputName]?.message}
                            </FormErrorText>
                        )}
                    </div>
                ))}
            </InputsContainer>
        </Container>
    )
}

export default MultipleInputsComponent

const Container = styled('div')``

const InputsContainer = styled('div')`
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    width: 100%;
    & > * {
        box-sizing: border-box;
    }
`

const Label = styled(InputLabel)`
    font-size: 16px;
`
