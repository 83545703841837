import HomeIcon from '@mui/icons-material/Home'
import CustomIcon from 'components/Display/CustomIcon'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import {Users} from '@styled-icons/fa-solid/Users'
import ApartmentIcon from '@mui/icons-material/Apartment'

export const menuItems = [
    'dashboard',
    'elders',
    // 'accounts',
    'users',
    'ticketHistory',
    'organizations',
]

export const menuIcons = {
    dashboard: <HomeIcon />,
    elders: <CustomIcon name="old-man" />,
    accounts: <ApartmentIcon />,
    users: <Users size={30} />,
    volunteers: <CustomIcon name="help-2" />,
    social_workers: <CustomIcon name="prayer" />,
    familyMembers: <CustomIcon name="help-2" />,
    ticketHistory: <FormatListBulletedIcon />,
    organizations: <ApartmentIcon />,
}

export const menuActions = {
    dashboard: (navigate) => navigate('/'),
    elders: (navigate) => {
        navigate('/elders')
    },
    accounts: (navigate) => navigate('/accounts'),
    users: (navigate) => navigate('/users'),
    volunteers: (navigate) => {
        navigate('/volunteers')
    },
    social_workers: (navigate) => {
        navigate('/social_workers')
    },
    familyMembers: (navigate) => {
        navigate('/familyMembers')
    },
    ticketHistory: (navigate) => {
        navigate('/ticketHistory')
    },
    organizations: (navigate) => {
        navigate('/organizations')
    },
}
