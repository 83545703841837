import {styled} from 'common'
import KeyValue from 'components/Display/KeyValue'

const UserAccountExpandArea = ({itemName, item}) => {
    // const {extra, status, ...accountData} = item
    return (
        <Container>
            {!!itemName && <Subtitle>{itemName}</Subtitle>}
            <KeyValue object={item} />
        </Container>
    )
}

export default UserAccountExpandArea

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`

const Subtitle = styled.h3``
