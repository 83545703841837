import {createSlice} from '@reduxjs/toolkit'
import {i18n} from 'common'
import {useSelector} from 'react-redux'

const initialState = {account: null, language: null}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAccount(state, action) {
            state.account = action.payload
        },
        setLanguage(state, action) {
            state.language = action.payload
        },
    },
})

export const {setAccount, setLanguage} = appSlice.actions
export default appSlice.reducer

export const useAccount = () => useSelector((state) => state.app.account)
export const useLanguage = () => useSelector((state) => state.app.language)
export const useIsRtl = () =>
    useSelector((state) => state.app.language === 'he')

export const useI18n = () =>
    useSelector((state) => ({
        language: state.app.language,
        i18n,
    }))
