export const constructUrl = (baseUrl, queryParams) => {
    const filteredParams = Object.entries(queryParams).filter(
        ([key, value]) => value !== undefined
    )

    const params = new URLSearchParams(filteredParams)
    const qstring = params.toString()

    return filteredParams ? `${baseUrl}?${qstring}` : baseUrl
}

export const noEmptyNamesQuery = {
    'firstName[$exists]': true,
    'lastName[$exists]': true,
}
