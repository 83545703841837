import {ticketSchema} from 'services/utils/validations/validationSchemas'
import {yupResolver} from '@hookform/resolvers/yup'
import TabsContainer from 'components/Display/Tabs/TabsContainer'
import {Page, PageContent} from 'components/Page'
import {useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {
    useCreateTicketMutation,
    useGetTicketQuery,
    useSaveTicketMutation,
} from 'store/slices/api/ticketsApi'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useGetCurrentUserQuery} from 'store/Store'
import {isNew} from 'services/utils/editForm'
import {shouldSkipFetchTicket} from 'services/utils/tickets'

const defaultValues = {
    ticketType: 'regular',
    status: 'draft',
}

const Ticket = () => {
    const {state: elder} = useLocation()
    const navigate = useNavigate()
    const {ticketId} = useParams()
    const [isDoneReset, setReset] = useState(false)

    const {data: ticket} = useGetTicketQuery(ticketId, {
        skip: shouldSkipFetchTicket(ticketId),
    })
    const {data: user} = useGetCurrentUserQuery()

    const address = elder?.address || ticket?.address

    const methods = useForm({
        defaultValues: {
            ...defaultValues,
            address,
            elder: elder,
            creator: user,
            ...ticket,
        },
        mode: 'all',
        resolver: yupResolver(ticketSchema),
    })
    const {watch, trigger, handleSubmit, reset, setValue} = methods

    const formValues = watch()
    const ticketCategory = watch('category')

    const [
        doCreateTicket,
        {error: createError, data: createResponse, isLoading},
    ] = useCreateTicketMutation()

    const [doSaveTicket, {error: saveError, data: saveResponse}] =
        useSaveTicketMutation()

    const onSubmit = (values) => {
        if (isNew(ticketId)) {
            doCreateTicket(values)
        } else {
            doSaveTicket(values)
        }
    }

    const submit = handleSubmit(onSubmit)

    const getTabToFilter = () => {
        const categoriesWithSourceTab = ['escort', 'delivery']
        const shouldFilter = !categoriesWithSourceTab.includes(ticketCategory)
        return shouldFilter ? 'ticketRoute' : null
    }

    useEffect(() => {
        if (createResponse) {
            navigate(`/ticket/${createResponse._id}`, {
                state: createResponse.elder,
            })
        }
    }, [createResponse, navigate])

    useEffect(() => {
        if (!formValues._id && ticket) {
            reset(ticket)
            setReset(true)
        }
    }, [ticket, reset, formValues])

    useEffect(() => {
        if (!formValues.creator && user) {
            setValue('creator', user)
        }
    }, [user, formValues, setValue])

    useEffect(() => {
        if (!formValues.elder && elder) {
            setValue('elder', elder)
        }
    }, [elder, formValues, setValue])

    useEffect(() => {
        if (saveResponse) {
            reset(saveResponse)
        }
    }, [saveResponse, reset])

    useEffect(() => {
        isDoneReset && trigger()
    }, [trigger, isDoneReset])

    return (
        <Page>
            <PageContent style={{padding: 56, paddingInline: 100}}>
                <FormProvider {...methods}>
                    <TabsContainer
                        itemName="ticket"
                        filterTabs={getTabToFilter()}
                        ticket={ticket}
                        submit={submit}
                        isLoading={isLoading}
                        response={createResponse || saveResponse}
                        error={createError || saveError}
                    />
                </FormProvider>
            </PageContent>
        </Page>
    )
}

export default Ticket
