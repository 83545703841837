import {
    useCreateUserMutation,
    useSaveUserMutation,
    useGetUserQuery,
    useI18n,
} from 'store/Store'
import {useEffect, useMemo, useState} from 'react'
import Dialog from 'components/Display/DialogForm/Dialog'
import {DialogActions, DialogContent, styled} from '@mui/material'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {volunteerSchema} from 'services/utils/validations/validationSchemas'
import DialogButtons from '../Tickets/NewTicket/NewTicketActions'
import moment from 'moment'
import {formatPhone, getFullAddress} from 'services/utils/formUtils'
import {phoneAlreadyExistsError} from 'services/utils/validations/validationUtils'
import CreateVolunteer from './CreateVolunteer'

const defaultValues = {
    type: 'volunteer',
}

const VolunteerFormDialog = ({volunteerId, handleClose}) => {
    const {i18n} = useI18n()
    const [errorMessage, setErrorMessage] = useState(null)
    const isNew = !volunteerId || volunteerId === 'new'

    const {data: existingVolunteer} = useGetUserQuery(
        {userId: volunteerId},
        {skip: isNew}
    )

    const [doCreateUser, {error: createError, data: createResponse}] =
        useCreateUserMutation()

    const [doUpdateUser, {error: updateError, data: updateResponse}] =
        useSaveUserMutation()

    const methods = useForm({
        defaultValues,
        mode: 'all',
        resolver: yupResolver(volunteerSchema),
    })

    const {handleSubmit, reset, setError} = methods

    const volunteer = useMemo(() => {
        if (volunteerId && existingVolunteer) {
            return existingVolunteer
        }
        return {}
    }, [volunteerId, existingVolunteer])

    useEffect(() => {
        if (volunteer && volunteerId && volunteerId !== 'new') {
            const values = {
                ...volunteer,
                birthDate: moment(volunteer.birthDate),
                phone: formatPhone(volunteer.phone),
                additionalPhone: formatPhone(volunteer.additionalPhone),
                address: {
                    description: getFullAddress(volunteer.address),
                },
            }
            reset(values)
        } else {
            reset(defaultValues)
        }
    }, [volunteer, volunteerId, reset])

    const onCreate = () => {
        submit()
    }

    useEffect(() => {
        if (createResponse || updateResponse) {
            handleClose()
        }
    }, [createResponse, updateResponse, handleClose])

    useEffect(() => {
        const error = createError?.data?.error || updateError?.data?.error
        if (error === 'user already exists') {
            setError('phone', phoneAlreadyExistsError, {shouldFocus: true})
        } else {
            setErrorMessage(error)
        }
    }, [createError, updateError, setError])

    const onClose = () => {
        reset(defaultValues)
        handleClose()
    }

    const onSubmit = async (submittedData) => {
        if (volunteerId) {
            await doUpdateUser(submittedData)
        } else {
            await doCreateUser(submittedData)
        }
    }

    const submit = handleSubmit(onSubmit)

    const okButtonText = i18n.t('form.saveAndContinue')
    const cancelButtonText = i18n.t('form.cancel')

    return (
        <Dialog
            onClose={onClose}
            open={(!!volunteerId && volunteer) || volunteerId === 'new'}
            title={i18n.t(
                `volunteers.${
                    isNew ? 'addVolunteerTitle' : 'editVolunteerTitle'
                }`
            )}>
            <FormProvider {...methods}>
                <DialogContent>
                    <CreateVolunteer errorMessage={errorMessage} />
                </DialogContent>
            </FormProvider>
            <ButtonWrapper>
                <DialogButtons
                    okButtonText={okButtonText}
                    cancelButtonText={cancelButtonText}
                    onOk={onCreate}
                    onCancel={onClose}
                />
            </ButtonWrapper>
        </Dialog>
    )
}

export default VolunteerFormDialog

const ButtonWrapper = styled(DialogActions)`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    padding: 0 18px 18px 18px;
`
