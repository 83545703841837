import {useI18n} from 'store/Store'
import {
    ColumnContainer,
    Title,
} from 'components/Display/Table/TableTitleAndFilters/filtersStyles'
import {ticketClassification, ticketTypes} from 'constants/tickets'
import {useUrlParams} from 'services/hooks'
import Checkbox from 'components/Control/Checkbox'
import {isChecked, toggleFilters} from './utils'

const TicketType = ({itemKey}) => {
    const {i18n} = useI18n()
    const {urlParams, setUrlParam} = useUrlParams()

    const onChange = (fieldName) => (event) => {
        updateFiltersParams(fieldName, event.target.name)
    }

    const updateFiltersParams = (fieldName, value) => {
        const filters = urlParams[fieldName]?.split(',') || []
        const newFilters = toggleFilters(filters, value)
        setUrlParam(fieldName, newFilters)
    }

    const items =
        itemKey === 'category' ? Object.keys(ticketTypes) : ticketClassification

    const getLabel = (item) => {
        const key = itemKey === 'category' ? '' : 'ticketTypes.'
        return i18n.t(`tickets.${key}${item}`)
    }

    return (
        <ColumnContainer>
            <Title>{i18n.t(`tickets.${itemKey}`)}</Title>
            {items.map((item) => (
                <Checkbox
                    name={item}
                    checked={isChecked(urlParams, itemKey, item)}
                    onChange={onChange(itemKey)}
                    value={item}
                    label={getLabel(item)}
                />
            ))}
        </ColumnContainer>
    )
}

export default TicketType
