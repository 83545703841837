import {React} from 'common'
import {useState, useEffect} from 'services/hooks'
import {useNavigate} from 'react-router-dom'
import {Page, PageContent} from 'components/Page'
import {useResetPasswordMutation} from 'store/Store'
import {Controller, useForm} from 'react-hook-form'
import Button from 'components/Control/Button'
import storage from 'services/storage'
import {
    FormActionButton,
    FormContainer,
    FormTitle,
    FormInput,
    FormRow,
    FormAdditionalButton,
    FormAdditionalText,
    FormErrorMessage,
} from 'components/Control/Form'
import FormCheckbox from 'components/Control/Form/FormChecbox'

const getSubmitError = (submittedData) => {
    const {password, confirmPassword} = submittedData
    if (password !== confirmPassword) {
        return 'Passwords do not match'
    }
    return null
}

const ResetPassword = ({match}) => {
    const {params} = match || {}
    const {token} = params || {}
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState(null)

    const [doResetPassword, {error, data: resetPasswordResponse}] =
        useResetPasswordMutation()

    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm()

    const onSubmit = (submittedData) => {
        console.log('submit data', submittedData, token)
        const submitError = getSubmitError(submittedData)
        setErrorMessage()
        if (!submitError) {
            storage.setRememberMe(submittedData?.rememberMe || false)
            doResetPassword({...submittedData, token})
        }
    }

    useEffect(() => {
        setErrorMessage(error?.data?.message?.message)
    }, [error])

    useEffect(() => {
        if (resetPasswordResponse?.error) {
            setErrorMessage(resetPasswordResponse?.error)
        }
    }, [resetPasswordResponse])

    console.log('resetPasswordResponse', resetPasswordResponse)
    if (resetPasswordResponse?.message) {
        return (
            <Page noHeader noDrawer>
                <PageContent>{resetPasswordResponse.message}</PageContent>
                <Button onClick={() => navigate('/login')} variant="contained">
                    Continue
                </Button>
            </Page>
        )
    }

    return (
        <Page noHeader noDrawer>
            <PageContent centered>
                <FormContainer>
                    <FormTitle>Enter New Password</FormTitle>
                    <Controller
                        name={'password'}
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({field: {onChange, value}}) => (
                            <FormInput
                                type="password"
                                onChange={onChange}
                                value={value}
                                fullWidth
                                label="Password"
                            />
                        )}
                    />
                    <FormErrorMessage
                        errors={errors}
                        name={'password'}
                        errorMessage={'password is required.'}
                    />
                    <Controller
                        name={'confirmPassword'}
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({field: {onChange, value}}) => (
                            <FormInput
                                type="password"
                                onChange={onChange}
                                value={value}
                                fullWidth
                                label="Confirm Password"
                            />
                        )}
                    />
                    <FormErrorMessage
                        errors={errors}
                        name={'password'}
                        errorMessage={'confirm password is required.'}
                    />
                    <FormCheckbox
                        name={'rememberMe'}
                        control={control}
                        label="Remember Me"
                    />
                </FormContainer>

                <FormActionButton
                    onClick={handleSubmit(onSubmit)}
                    variant="contained">
                    Reset Password
                </FormActionButton>
                <FormErrorMessage errorMessage={errorMessage} />
                <FormRow>
                    <FormAdditionalText>Have an Account?</FormAdditionalText>
                    <FormAdditionalButton onClick={() => navigate('/login')}>
                        Login
                    </FormAdditionalButton>
                </FormRow>
            </PageContent>
        </Page>
    )
}

export default ResetPassword
