import {styled} from '@mui/material'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {pickupLocations, transportMethods} from 'constants/tickets'
import {useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useLocation, useParams} from 'react-router-dom'
import {setValueOptions} from 'services/utils/formUtils'
import {shouldSkipFetchTicket} from 'services/utils/tickets'
import {useGetTicketQuery} from 'store/slices/api/ticketsApi'
import {useI18n} from 'store/Store'
import TicketTabSection from '../Tickets/TicketTabSection'
import TicketIconsSection from './components/TicketIconsItem'
import TicketFormError from './TicketFormError'
import FormAddressComponent from 'components/Control/Form/FormAddressComponent'

const TicketRoute = () => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const {
        control,
        watch,
        setValue,
        formState: {errors: formErrors},
    } = formContext

    const values = watch()
    const {state: elder} = useLocation()

    const {ticketId} = useParams()
    const {data: ticket} = useGetTicketQuery(ticketId, {
        skip: shouldSkipFetchTicket(ticketId),
    })

    const getSelectedItem = (selectedName) => {
        if (!ticket) {
            return null
        }
        if (selectedName === 'endAddress' && !ticket.endAddress) {
            return 'none'
        }
        return ticket[selectedName]?._id === ticket.elder?.address?._id
            ? 'elderHome'
            : 'otherLocation'
    }

    const [activeItems, setActiveItems] = useState({
        startAddressType: getSelectedItem('startAddress'),
        destinationAddressType: getSelectedItem('destinationAddress'),
        endAddressType: getSelectedItem('endAddress'),
    })

    const handleSelectItem = (fieldName, selectedItem) => {
        setValue(fieldName, selectedItem, setValueOptions)

        if (Object.keys(activeItems).includes(fieldName)) {
            const addressField = fieldName.split('Type')[0]
            let newValue
            if (selectedItem === 'elderHome') {
                newValue = ticket?.elder?.address ?? elder?.address
            }
            if (selectedItem === 'otherLocation') {
                newValue = {
                    name: values.categoryLocationName,
                    workingHours: values.categoryLocationWorkingHours,
                    ...values.categoryLocationAddress,
                    type: 'OTHER',
                }
            }
            if (selectedItem === 'none') {
                newValue = null
            }

            setValue(addressField, newValue, setValueOptions)
            setActiveItems({...activeItems, [fieldName]: selectedItem})
        }
    }

    const renderInputs = (fieldName) => {
        const components = {
            elderHome: <ElderAddressInputs fieldName={fieldName} />,
            otherLocation: <CustomLocationInputs fieldName={fieldName} />,
            none: <></>,
        }
        return components[activeItems[fieldName]]
    }

    return (
        <Container>
            <TicketTabSection
                testKey={'startAddressType'}
                title={i18n.t('tickets.ticketRoutePickup_title')}>
                <TicketIconsSection
                    fieldName="startAddressType"
                    itemName="ticket"
                    types={pickupLocations}
                    allowAdd={false}
                    activeItem={activeItems.startAddressType}
                    handleSelectItem={handleSelectItem}
                />
                <InputsContainer>
                    {renderInputs('startAddressType')}
                </InputsContainer>

                <TicketFormError
                    fieldName="startAddress"
                    errors={formErrors}
                    shouldShowError={!values.startAddressType}
                />
            </TicketTabSection>

            <TicketTabSection
                testKey={'destinationAddressType'}
                title={i18n.t('tickets.ticketRouteDropoff_title')}>
                <TicketIconsSection
                    fieldName="destinationAddressType"
                    itemName="ticket"
                    types={pickupLocations}
                    allowAdd={false}
                    activeItem={activeItems.destinationAddressType}
                    handleSelectItem={handleSelectItem}
                />
                <InputsContainer>
                    {renderInputs('destinationAddressType')}
                </InputsContainer>

                <TicketFormError
                    fieldName="destinationAddress"
                    errors={formErrors}
                    shouldShowError={!values.destinationAddressType}
                />
            </TicketTabSection>

            <TicketTabSection
                testKey={'endAddressType'}
                title={i18n.t('tickets.ticketRouteReturn_title')}>
                <TicketIconsSection
                    fieldName="endAddressType"
                    itemName="ticket"
                    types={[...pickupLocations, 'none']}
                    allowAdd={false}
                    activeItem={activeItems.endAddressType}
                    handleSelectItem={handleSelectItem}
                />
                <InputsContainer>
                    {renderInputs('endAddressType')}
                </InputsContainer>

                <TicketFormError
                    fieldName="endAddress"
                    errors={formErrors}
                    shouldShowError={!values.endAddressType}
                />
            </TicketTabSection>

            <TicketTabSection
                testKey={'transportMethod'}
                title={i18n.t('tickets.ticketRouteTransport_title')}>
                <TicketIconsSection
                    fieldName="transportMethod"
                    itemName="ticket"
                    types={transportMethods}
                    allowAdd={false}
                    activeItem={values.transportMethod}
                    handleSelectItem={handleSelectItem}
                />

                <TicketFormError
                    fieldName="transportMethod"
                    errors={formErrors}
                    shouldShowError={formErrors.transportMethod}
                />
            </TicketTabSection>

            <TicketTabSection
                testKey={'routeComments'}
                title={i18n.t('tickets.notesForVolunteer_route')}>
                <FormTextInput
                    name="routeComments"
                    label={i18n.t('ticketForm.importantNotes')}
                    control={control}
                    fullWidth
                    errors={formErrors}
                    inputProps={{maxLength: 500}}
                />
            </TicketTabSection>
        </Container>
    )
}

export default TicketRoute

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
const InputsContainer = styled('div')`
    display: flex;
    gap: 16px;
    width: 100%;
    margin-block: 24px;
    & > * {
        flex: 2;
    }
`

const CustomLocationInputs = ({fieldName}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const field = fieldName.split('Type')[0]

    const setAddressValue = (address) => {
        formContext.setValue(field, {...address}, setValueOptions)
    }

    const handleInputChange = (value, name) => {
        formContext.setValue(name, value, setValueOptions)
    }

    return (
        <>
            <FormTextInput
                fullWidth
                nested
                name={`${field}.name`}
                label={i18n.t('ticketForm.locationName')}
                control={formContext.control}
                errors={formContext.formState.errors}
                handleInputChange={handleInputChange}
            />

            <FormAddressComponent
                fullWidth
                name={field}
                label={i18n.t('ticketForm.address')}
                control={formContext.control}
                errors={formContext.formState.errors}
                setAddressValue={setAddressValue}
            />

            <FormTextInput
                fullWidth
                nested
                name={`${field}.workingHours`}
                label={i18n.t('ticketForm.workingHours')}
                control={formContext.control}
                errors={formContext.formState.errors}
                handleInputChange={handleInputChange}
            />
        </>
    )
}
const ElderAddressInputs = ({fieldName}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const field = fieldName.split('Type')[0]

    const setAddressValue = (address) => {
        formContext.setValue(field, {...address}, setValueOptions)
    }

    return (
        <div style={{width: '30%', display: 'flex', justifyContent: 'center'}}>
            <FormAddressComponent
                itemName="users"
                name={field}
                mainInputName="address"
                label={i18n.t(`users.address_title`)}
                control={formContext.formControl}
                required={field.required}
                errors={formContext.formState.errors}
                setAddressValue={setAddressValue}
            />
        </div>
    )
}
