import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const transformResponseFixFloor = (response) => {
    if (response.address?.floor === 0) {
        response.address.floor = 'קרקע'
    }
    return response
}
const usersApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query({
            query: (query) => {
                const url = constructUrl('users', {
                    ...query,
                })

                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('User'),
        }),
        getUser: build.query({
            query: ({userId}) => ({
                url: `users/${userId}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFixFloor,
            providesTags: () => provideTag('User'),
        }),
        createUser: build.mutation({
            query: (user) => {
                console.log('create user mutation req', user)
                return {
                    url: `users`,
                    method: 'POST',
                    body: user,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('User'),
        }),
        saveUser: build.mutation({
            query: (user) => {
                console.log('save user mutation req', user)
                return {
                    url: `users/${user._id}`,
                    method: 'PUT',
                    body: user,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['User', 'Ticket'],
        }),
        deleteUser: build.mutation({
            query: (id) => {
                console.log('delete user mutation req', id)
                return {
                    url: `users/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('User'),
        }),
        getElders: build.query({
            query: (query) => {
                const url = constructUrl('users', {
                    ...query,
                })

                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('User'),
        }),
        getVolunteers: build.query({
            query: (query) => {
                const url = constructUrl('users', {...query, type: 'volunteer'})
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('User'),
        }),
        getSocialWorkers: build.query({
            query: ({page, pageSize, search, sort}) => {
                const searchStr = search ? `&search=${search}` : ''
                const paginationStr = `&page=${page}&pageSize=${pageSize}`
                const sortStr = sort ? `&sort=${sort}` : ''

                return {
                    url: `users?type=social_worker${paginationStr}${searchStr}${sortStr}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('User'),
        }),
        getUsersCities: build.query({
            query: (query) => {
                const url = constructUrl('users/cities', query)
                console.log({url})
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('User'),
        }),
    }),
    overrideExisting: false,
})

export default usersApi

export const {
    useGetUserQuery,
    useGetUsersQuery,
    useCreateUserMutation,
    useSaveUserMutation,
    useDeleteUserMutation,
    useGetEldersQuery,
    useGetSocialWorkersQuery,
    useGetVolunteersQuery,
    useGetUsersCitiesQuery,
} = usersApi
