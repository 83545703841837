import {React, styled} from 'common'
import {Colors} from 'appearance'
import {useState} from 'services/hooks'
import Header from 'components/Page/Header'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import {useGetCurrentUserQuery, useI18n} from 'store/Store'
import {sendAxiosRequest} from 'store/slices/api/baseApi'
import SideMenu from './SideMenu'
import NewTicket from './Dashboard/Tickets/NewTicket/NewTicket'

const Page = ({title, menuTitle, children, noHeader, noDrawer}) => {
    const {i18n} = useI18n()

    const {data: user} = useGetCurrentUserQuery()

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [createOpen, setCreateOpen] = useState(false)

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }

    const onClickCreate = () => {
        setCreateOpen(true)
    }

    const handleCloseCreate = () => {
        setCreateOpen(false)
    }

    return (
        <Container dir={i18n.t('dir') || 'ltr'}>
            <CssBaseline />

            {!noHeader && (
                <Header
                    toggleDrawer={toggleDrawer}
                    open={drawerOpen}
                    user={user}
                    onClickCreate={onClickCreate}
                />
            )}

            {!noDrawer && (
                <SideMenu
                    open={drawerOpen}
                    handleClose={handleDrawerClose}
                    toggleDrawer={toggleDrawer}
                    user={user}
                />
            )}
            <Content component="main" noheader={noHeader}>
                {children}
            </Content>
            <ClearTestDataButton
                data-test-id="clear-test-data"
                onClick={() =>
                    sendAxiosRequest(null, 'tests/clearTestData', 'GET')
                }
            />
            <NewTicket open={createOpen} handleClose={handleCloseCreate} />
        </Container>
    )
}

export default Page

const Container = styled(Box)`
    flex-grow: 1;
    background-color: ${Colors.APP_WHITE};
    min-height: 100vh;
    overflow-x: hidden;
`

const Content = styled(Box)`
    flex-grow: 1;
    padding: 64px 65px 64px 0;
    display: flex;
    flex-direction: column;
    align-items: ${(p) => (p.noheader ? 'center' : 'flex-start')};
`

const ClearTestDataButton = styled.div`
    width: 5px;
    height: 5px;
    position: fixed;
    z-index: 100000;
    top: 0px;
    left: 0px;
`
