import {Autocomplete as RawAutocomplete, styled, TextField} from '@mui/material'
import {useI18n} from 'store/Store'
import {Label} from './Form'
import {filterNonDOMProps} from 'services/utils/formUtils'

const AutocompleteInput = ({
    name,
    label,
    InputProps,
    options,
    onChange,
    getOptionLabel,
    value,
    onInputChange,
    inputValue,
    inputKey,
    placeholder,
    defaultValue,
    renderOption,
    filterOptions,
    open,
    onOpen,
    onClose,
    isLoading,
    ...props
}) => {
    const {i18n} = useI18n()
    const validDivProps = filterNonDOMProps(props)

    return (
        <>
            {label && <Label>{label}</Label>}
            <Autocomplete
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                loading={isLoading}
                clearOnBlur={false}
                key={`${name}-${inputKey}`}
                name={name}
                defaultValue={defaultValue}
                getOptionLabel={getOptionLabel}
                filterOptions={filterOptions}
                options={options || []}
                inputValue={inputValue}
                onChange={onChange}
                value={value}
                onInputChange={onInputChange}
                noOptionsText={i18n.t('form.autocompleteNoOptionsText')}
                renderOption={renderOption}
                slotProps={{
                    popper: {
                        sx: {
                            direction: 'rtl',
                            '& li': {
                                flex: 1,
                            },
                        },
                    },
                }}
                freeSolo={true}
                forcePopupIcon={true}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        hiddenLabel
                        variant="filled"
                        placeholder={placeholder}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            ...InputProps,
                        }}
                        inputProps={{
                            ...params.inputProps,
                            'data-testid': `${name}_field`,
                        }}
                    />
                )}
                {...validDivProps}
            />
        </>
    )
}

export default AutocompleteInput

const Autocomplete = styled(RawAutocomplete)`
    & .MuiInputBase-root {
        height: 40px;
        padding-inline: 8px;
    }

    & .MuiFilledInput-root.MuiInputBase-hiddenLabel .MuiAutocomplete-input {
        height: 40px;
        padding: 0;
    }

    & .MuiFilledInput-root .MuiAutocomplete-endAdornment {
        left: 9px;
        right: unset;
    }

    &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
        .MuiFilledInput-root {
        padding-inline: 8px;
    }
    &.MuiAutocomplete-hasPopupIcon .MuiFilledInput-root {
        padding-inline: 8px;
    }
`
