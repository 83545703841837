import {styled} from '@mui/material'
import {Texts} from 'appearance'
import Button from 'components/Control/Button'
import {RenderTicketNotes} from 'components/Page/Dashboard/Ticket/TicketSummary'
import EditTicketStatus from 'components/Page/Dashboard/Ticket/components/EditTicketStatus'
import VolunteerDetails from 'components/Page/Dashboard/Tickets/TicketDialog/VolunteerDetails'
import TicketTabSection from 'components/Page/Dashboard/Tickets/TicketTabSection'
import {useState} from 'react'
import {
    formatPhone,
    getFullAddress,
    getFullName,
    getUserType,
} from 'services/utils/formUtils'
import {formatKeyFunctionsByType} from 'services/utils/tables'
import {useI18n} from 'store/Store'
import {useGetTicketQuery} from 'store/slices/api/ticketsApi'

const translateOrFallback = (i18n, path, key, fallback = '-') =>
    key ? i18n.t(`${path}.${key}`) : '-'

const TicketDialog = ({ticketId}) => {
    const {i18n} = useI18n()
    const {data: ticket} = useGetTicketQuery(ticketId, {skip: !ticketId})
    console.log('ticket', ticket)
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false) // State for dialog

    const handleOpenDialog = () => {
        setIsEditDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setIsEditDialogOpen(false)
    }

    const elderName = getFullName(ticket?.elder)
    const subCategoryOptions = ticket?.subCategoryOptions
        .map((item) => i18n.t(`tickets.${item}`))
        .join(', ')

    const creatorType = getUserType(ticket?.creator)

    const getIssuerType = () => {
        if (ticket?.issuer) {
            const issuerType = getUserType(ticket.issuer)
            return i18n.t(`users.userTypes.${issuerType}`)
        }
        return i18n.t(`tickets.issuerSources.${ticket?.issuerSource}`)
    }

    const formatTicketRequestor = () => {
        if (ticket?.issuer) {
            return getFullName(ticket?.issuer)
        }
        const phone = ticket?.issuerPhone ? `(${ticket?.issuerPhone})` : ''
        return `${ticket?.issuerName} ${phone}`
    }

    const ticketTime = ticket?.availableTimes[0]?.time ?? ''

    const cancellationReason = translateOrFallback(
        i18n,
        'tickets.cancellationReasons',
        ticket?.cancellationReason
    )

    const cancellationInitiator = translateOrFallback(
        i18n,
        'tickets.cancellationInitiators',
        ticket?.cancellationInitiator
    )

    const sendAnotherVolunteer = translateOrFallback(
        i18n,
        `tickets`,
        ticket?.sendAnotherVolunteer ? 'yes' : 'no'
    )

    return (
        <Container>
            <TicketTabSection
                testKey="elderDetails"
                title={i18n.t('tickets.elder')}>
                <DetailsRow>
                    <DetailName>{i18n.t('tickets.elderName')}:</DetailName>
                    <DetailValue>{elderName}</DetailValue>
                </DetailsRow>

                <DetailsRow>
                    <DetailName>{i18n.t('users.gender')}:</DetailName>
                    <DetailValue>
                        {i18n.t(`users.genders.${ticket?.elder?.gender}`)}
                    </DetailValue>
                </DetailsRow>

                <DetailsRow>
                    <DetailName>{i18n.t('users.address_title')}:</DetailName>
                    <DetailValue>
                        {getFullAddress(ticket?.elder?.address)}
                    </DetailValue>
                </DetailsRow>

                <DetailsRow>
                    <DetailName>{i18n.t('users.phone')}:</DetailName>
                    <DetailValue>
                        {formatPhone(ticket?.elder?.phone)}
                    </DetailValue>
                </DetailsRow>
            </TicketTabSection>

            <TicketTabSection
                testKey="ticketDetails"
                title={i18n.t('tickets.ticketDetails')}>
                <TicketDetails>
                    <SectionColumn>
                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.ticketNumber')}:
                            </DetailName>
                            <DetailValue>{ticket?.ticketNumber}</DetailValue>
                        </DetailsRow>

                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.creator')}:
                            </DetailName>
                            <DetailValue>{`${getFullName(
                                ticket?.creator
                            )} (${i18n.t(
                                `users.userTypes.${creatorType}`
                            )})`}</DetailValue>
                        </DetailsRow>

                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.ticketSource')}:
                            </DetailName>
                            <DetailValue>{getIssuerType()}</DetailValue>
                        </DetailsRow>

                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.ticketRequestor')}:
                            </DetailName>
                            <DetailValue>{formatTicketRequestor()}</DetailValue>
                        </DetailsRow>

                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.ticket_type')}:
                            </DetailName>
                            <DetailValue>
                                {i18n.t(`tickets.${ticket?.category}`)}
                            </DetailValue>
                        </DetailsRow>

                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.subCategory')}:
                            </DetailName>
                            <DetailValue>
                                {`${i18n.t(
                                    `tickets.${ticket?.subCategory}`
                                )} - ${subCategoryOptions}`}
                            </DetailValue>
                        </DetailsRow>

                        {['escort', 'delivery'].includes(ticket?.category) && (
                            <>
                                <DetailsRow>
                                    <DetailName>
                                        {i18n.t('ticketForm.startAddress')}:
                                    </DetailName>
                                    <DetailValue>
                                        {getFullAddress(ticket?.startAddress)}
                                    </DetailValue>
                                </DetailsRow>

                                <DetailsRow>
                                    <DetailName>
                                        {i18n.t(
                                            'ticketForm.destinationAddress'
                                        )}
                                        :
                                    </DetailName>
                                    <DetailValue>
                                        {getFullAddress(
                                            ticket?.destinationAddress
                                        )}
                                    </DetailValue>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailName>
                                        {i18n.t('ticketForm.endAddress')}:
                                    </DetailName>
                                    <DetailValue>
                                        {getFullAddress(ticket?.endAddress)}
                                    </DetailValue>
                                </DetailsRow>
                            </>
                        )}

                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.availableTimes')}:
                            </DetailName>
                            <DetailValue>
                                {`${formatKeyFunctionsByType.ticketTimes(
                                    ticket?.availableTimes,
                                    undefined,
                                    ticket
                                )} ${ticketTime}`}
                            </DetailValue>
                        </DetailsRow>

                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.created')}:
                            </DetailName>
                            <DetailValue>
                                {formatKeyFunctionsByType.datetime(
                                    ticket?.created
                                )}
                            </DetailValue>
                        </DetailsRow>

                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.completedDate')}:
                            </DetailName>
                            <DetailValue>
                                {formatKeyFunctionsByType.datetime(
                                    ticket?.completedDate
                                )}
                            </DetailValue>
                        </DetailsRow>

                        <DetailsRow>
                            <DetailName style={{alignSelf: 'start'}}>
                                {i18n.t('tickets.importantNotes')}:
                            </DetailName>
                            <DetailValue>
                                <RenderTicketNotes
                                    detailsComments={ticket?.detailsComments}
                                    additionalContact={
                                        ticket?.additionalContact
                                    }
                                    routeComments={ticket?.routeComments}
                                    internalComments={ticket?.internalComments}
                                />
                            </DetailValue>
                        </DetailsRow>
                    </SectionColumn>

                    <SectionColumn>
                        <DetailsRow>
                            <DetailName>{i18n.t('tickets.status')}:</DetailName>
                            <DetailValue>
                                {i18n.t(`tickets.statuss.${ticket?.status}`)}
                            </DetailValue>
                        </DetailsRow>

                        {ticket?.status === 'canceled' && (
                            <>
                                <DetailsRow>
                                    <DetailName>
                                        {i18n.t(
                                            'tickets.filters.cancellationInitiator'
                                        )}
                                        :
                                    </DetailName>
                                    <DetailValue>
                                        {cancellationInitiator}
                                    </DetailValue>
                                </DetailsRow>
                                <DetailsRow>
                                    <DetailName>
                                        {i18n.t(
                                            'tickets.filters.cancellationReason'
                                        )}
                                        :
                                    </DetailName>
                                    <DetailValue>
                                        {cancellationReason}
                                    </DetailValue>
                                </DetailsRow>
                            </>
                        )}
                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.sendAnotherVolunteer')}:
                            </DetailName>
                            <DetailValue>{sendAnotherVolunteer}</DetailValue>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailName>
                                {i18n.t('tickets.ticketType')}:
                            </DetailName>
                            <DetailValue>
                                {i18n.t(
                                    `tickets.ticketTypes.${ticket?.ticketType}`
                                )}
                            </DetailValue>
                        </DetailsRow>
                    </SectionColumn>
                </TicketDetails>
            </TicketTabSection>

            <TicketTabSection
                testKey="volunteerDetails"
                title={i18n.t('tickets.volunteerDetails')}>
                <VolunteerDetails
                    volunteer={ticket?.volunteers[0]}
                    feedback={ticket?.feedback}
                />
            </TicketTabSection>

            {/* Button to open the EditTicketStatus dialog */}
            <Button
                color="primary"
                onClick={handleOpenDialog}
                name="editTicketStatus">
                {i18n.t('ticketMenu.changeTicketStatus')}
            </Button>

            {/* EditTicketStatus dialog */}
            <EditTicketStatus
                ticket={ticket}
                open={isEditDialogOpen}
                onClose={handleCloseDialog}
            />
        </Container>
    )
}

export default TicketDialog

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
`
const DetailsRow = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;
`

const DetailName = styled(Texts.RegularText)`
    font-weight: 500;
    flex-shrink: 0;
`

const DetailValue = styled(Texts.RegularText)`
    font-weight: 300;
`
const TicketDetails = styled('div')`
    display: flex;
    gap: 150px;
`

const SectionColumn = styled('div')`
    flex: 1;
`
