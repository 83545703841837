import {React} from 'common'
import {useNavigate} from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import Button from 'components/Control/Button'
import {useI18n, useIsRtl} from 'store/Store'

const TableAddButton = ({itemName, show, addFunction}) => {
    const navigate = useNavigate()
    const isRtl = useIsRtl()
    const {i18n} = useI18n()

    if (!show) {
        return null
    }

    return (
        <Button
            onClick={() =>
                addFunction ? addFunction() : navigate(`/${itemName}/new`)
            }
            variant="contained"
            sx={{
                marginLeft: isRtl ? '0px' : '20px',
                marginRight: isRtl ? '20px' : '0px',
                borderRadius: 1,
            }}>
            {i18n.t(`tables.addButtonText`, {
                type: i18n.t(`${itemName}s.${itemName}`),
            })}
            <AddIcon />
        </Button>
    )
}

export default TableAddButton
