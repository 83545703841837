import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const ticketsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        createTicket: build.mutation({
            query: (ticket) => {
                return {
                    url: `tickets`,
                    method: 'POST',
                    body: ticket,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['Ticket'],
        }),
        saveTicket: build.mutation({
            query: (ticket) => {
                return {
                    url: `tickets/${ticket._id}`,
                    method: 'PUT',
                    body: ticket,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Ticket'),
        }),
        deleteTicket: build.mutation({
            query: (id) => {
                return {
                    url: `tickets/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Ticket'),
        }),
        getTickets: build.query({
            query: (query) => {
                const url = constructUrl('tickets', {
                    ...query,
                })
                return {
                    url,
                    method: 'GET',
                }
            },

            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Ticket'),
        }),
        getTicketsByElderId: build.query({
            query: (query) => {
                const {elderId, ...params} = query
                const url = constructUrl(`tickets/elder/${elderId}`, {
                    ...params,
                })

                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Ticket'),
        }),
        getTicket: build.query({
            query: (id) => ({
                url: `tickets/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Ticket'),
        }),
        getTicketsCities: build.query({
            query: () => {
                return {
                    url: 'tickets/elderCities',
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Ticket'),
        }),
    }),
})

export default ticketsApi

export const {
    useSaveTicketMutation,
    useGetTicketsQuery,
    useDeleteTicketMutation,
    useCreateTicketMutation,
    useGetTicketQuery,
    useGetTicketsByElderIdQuery,
    useGetTicketsCitiesQuery,
} = ticketsApi
