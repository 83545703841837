import {styled} from 'common'
import RawTable from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableTitleAndFilters from './Table/TableTitleAndFilters'
import TableBody from './Table/TableBody'
import TablePagination from './Table/TablePagination'
import TableHeadRow from './Table/TableHeadRow'
import {getTableData} from 'services/utils/tables'
import {useI18n} from 'store/Store'
import {useUrlParams} from 'services/hooks'
import {useEffect, useState} from 'react'
import {FormErrorText} from 'components/Control/Form'
import {INITIAL_PAGE_SIZE} from 'constants/tables'
import Button from 'components/Control/Button'
import {Link} from '@mui/material'
import api from 'services/api'
import {constructUrl} from 'store/slices/api/utils'
import useDebounce from 'logic/Forms/useDebounce'

const getDownloadLink = (
    itemName,
    page,
    pageSize,
    search,
    sort,
    additionalQueries
) => {
    const itemType = itemName === 'ticket' ? 'ticket' : 'user'
    const params = {
        type: itemName === 'ticket' ? undefined : itemName,
        page: page || 0,
        pageSize: pageSize || INITIAL_PAGE_SIZE,
        search,
        sort,
        ...additionalQueries,
    }
    return constructUrl(`${api.baseUrl}/${itemType}s/download`, params)
}

const Table = ({
    itemName,
    itemType,
    total,
    visibleKeys,
    onDelete,
    allowExpand,
    allowEdit,
    allowAdd,
    addFunction,
    editFunction,
    deleteFunction,
    allowDelete,
    allowDownload,
    filters,
    children,
    paginationType, // 'local', 'external' (server) or null
    useGetQuery,
    useDeleteMutation,
    query = {},
    CustomExpand,
    hideTitle,
    titleTextAlign,
    buttonsGroupOptions,
    selectedToggleButton,
    setSelectedToggleButton,
    onRowClick,
    deletePrompt,
    customRowActions,
    setRowColor,
    ...p
}) => {
    const {i18n} = useI18n()
    const {urlParams} = useUrlParams()
    const {
        [`${itemName}_search`]: search,
        [`${itemName}_page`]: page,
        [`${itemName}_pageSize`]: pageSize,
        [`${itemName}_sort`]: sort,
        ...filter
    } = urlParams

    const debouncedValue = useDebounce(search, 500)
    const [errorMessage, setErrorMessage] = useState(null)
    const additionalQueries = {...query, ...filter}

    const {data, refetch, isFetching} = useGetQuery(
        paginationType === 'external'
            ? {
                  page: page || 0,
                  pageSize: pageSize || INITIAL_PAGE_SIZE,
                  search: debouncedValue,
                  sort,
                  ...additionalQueries,
              }
            : {...additionalQueries}
    )

    const [doDelete, {error}] = useDeleteMutation()

    const handleDelete = (itemId, tableRowData) => {
        deleteFunction ? deleteFunction(itemId) : doDelete(itemId)
    }

    const clearAllFilters = () => {
        window.history.pushState(null, '', window.location.pathname)
        window.location.reload()
    }
    useEffect(() => {
        if (data) {
            console.log('table_data', data)
        }
    }, [data, query])

    useEffect(() => {
        setErrorMessage(error?.data?.message)
    }, [error])

    useEffect(() => {
        if (paginationType === 'external') {
            refetch()
        }
    }, [urlParams, refetch, paginationType])

    const tableName = `${itemName}s`
    const {tableKeys, tableData, keyTypes, tableItems, totalItems} =
        getTableData(
            tableName,
            visibleKeys,
            data,
            page,
            pageSize,
            search,
            sort,
            paginationType
        )

    const handleSelectedType = (event, newValue) => {
        const {value: selectedButton} = event.target

        setSelectedToggleButton((prev) =>
            selectedButton === prev ? prev : newValue
        )
    }

    return (
        <Container>
            <TableTitleAndFilters
                title={i18n.t(`tables.${tableName}`)}
                itemType={itemType}
                useSearch
                allowAdd={allowAdd}
                addFunction={addFunction}
                itemName={itemName}
                hideTitle={hideTitle}
                textAlign={titleTextAlign}
                selectedToggleButton={selectedToggleButton}
                buttonsGroupOptions={buttonsGroupOptions}
                handleChange={handleSelectedType}
                clearAllFilters={clearAllFilters}
                isLoading={isFetching}>
                {filters}
            </TableTitleAndFilters>

            <StyledTableContainer component={Paper}>
                <StyledTable
                    sx={{minWidth: 650}}
                    {...p}
                    aria-label="simple table">
                    <TableHeadRow
                        itemName={itemName}
                        onDelete={handleDelete}
                        allowExpand={allowExpand}
                        allowEdit={allowEdit}
                        tableKeys={tableKeys}
                    />
                    <TableBody
                        setRowColor={setRowColor}
                        itemName={itemName}
                        tableKeys={tableKeys}
                        keyTypes={keyTypes}
                        tableItems={tableItems}
                        tableData={tableData}
                        onDelete={handleDelete}
                        allowExpand={allowExpand}
                        allowEdit={allowEdit}
                        editFunction={editFunction}
                        allowDelete={allowDelete}
                        CustomExpand={CustomExpand}
                        onRowClick={onRowClick}
                        deletePrompt={deletePrompt}
                        customRowActions={customRowActions}>
                        {children}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>
            <TablePagination
                itemName={itemName}
                paginationType={paginationType}
                totalItems={totalItems}
            />
            {allowDownload && (
                <DownloadLink
                    href={getDownloadLink(
                        itemName,
                        0,
                        totalItems,
                        search,
                        sort,
                        additionalQueries
                    )}>
                    <DownloadButton>Excel</DownloadButton>
                </DownloadLink>
            )}
            {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}
        </Container>
    )
}

export default Table

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const StyledTableContainer = styled(TableContainer)`
    width: 100%;
    margin-bottom: 0px;
`
const StyledTable = styled(RawTable)`
    width: 100%;
`

const DownloadButton = styled(Button)`
    width: 100px;
    align-self: flex-end;
`

const DownloadLink = styled(Link)`
    align-self: flex-end;
`
