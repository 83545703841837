const REMEMBER_ME_KEY = 'REMEMBER_ME'
const LANGUAGE_KEY = 'LANGUAGE'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyACm71PK84v03MP7St4wV6GMWOqz-F8OFk'

const getKeyWithBase = (key) => {
    const keyBase = `@${window.programmaticAppName || 'undefined'}:`
    return keyBase + key
}

export const setJsonData = (key, obj) =>
    localStorage.setItem(key, JSON.stringify(obj || {}))

export const getJsonData = (key) =>
    JSON.parse(localStorage.getItem(key) || '{}')

export const setArrayData = (key, arr) =>
    localStorage.setItem(key, JSON.stringify(arr || []))

export const getArrayData = (key) =>
    JSON.parse(localStorage.getItem(key) || '[]')

export const addItemToArrayData = async (
    key,
    item,
    prevArr = null,
    place = 'last'
) => {
    let arr = prevArr || getArrayData(key)
    if (place === 'last') {
        arr.push(item)
    } else {
        arr = [item, ...arr]
    }
    await setArrayData(key, arr)
    return arr
}

const storage = {
    clearAll: () => localStorage.clear(),

    setRememberMe: (value) => {
        localStorage.setItem(getKeyWithBase(REMEMBER_ME_KEY), value)
    },
    getRememberMe: () =>
        localStorage.getItem(getKeyWithBase(REMEMBER_ME_KEY)) || false,

    setLanguage: (language) =>
        localStorage.setItem(getKeyWithBase(LANGUAGE_KEY), language),
    getLanguage: () => localStorage.getItem(getKeyWithBase(LANGUAGE_KEY)),
}

export default storage
