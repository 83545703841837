import {ticketTypes} from 'constants/tickets'

export const phoneAlreadyExistsError = {
    type: 'custom',
    message: 'מספר טלפון קיים במערכת',
}

export const showLocationInputs = (category, subCategory) => {
    if (!category || !subCategory) {
        return false
    }
    const requireLocation = ['escort', 'delivery']
    return category && subCategory && requireLocation.includes(category)
}

export const showSelectAssistance = (category, subCategory) =>
    category && subCategory && !!ticketTypes[category]?.[subCategory]?.length

export const createAddressError = (address) => {
    const error = []
    if (address?.city) {
        error.push('עיר')
    }
    if (address?.street) {
        error.push('רחוב')
    }
    if (address?.houseNumber) {
        error.push("מס' בית")
    }

    return error.length === 0 ? null : `חסר ${error.join(', ')}`
}

export const isIsraeliIdValid = (id) => {
    let strId = String(id).trim().padStart(9, '0')
    const counter = strId
        .split('')
        .reduce((accumulator, currentValue, index) => {
            const rawVal = Number(currentValue) * ((index % 2) + 1)
            return accumulator + (rawVal > 9 ? rawVal - 9 : rawVal)
        }, 0)
    return counter % 10 === 0
}
