import {Divider, styled} from '@mui/material'
import {Colors, Texts} from 'appearance'
import {dayTimeSlots} from 'constants/tickets'
import {useI18n} from 'store/Store'
import Button from './Button'
import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check'
import {useFormContext} from 'react-hook-form'

const TimeSlotsSelection = ({slotDays, handleSelectSlot}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const values = formContext.watch()

    const parseDateString = (date) => {
        const day = date.utc(true).date()
        const month = date.format('MMMM')

        return i18n.t('general.dayAndMonth', {day, month})
    }

    const isSelected = (day, timeSlot) => {
        const {availableTimes} = values
        const dayDate = day.utc(true)
        const index = availableTimes.findIndex((time) => {
            const date = moment(time.date, 'YYYY-MM-DD').utc(true)
            return date.isSame(dayDate, 'day')
        })

        return availableTimes[index]?.[timeSlot]
    }

    return (
        <Container>
            <Row>
                <Slot />
                {slotDays.map((day) => (
                    <Slot key={day}>
                        <SlotTitle>
                            {i18n.t(`ticketForm.day`, {
                                day: day.format('ddd'),
                            })}
                        </SlotTitle>
                        <SlotDescription>
                            {parseDateString(day)}
                        </SlotDescription>
                    </Slot>
                ))}
            </Row>
            <Divider style={{width: '100%', borderColor: Colors.BORDER}} />
            {dayTimeSlots.map((slot) => (
                <Row key={slot}>
                    <Slot>
                        <SlotTitle>
                            {i18n.t(`ticketForm.dayTimeSlots.${slot}`)}
                        </SlotTitle>
                        <SlotDescription>
                            {i18n.t(`ticketForm.dayTimeSlots.${slot}_hours`)}
                        </SlotDescription>
                    </Slot>
                    {slotDays.map((day, index) => {
                        const selected = isSelected(day, slot, index)
                        return (
                            <Slot key={day}>
                                <SlotButton
                                    data-testid={`${day.format(
                                        'DD.MM'
                                    )}_${slot}_button`}
                                    key={`${slot}-${day}`}
                                    selected={selected}
                                    onClick={() =>
                                        handleSelectSlot(day, slot, index)
                                    }>
                                    {selected ? (
                                        <CheckIcon />
                                    ) : (
                                        i18n.t('ticketForm.available')
                                    )}
                                </SlotButton>
                            </Slot>
                        )
                    })}
                </Row>
            ))}
        </Container>
    )
}

export default TimeSlotsSelection

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 48px auto;
    max-width: 100%;
    gap: 12px;
`
const Row = styled('div')`
    display: flex;
    width: 100%;
    gap: 12px;
`

const Slot = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 56px;
    width: 10rem;
`

const SlotTitle = styled(Texts.BoldText)``

const SlotDescription = styled(Texts.RegularText)``

const SlotButton = styled(Button)`
    height: 100%;
    width: 100%;
    background: ${({selected}) =>
        selected ? Colors.PRIMARY_COLOR : Colors.SECONDARY_COLOR};
`
