import 'app_globals'
import React from 'react'
import './App.css'
import NavigationRouter from 'navigation/NavigationRouter'
import {ThemeProvider} from '@mui/material/styles'
import {Provider} from 'react-redux'
import store from 'store/Store'
import theme from 'theme'

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <NavigationRouter />
            </Provider>
        </ThemeProvider>
    )
}

export default App
