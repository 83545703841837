import {React} from 'common'
import {Controller} from 'react-hook-form'
import {parseObjectPath, filterNonDOMProps} from 'services/utils/formUtils'
import {FormErrorText} from '.'
import Input from '../Input'

const FormTextInput = ({
    name,
    label,
    control,
    errors,
    rules,
    required,
    disabled,
    width,
    fullWidth,
    type,
    initialValue,
    transformOnChange,
    transformValue,
    nested,
    inputProps,
    style,
    placeholder,
    handleInputChange,
    InputProps,
    ...p
}) => {
    const validDivProps = filterNonDOMProps(p)

    const handleChange = (onChange) => (event) => {
        const newValue = transformOnChange
            ? transformOnChange(event)
            : event.target.value
        handleInputChange && handleInputChange(newValue, name)
        onChange(newValue)
    }

    const updateValue = (value) => {
        return transformValue ? transformValue(value) : value
    }

    const errorMessage = nested
        ? parseObjectPath(name, errors)?.message
        : errors?.[name]?.message

    return (
        <div {...validDivProps} style={{width: '100%', ...style}}>
            <Controller
                name={name}
                control={control}
                rules={rules || {required}}
                render={({field: {onChange, value}}) => (
                    <Input
                        name={name}
                        onChange={handleChange(onChange)}
                        value={updateValue(value || initialValue)}
                        fullWidth={fullWidth}
                        label={label}
                        required={required}
                        disabled={disabled}
                        type={type}
                        inputProps={inputProps}
                        InputProps={InputProps}
                        placeholder={placeholder}
                    />
                )}
            />
            {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}
        </div>
    )
}

export default FormTextInput
