import {styled} from 'common'
import TableCell from '@mui/material/TableCell'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {useNavigate} from 'react-router-dom'
import {IconButton} from '@mui/material'

const getExpandIcon = (isExpanded) =>
    isExpanded ? ExpandLessIcon : ExpandMoreIcon

const isVisible = (allowEdit, onDelete, onExpand, customActions) =>
    allowEdit || onDelete || onExpand || customActions.length

const TableActions = ({
    itemId,
    itemName,
    tableName,
    tableRowData,
    allowEdit,
    editFunction,
    allowDelete,
    onDelete,
    onExpand,
    isExpanded,
    deletePrompt,
    allowRowClick,
    customRowActions,
}) => {
    const navigate = useNavigate()

    if (!isVisible(allowEdit, onDelete, onExpand, customRowActions)) {
        return null
    }

    const ExpandIcon = getExpandIcon(isExpanded)

    const stopPropagation = (event) => {
        if (allowRowClick) {
            event.syntheticEvent?.stopPropagation()
            event.stopPropagation()
        }
    }

    const onEditClick = (event) => {
        stopPropagation(event)
        editFunction ? editFunction(itemId) : navigate(`/${itemName}/${itemId}`)
    }

    const onDeleteClick = (event) => {
        stopPropagation(event)

        if (confirmDelete() === true) {
            onDelete(itemId, tableRowData)
        }
    }

    const onExpandClick = (event) => {
        stopPropagation(event)
        onExpand(isExpanded ? null : itemId)
    }

    const confirmDelete = () =>
        deletePrompt ?? window.confirm('Are you sure you want to delete?')

    return (
        <TableCell component="th" scope="row">
            <Actions>
                {allowEdit && (
                    <IconButton
                        color="inherit"
                        size="small"
                        onClick={onEditClick}>
                        <EditIcon />
                    </IconButton>
                )}
                {allowDelete && (
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={onDeleteClick}>
                        <DeleteIcon />
                    </IconButton>
                )}
                {onExpand && (
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={onExpandClick}>
                        <ExpandIcon />
                    </IconButton>
                )}

                {customRowActions?.map((action, i) => {
                    const Icon = action.icon
                    return (
                        <IconButton
                            key={action.key || i}
                            size="small"
                            color="inherit"
                            onClick={() => action.onClick(itemId)}>
                            <Icon />
                        </IconButton>
                    )
                })}
            </Actions>
        </TableCell>
    )
}

export default TableActions

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    width: 100px;
    justify-content: space-evenly;
`
