import {
    useCreateUserMutation,
    useSaveUserMutation,
    useGetUserQuery,
    useI18n,
} from 'store/Store'
import {useEffect, useMemo, useState} from 'react'
import Dialog from 'components/Display/DialogForm/Dialog'
import {DialogActions, DialogContent, styled} from '@mui/material'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {elderSchema} from 'services/utils/validations/validationSchemas'
import CreateElder from '../CreateElder'
import DialogButtons from '../Tickets/NewTicket/NewTicketActions'
import moment from 'moment'
import {
    formatPhone,
    setValueOptions,
    formatAddressFloor,
} from 'services/utils/formUtils'
import {phoneAlreadyExistsError} from 'services/utils/validations/validationUtils'

const defaultValues = {
    type: 'elder',
}

const ElderFormDialog = ({elderId, handleClose}) => {
    const {i18n} = useI18n()
    const [errorMessage, setErrorMessage] = useState(null)
    const isNew = !elderId || elderId === 'new'

    const {data: existingElder, isLoading} = useGetUserQuery(
        {userId: elderId},
        {skip: isNew}
    )

    const [doCreateUser, {error: createError, data: createResponse}] =
        useCreateUserMutation()

    const [doUpdateUser, {error: updateError, data: updateResponse}] =
        useSaveUserMutation()

    const methods = useForm({
        defaultValues,
        mode: 'all',
        resolver: yupResolver(elderSchema),
    })

    const {handleSubmit, reset, setError, setValue} = methods
    // const {watch} = methods
    // console.log('watch', watch())

    const elder = useMemo(() => {
        if (elderId && existingElder) {
            return existingElder
        }
        return {}
    }, [elderId, existingElder])

    useEffect(() => {
        if (elder && elderId && elderId !== 'new') {
            const values = {
                ...elder,
                birthDate: elder.birthDate && moment(elder.birthDate),
                phone: formatPhone(elder.phone),
                additionalPhone: formatPhone(elder.additionalPhone),
            }
            reset(values)
        } else {
            reset(defaultValues)
        }
    }, [elder, elderId, reset])

    const onCreate = () => {
        submit()
    }

    useEffect(() => {
        if (createResponse || updateResponse) {
            handleClose()
        }
    }, [createResponse, updateResponse, handleClose])

    useEffect(() => {
        const error = createError?.data?.error || updateError?.data?.error
        if (error === 'user already exists') {
            setError('phone', phoneAlreadyExistsError, {shouldFocus: true})
        } else {
            setErrorMessage(error)
        }
    }, [createError, updateError, setError])

    const onClose = () => {
        setErrorMessage(null)
        reset(defaultValues)
        handleClose()
    }

    const onSubmit = async (submittedData) => {
        // workaround to avoid server error, but there is also a validator
        submittedData.address = formatAddressFloor(submittedData.address)

        setErrorMessage(null)
        if (elderId) {
            await doUpdateUser(submittedData)
        } else {
            await doCreateUser(submittedData)
        }
    }

    const submit = handleSubmit(onSubmit)

    const okButtonText = i18n.t('form.saveAndContinue')
    const cancelButtonText = i18n.t('form.cancel')

    const setAddressValue = (address) => {
        setValue('address', {...address}, setValueOptions)
    }

    return (
        <Dialog
            onClose={onClose}
            open={(!!elderId && !isLoading) || elderId === 'new'}
            title={i18n.t('ticketForm.formTitle')}>
            <DialogContent>
                <FormProvider {...methods}>
                    <CreateElder
                        errorMessage={errorMessage}
                        setAddressValue={setAddressValue}
                    />
                </FormProvider>
            </DialogContent>
            <ButtonWrapper>
                <DialogButtons
                    okButtonText={okButtonText}
                    cancelButtonText={cancelButtonText}
                    onOk={onCreate}
                    onCancel={onClose}
                />
            </ButtonWrapper>
        </Dialog>
    )
}

export default ElderFormDialog

const ButtonWrapper = styled(DialogActions)`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    padding: 0 18px 18px 18px;
`
