import {styled} from '@mui/material'
import {FormErrorText} from 'components/Control/Form'
import {fieldsComponents, formFields} from 'constants/forms'
import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {useEffect, useState} from 'react'
import ErrorDialog from 'components/Display/ErrorDialog'

const CreateElder = ({key, errorMessage, setAddressValue}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const [showError, setShowError] = useState(false)
    useEffect(() => {
        setShowError(errorMessage)
    }, [errorMessage])

    const {
        control: formControl,
        formState: {errors: formErrors},
    } = formContext

    return (
        <Container>
            {formFields.elders.map((field) => {
                const Component = fieldsComponents[field.fieldType]
                const key =
                    field.fieldType === 'multiple' ||
                    field.fieldType === 'address'
                        ? field.mainInputName
                        : field.key

                return (
                    <Component
                        key={key}
                        itemName="users"
                        name={key}
                        mainInputName={field.mainInputName}
                        label={i18n.t(`users.${field.key}`)}
                        control={formControl}
                        required={field.required}
                        errors={formErrors}
                        options={field.options}
                        setAddressValue={setAddressValue}
                        withSubInputs
                        subInputs={field.subInputs}
                        subInputsSizing={field.subInputsSizing}
                    />
                )
            })}

            {errorMessage && (
                <>
                    <ErrorDialog
                        open={showError}
                        text={errorMessage}
                        handleClose={() => setShowError(false)}
                    />
                    <FormErrorText>{errorMessage}</FormErrorText>
                </>
            )}
        </Container>
    )
}

export default CreateElder

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
