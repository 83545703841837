import {DialogActions, styled} from '@mui/material'
import {Texts} from 'appearance'
import Dialog from './DialogForm/Dialog'
import {useI18n} from 'store/Store'
import Button from 'components/Control/Button'

const ErrorDialog = ({open, handleClose, text, buttonOptions}) => {
    const {i18n} = useI18n()
    return (
        <Dialog
            titlePadding="12px 12px 0 12px"
            open={open}
            onClose={handleClose}>
            <Container>
                <Content>
                    <Title>{text}</Title>
                </Content>

                <ButtonWrapper>
                    <OkButton
                        name="submit"
                        variant="contained"
                        color={buttonOptions?.color}
                        endIcon={buttonOptions?.icon}
                        onClick={handleClose}>
                        {buttonOptions?.text ?? i18n.t('form.goBack')}
                    </OkButton>
                </ButtonWrapper>
            </Container>
        </Dialog>
    )
}

export default ErrorDialog

const Container = styled('div')``

const Title = styled(Texts.BoldText)`
    padding-inline: 12px;
    font-size: 20px;
`

const ButtonWrapper = styled(DialogActions)`
    display: flex;
    justify-content: center;
    margin-top: 8px;
    padding: 12px;
`
const OkButton = styled(Button)``

const Content = styled('div')`
    padding: 12px 24px;
`
