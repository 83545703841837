import {CenteredFormErrorText} from 'components/Control/Form'

const TicketFormError = ({
    errors,
    fieldName,
    shouldShowError = false,
    fallbackError = 'שדה חובה',
}) => {
    if (shouldShowError === false || (shouldShowError && !errors[fieldName])) {
        return
    }

    return (
        <CenteredFormErrorText>
            {errors?.[fieldName]?.message ?? fallbackError}
        </CenteredFormErrorText>
    )
}

export default TicketFormError
