import {styled, Typography} from '@mui/material'
import Colors from 'appearance/colors'
import 'assets/css/fonts.css'

const RegularText = styled(({as, ...props}) => (
    <Typography component="span" {...props} />
))`
    font-family: 'Open Sans', sans-serif;
    word-break: break-word;
`

const MediumText = styled(Typography)`
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
`

const BoldText = styled(Typography)`
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
`

const Title = styled(BoldText)`
    color: ${Colors.PRIMARY_TEXT};
    font-size: 24px;
`

const Texts = {RegularText, MediumText, BoldText, Title}
export default Texts
