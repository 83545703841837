import TicketDetails from 'components/Page/Dashboard/Ticket/TicketDetails'
import TicketRoute from 'components/Page/Dashboard/Ticket/TicketRoute'
import TicketSource from 'components/Page/Dashboard/Ticket/TicketSource'
import TicketSummary from 'components/Page/Dashboard/Ticket/TicketSummary'
import TicketTimes from 'components/Page/Dashboard/Ticket/TicketTimes'

export const tabs = {
    ticket: {
        keys: [
            'ticketSource',
            'ticketDetails',
            'ticketTime',
            'ticketRoute',
            'summary',
        ],
        subtitles: {ticketSource: 'address', summary: 'draft'},
        components: {
            ticketSource: TicketSource,
            ticketDetails: TicketDetails,
            ticketTime: TicketTimes,
            ticketRoute: TicketRoute,
            summary: TicketSummary,
        },
        formFields: {
            ticketSource: ['issuer', 'referral'],
            ticketDetails: [
                'category',
                'subCategory',
                'assistanceTypes',
                'locationName',
                'address',
                'workingHours',
                'paymentType',
                'volunteerNotes',
            ],
            ticketTime: ['ticketDuration', 'ticketTime'],
            ticketRoute: [
                'pickup',
                'dropoff',
                'transport',
                'routeNotes',
                'street',
                'houseNumber',
                'city',
                'floor',
                'apartmentNumber',
                'enterance',
                'pickupLocationName',
                'pickupAddress',
                'pickupWorkingHours',
            ],
        },
    },
}
