import {createApi} from '@reduxjs/toolkit/query/react'
import api from 'services/api'
import axios from 'axios'
// import storage from 'services/storage'

export const transformResponseFromData = (response) => {
    // console.log('api response', response)
    return response
}
export const transformResponseFromMessage = (response) => response.message

export const provideTags = (tagName, data, tagField) => {
    return data
        ? [
              ...data?.data?.map((item) => ({
                  type: tagName,
                  id: item[tagField || '_id'],
              })),
              {type: tagName, id: 'LIST'},
          ]
        : [{type: tagName, id: 'LIST'}]
}
export const provideTag = (tagName, data, tagField) => {
    return data
        ? [{type: tagName, id: data[tagField || '_id']}]
        : [{type: tagName, id: 'LIST'}]
}

export const sendAxiosRequest = (baseUrl, url, method, body, headers) =>
    axios({
        url: `${baseUrl || api.baseUrl}/${url}`,
        method,
        data: body,
        withCredentials: true,
        ...(headers && {headers: headers({})}),
    })

const axiosBaseQuery =
    ({baseUrl, headers} = {baseUrl: '', headers: ''}) =>
    async ({url, method, body}) => {
        // console.log('axios basic query', `${baseUrl}/${url}`, method, headers, {
        //     body,
        // })
        try {
            const result = await sendAxiosRequest(
                baseUrl,
                url,
                method,
                body,
                headers
            )

            // console.log('axios result', result)
            return {data: result.data}
        } catch (axiosError) {
            // console.log('axios error', axiosError)
            const responseError = {
                // data: err.response?.data,
                error: {
                    status: axiosError.response?.status,
                    data: axiosError.response?.data,
                },
            }

            // handle refresh
            if (
                axiosError.response?.status === 401 &&
                url !== 'auth/refreshToken' &&
                url !== 'auth/login'
            ) {
                try {
                    console.log('requesting refresh')
                    const refreshResult = await sendAxiosRequest(
                        baseUrl,
                        'auth/refreshToken',
                        method,
                        body,
                        headers
                    )
                    console.log('refresh result', refreshResult)

                    const result = await sendAxiosRequest(
                        baseUrl,
                        url,
                        method,
                        body,
                        headers
                    )
                    return {data: result.data}
                } catch (refreshError) {
                    console.log(
                        'axios error - refresh error',
                        refreshError,
                        responseError
                    )
                    return responseError
                }
            }

            console.log('axios error', responseError)
            return responseError
        }
    }

const getHeaders = () => {
    return {
        Accept: 'application/json',
    }
}

const baseApi = createApi({
    baseQuery: axiosBaseQuery({
        baseUrl: api.baseUrl,
        headers: () => getHeaders(),
    }),
    reducerPath: 'api',
    tagTypes: [
        'CurrentUser',
        'Account',
        'User',
        'Invite',
        'Ticket',
        'Organization',
    ],
    endpoints: () => ({}),
})

export const uploadFileWithIndication = (
    fileType,
    formData,
    progressUpdate
) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${api.baseUrl}/upload/` + fileType, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentUploaded = parseInt(
                        Math.round(
                            (progressEvent.loaded / progressEvent.total) * 100
                        ),
                        10
                    )
                    console.log('uploading', percentUploaded)
                    progressUpdate && progressUpdate(percentUploaded)
                },
            })
            .then((response) => {
                // response.text().then(res => {
                console.log('upload file res', response)
                resolve(response)
                // })
            })
            .catch((error) => {
                console.log('upload file error', error)
                reject(error)
            })
    })
}

export default baseApi
