import {
    TextField as RawInput,
    FormControl,
    styled,
    InputLabel,
} from '@mui/material'
import {Colors} from 'appearance'
import {paramCase} from 'change-case'

const Input = ({
    name,
    label,
    dataTestId,
    multiline,
    required,
    InputProps,
    fullWidth,
    inputProps,
    hiddenLabel = true,
    ...p
}) => {
    return (
        <div>
            {label && <Label required={required}>{label}</Label>}
            <FormControl fullWidth={fullWidth} {...p} style={{width: '100%'}}>
                <StyledInput
                    name={name}
                    fullWidth={fullWidth}
                    hiddenLabel={hiddenLabel}
                    variant="filled"
                    multiline={multiline}
                    data-test-id={`input-${paramCase(
                        label || dataTestId || 'undefined'
                    )}`}
                    InputProps={{
                        disableUnderline: true,
                        ...InputProps,
                    }}
                    inputProps={{...inputProps, 'data-testid': `${name}_field`}}
                    {...p}
                />
            </FormControl>
        </div>
    )
}

export default Input

const Label = styled(InputLabel)`
    color: ${Colors.PRIMARY_COLOR};
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 4px;
`
const StyledInput = styled(RawInput)`
    & .MuiInputBase-root input {
        padding: 0;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    & .MuiInputBase-root {
        height: 40px;
        padding-inline: 8px;
    }
`
