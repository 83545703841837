import {DialogContent} from '@mui/material'
import Button from 'components/Control/Button'
import {FormErrorText} from 'components/Control/Form'
import Dialog from 'components/Display/DialogForm/Dialog'
import FormColorPicker from 'components/Control/Form/FormColorPicker'
import {ticketStatuses} from 'constants/tickets'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSaveTicketMutation} from 'store/slices/api/ticketsApi'
import {useI18n} from 'store/Store'
import styled from 'styled-components'

const EditTicketMarkerColor = ({ticket, open, onClose}) => {
    const {i18n} = useI18n()
    const [errorMessage, setErrorMessage] = useState()
    const [doSave, {error: saveError}] = useSaveTicketMutation()

    const {
        control,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm({
        defaultValues: {
            markerColor: ticket?.markerColor,
        },
        mode: 'all',
    })

    const onSubmit = ({markerColor}) => {
        doSave({...ticket, markerColor})
        onClose()
    }

    useEffect(() => {
        if (saveError) {
            setErrorMessage(saveError?.data?.message)
        }
    }, [saveError])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={i18n.t('ticketForm.markerColor')}>
            <DialogContent>
                <FormColorPicker
                    name="markerColor"
                    control={control}
                    fullWidth
                    initialValue=""
                    errors={errors}
                    options={ticketStatuses}
                />

                {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}

                <DialogActions>
                    <Button color="secondary" onClick={onClose}>
                        {i18n.t('form.cancel')}
                    </Button>
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isValid}>
                        {i18n.t('form.saveAndContinue')}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default EditTicketMarkerColor

const DialogActions = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`
