import {Badge, IconButton, styled} from '@mui/material'
import {Colors, Texts} from 'appearance'
import {allowAddItems, allowSearchItems, ticketIcons} from 'constants/tickets'
import {useI18n} from 'store/Store'
import AddIcon from '@mui/icons-material/Add'
import SearchUser from '../../Tickets/NewTicket/SearchUser'
import {cloneElement, useState} from 'react'
import Dialog from 'components/Display/DialogForm/Dialog'

const TicketIconsSection = ({
    activeItem,
    fieldName,
    itemName,
    types,
    handleSelectItem,
    DialogForm,
}) => {
    const {i18n} = useI18n()

    const [openType, setOpenType] = useState()

    const isActive = (type) => activeItem === type
    const allowAdd = (type) =>
        itemName === 'user' && isActive(type) && allowAddItems.includes(type)
    const allowSearch = (type) => allowSearchItems.includes(type)

    const onClick = (type) => (event) => {
        event.target.blur()
        handleSelectItem(fieldName, type)
    }

    const closeDialogForm = () => {
        setOpenType()
    }

    const onClickAdd = (type) => () => {
        setOpenType(type)
    }

    const renderDialogForm = () => {
        if (!DialogForm) {
            return <></>
        }
        return cloneElement(DialogForm, {
            onClose: closeDialogForm,
        })
    }

    return (
        <Container>
            <TicketIconsContainer>
                {types.map((type) => {
                    const Icon = ticketIcons[type]
                    return (
                        <TicketIconsItem
                            key={type}
                            active={isActive(type).toString()}>
                            <AddBadge
                                overlap="circular"
                                badgeContent={
                                    <AddNewIcon onClick={onClickAdd(type)} />
                                }
                                invisible={!allowAdd(type)}>
                                <StyledIconButton
                                    data-testid={`${type}_button`}
                                    size="large"
                                    onClick={onClick(type)}>
                                    <Icon size={32} />
                                </StyledIconButton>
                            </AddBadge>
                            <TicketIconItemText>
                                {i18n.t(`${itemName}s.${type}`)}
                            </TicketIconItemText>
                        </TicketIconsItem>
                    )
                })}
            </TicketIconsContainer>
            {allowSearch(activeItem) && itemName === 'user' && (
                <SearchUser userType={activeItem} />
            )}

            <Dialog
                open={!!openType}
                title={i18n.t(`${itemName}s.dialogTitle`, {
                    type: i18n.t(`${itemName}s.${openType}`),
                })}
                onClose={closeDialogForm}>
                {renderDialogForm()}
            </Dialog>
        </Container>
    )
}

export default TicketIconsSection

const AddNewIcon = ({onClick}) => {
    return (
        <AddIconButton size="small" onClick={onClick}>
            <AddIcon style={{fontSize: 16}} />
        </AddIconButton>
    )
}

const Container = styled('div')`
    margin-block: 24px;
`
const TicketIconsContainer = styled('div')`
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-inline: auto;
`

const TicketIconsItem = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    & > span > button,
    p {
        color: ${({active}) =>
            active === 'true' ? Colors.PRIMARY_COLOR : Colors.PRIMARY_TEXT};
        text-align: center;
    }
`
const StyledIconButton = styled(IconButton)`
    color: ${(props) =>
        props.active === 'true' ? Colors.PRIMARY_COLOR : Colors.PRIMARY_TEXT};
    & [class^='d-icon-'] {
        font-size: 40px;
    }
`

const TicketIconItemText = styled(Texts.RegularText)`
    font-size: 16px;
`

const AddBadge = styled(Badge)`
    & > span {
        width: 20px;
    }
`
const AddIconButton = styled(IconButton)`
    background-color: ${Colors.BORDER};
    height: 20px;
    width: 20px;
    color: ${Colors.PRIMARY_TEXT};
`
