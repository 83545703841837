import {styled} from '@mui/material'
import {Colors, Texts} from 'appearance'

export const Container = styled('div')`
    direction: rtl;
    display: flex;
    gap: 24px;
    padding: 24px;
`

export const ColumnContainer = styled(Container)`
    flex-direction: column;
    min-width: 250px;
    gap: 4px;
`

export const Title = styled(Texts.BoldText)(({theme, disabled}) => ({
    color: disabled ? theme.palette.action.disabled : Colors.PRIMARY_TEXT,
    padding: '10px',
}))
