import {useState} from 'react'
import {tabs} from 'constants/tabs'
import {useI18n} from 'store/Store'
import {StyledTab, StyledTabs} from './styles'
import TabContent from './TabContent'
import TabLabel from './TabLabel'
import {Box, styled} from '@mui/material'
import Button from 'components/Control/Button'
import {useLocation, useNavigate} from 'react-router-dom'
import LoadingButton from 'components/Control/LoadingButton'
import {useFormContext} from 'react-hook-form'
import {validatedFormFields} from 'constants/tickets'
import {getFullName} from 'services/utils/formUtils'

const getCurrentFields = (currentTab) => validatedFormFields[currentTab] || []

const TabsContainer = ({
    itemName,
    ticket,
    submit,
    response,
    error,
    isLoading,
    filterTabs,
    ...tabProps
}) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0)
    const {i18n} = useI18n()
    const navigate = useNavigate()
    const {state: elder} = useLocation()
    const formContext = useFormContext()

    const tabsKeys = tabs[itemName].keys.filter(
        (key) => !filterTabs?.includes(key) ?? key !== filterTabs
    )

    const isTabValid = () => {
        const fields = getCurrentFields(currentTab)
        return fields?.every(
            (field) => !formContext.getFieldState(field)?.error
        )
    }

    const lastTabIndex = tabsKeys.length - 1
    const currentTab = tabsKeys[currentTabIndex]
    const isValid = isTabValid(currentTab)
    const fields = getCurrentFields(currentTab)

    const handleChange = async (event, newValue) => {
        const valid = await formContext.trigger(fields)

        const isPreviousTab = newValue < currentTabIndex
        const isNextTab = newValue > currentTabIndex

        if ((valid && isNextTab) || isPreviousTab) {
            setCurrentTabIndex(newValue)
        }
    }

    const getTabAddress = (elder) => {
        if (!elder || !elder.address) {
            return ''
        }
        const {
            address: {street = '', city = ''},
        } = elder
        return `${street} ${city}`
    }

    const getTabTitles = (key) => {
        let subtitle = ''
        const elderName = getFullName(elder ?? ticket?.elder)

        let title = i18n.t(`${itemName}s.${key}`)
        const subtitleKey = tabs[itemName]?.subtitles?.[key]
        subtitle = subtitleKey && i18n.t(`${itemName}s.${subtitleKey}`)

        if (key === 'ticketSource') {
            title = elderName
            subtitle = getTabAddress(elder ?? ticket?.elder)
        }
        return {title, subtitle}
    }

    const handleSave = () => {
        submit()
        navigate('/')
    }

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <StyledTabs value={currentTabIndex} onChange={handleChange}>
                    {tabsKeys.map((key) => {
                        const title = getTabTitles(key).title
                        const subtitle = getTabTitles(key).subtitle
                        return (
                            <StyledTab
                                key={key}
                                valid={isValid}
                                label={
                                    <TabLabel
                                        testKey={key}
                                        title={title}
                                        subtitle={subtitle}
                                    />
                                }
                            />
                        )
                    })}
                </StyledTabs>
            </Box>

            {tabsKeys.map((key, index) => {
                const Component = tabs[itemName].components[key]
                return (
                    <TabContent key={key} value={currentTabIndex} index={index}>
                        <Component {...tabProps} />
                    </TabContent>
                )
            })}

            <ButtonsContainer index={currentTabIndex}>
                {currentTabIndex !== 0 && (
                    <Button
                        name="back"
                        color="secondary"
                        onClick={(e) => handleChange(e, currentTabIndex - 1)}>
                        {i18n.t('form.back')}
                    </Button>
                )}
                {currentTabIndex !== lastTabIndex ? (
                    <LoadingButton
                        name="continue"
                        disabled={!isValid}
                        loading={isLoading}
                        onClick={(e) => handleChange(e, currentTabIndex + 1)}>
                        {i18n.t('form.continue')}
                    </LoadingButton>
                ) : (
                    <SummaryButtons>
                        <Button
                            disabled={!isValid}
                            color="primary"
                            onClick={handleSave}
                            name="saveAndClose">
                            {i18n.t('form.saveAndClose')}
                        </Button>
                    </SummaryButtons>
                )}
            </ButtonsContainer>
        </Box>
    )
}

export default TabsContainer

const ButtonsContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: ${({index}) =>
        index === 0 ? 'flex-end' : 'space-between'};
    width: 100%;
`
const SummaryButtons = styled('div')`
    display: flex;
    gap: 16px;
`
