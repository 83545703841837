import * as Sentry from '@sentry/react'
const sentryDsn = process.env.REACT_APP_SENTRY_DSN
const isSentryEnabled = !!sentryDsn

export function initSentry() {
    if (isSentryEnabled) {
        Sentry.init({
            dsn: sentryDsn,
            integrations: [
                new Sentry.BrowserTracing({
                    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    tracePropagationTargets: [
                        'localhost',
                        'localhost:3900',
                        /.*api\.2080\.org\.il\/api/,
                    ],
                }),
                new Sentry.Replay(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        })
    }
}

export function enrichUserForTracing({email, name}) {
    if (isSentryEnabled) {
        Sentry.setUser({email, username: name})
    }
}
