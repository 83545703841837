import {useCreateUserMutation, useI18n} from 'store/Store'
import SearchElder from './SearchElder'
import {useEffect, useState} from 'react'
import CreateElder from '../../CreateElder'
import Dialog from 'components/Display/DialogForm/Dialog'
import NewTicketActions from './NewTicketActions'
import {DialogActions, DialogContent, styled} from '@mui/material'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {elderSchema} from 'services/utils/validations/validationSchemas'
import {useUrlParams} from 'services/hooks'
import {useNavigate} from 'react-router-dom'
import {phoneAlreadyExistsError} from 'services/utils/validations/validationUtils'
import {setValueOptions, formatAddressFloor} from 'services/utils/formUtils'

const NewTicket = ({open, handleClose}) => {
    const {i18n} = useI18n()
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState(null)

    const [doCreateUser, {error: createError, data: createResponse}] =
        useCreateUserMutation()
    const {setUrlParam} = useUrlParams()

    const [createElder, setCreateElder] = useState(false)

    const methods = useForm({
        defaultValues: {
            type: 'elder',
        },
        mode: 'all',
        resolver: yupResolver(elderSchema),
    })

    const {handleSubmit, reset, setError, setValue} = methods

    const onCreate = () => {
        if (!createElder) {
            return
        }

        submit()
    }

    useEffect(() => {
        if (createResponse) {
            navigate(`/ticket/new`, {state: createResponse})
            handleClose()
        }
    }, [createResponse, navigate, setUrlParam, handleClose])

    useEffect(() => {
        const error = createError?.data?.error
        if (error === 'user already exists') {
            setError('phone', phoneAlreadyExistsError, {shouldFocus: true})
        } else {
            setErrorMessage(error)
        }
    }, [createError, setError])

    const handleCreateElder = () => {
        setCreateElder(true)
    }

    const onClose = () => {
        reset()
        handleClose()
        setCreateElder(false)
    }

    const onSubmit = async (submittedData) => {
        submittedData.address = formatAddressFloor(submittedData.address)
        await doCreateUser(submittedData)
    }

    const submit = handleSubmit(onSubmit)

    const okButtonText = i18n.t(
        createElder ? 'form.saveAndContinue' : 'ticketForm.createElder'
    )
    const cancelButtonText = createElder && i18n.t('form.cancel')

    const setAddressValue = (address) => {
        setValue('address', {...address}, setValueOptions)
    }

    return (
        <Dialog
            onClose={onClose}
            open={open}
            title={i18n.t('ticketForm.formTitle')}>
            <DialogContent>
                {createElder ? (
                    <FormProvider {...methods}>
                        <CreateElder
                            errorMessage={errorMessage}
                            setAddressValue={setAddressValue}
                        />
                    </FormProvider>
                ) : (
                    <SearchElder onClose={onClose} />
                )}
            </DialogContent>
            <ButtonWrapper data-testid="createElder_button">
                <NewTicketActions
                    okButtonText={okButtonText}
                    cancelButtonText={cancelButtonText}
                    onOk={createElder ? onCreate : handleCreateElder}
                    onCancel={onClose}
                />
            </ButtonWrapper>
        </Dialog>
    )
}

export default NewTicket

const ButtonWrapper = styled(DialogActions)`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    padding: 0 18px 18px 18px;
`
