import {CircularProgress} from '@mui/material'
import Button from './Button'

const LoadingButton = ({children, dataTestId, loading, ...p}) => {
    return (
        <Button
            disabled={loading}
            endIcon={loading && <CircularProgress color="inherit" size={20} />}
            {...p}>
            {children}
        </Button>
    )
}

export default LoadingButton
