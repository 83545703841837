import {Divider, styled} from '@mui/material'
import {Colors, Texts} from 'appearance'
import Button from 'components/Control/Button'
import {useNavigate} from 'react-router-dom'
import {useI18n} from 'store/Store'

const SearchResult = ({result, onClose}) => {
    const navigate = useNavigate()
    const {i18n} = useI18n()

    const getAddress = () => {
        if (!userAddress) {
            return ''
        }
        return `${userAddress.street || ''} ${userAddress.houseNumber || ''} ${
            userAddress.city || ''
        }`
    }

    const {firstName, lastName, address: userAddress, phone} = result
    const fullName = `${firstName} ${lastName}`
    const address = getAddress()
    const description = `${phone?.trim().replace('+972', '0')} | ${address}`

    const onOpenNewTicket = () => {
        onClose()
        navigate('/ticket/new', {state: result})
    }

    return (
        <>
            <Container>
                <RightSide>
                    <ResultName>{fullName}</ResultName>
                    <ResultDescription>{description} </ResultDescription>
                </RightSide>

                <CreateTicketButton
                    data-testid="createTicket_button"
                    variant="contained"
                    onClick={onOpenNewTicket}>
                    {i18n.t('tickets.createTicket')}
                </CreateTicketButton>
            </Container>
            <Divider />
        </>
    )
}

export default SearchResult

const ResultName = styled(Texts.BoldText)`
    font-size: 18px;
`

const ResultDescription = styled(Texts.RegularText)`
    font-size: 18px;
    font-weight: 300;
`

const Container = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const RightSide = styled('div')`
    display: flex;
    flex-direction: column;
`

const CreateTicketButton = styled(Button)`
    border-radius: 0;
    background: ${Colors.SECONDARY_COLOR};
    padding: 4px 12px;
    font-size: 18px;
`
