import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const transformResponseAddLink = (response) => {
    if (response.data) {
        var appUrl = 'https://app.2080.org.il/'
        if (window.location.hostname === 'localhost') {
            appUrl = 'http://localhost:3501/'
        } else if (window.location.hostname.includes('dev-admin')) {
            appUrl = 'https://dev-app.2080.org.il/'
        }
        const newResponse = {
            ...response,
            data: response.data.map((item) => ({
                ...item,
                link: `${appUrl}welcome?inviteCode=${item._id}`,
            })),
        }
        return newResponse
    }
    return response
}
const organizationsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getOrganization: build.query({
            query: (organizationId) => ({
                url: `organizations/${organizationId}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Organization', result),
        }),
        getOrganizations: build.query({
            query: (query) => {
                console.log('get organizations query', query)
                const url = constructUrl('organizations', {
                    ...query,
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseAddLink,
            providesTags: () => provideTag('Organization'),
        }),
        createOrganization: build.mutation({
            query: (body) => {
                return {
                    url: `organizations`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['Organization'],
        }),
        saveOrganization: build.mutation({
            query: (organization) => {
                console.log('save_organization mutation req', organization)
                return {
                    url: `organizations/${organization._id}`,
                    method: 'PUT',
                    body: organization,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['Organization'],
        }),
        deleteOrganization: build.mutation({
            query: (id) => {
                return {
                    url: `organizations/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Organization'),
        }),
    }),
    overrideExisting: false,
})

export default organizationsApi

export const {
    useGetOrganizationQuery,
    useGetOrganizationsQuery,
    useCreateOrganizationMutation,
    useSaveOrganizationMutation,
    useDeleteOrganizationMutation,
} = organizationsApi
