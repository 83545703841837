import storage from 'services/storage'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useSignupMutation, useSignupWithInviteMutation} from 'store/Store'

const useRegister = (token) => {
    const [doSignup, {error: signupError, data: signupResponse, isLoading}] =
        useSignupMutation()
    const [doSignupWithInvite] = useSignupWithInviteMutation()

    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        setErrorMessage(signupError?.data?.message?.message)
    }, [signupError])

    const {
        handleSubmit,
        control: formControl,
        formState: {errors: formErrors},
        getValues: getFormValues,
        setValue: setFormValue,
    } = useForm()

    const onSubmit = async (submittedData) => {
        console.log('submitted data', submittedData)
        const {password, confirmPassword, rememberMe} = submittedData
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match')
            return
        }
        setErrorMessage(null)
        storage.setRememberMe(rememberMe || false)
        if (token) {
            await doSignupWithInvite({submittedData, token})
        } else {
            await doSignup(submittedData)
        }
    }

    const submit = handleSubmit(onSubmit)

    return {
        formControl,
        submit,
        isLoading,
        signupError,
        errorMessage,
        formErrors,
        getFormValues,
        setFormValue,
        signupResponse,
    }
}

export default useRegister
