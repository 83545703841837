import {Controller} from 'react-hook-form'
import {FormErrorText} from '.'
import {parseObjectPath} from 'services/utils/formUtils'
import {CompactPicker} from 'react-color'

const FormColorPicker = ({
    name,
    control,
    rules,
    required,
    label,
    errors,
    nested,
    setTime,
    valueFormat,
    ...p
}) => {
    const errorMessage = nested
        ? parseObjectPath(name, errors)?.message
        : errors?.[name]?.message

    return (
        <div style={{width: '100%'}}>
            <Controller
                name={name}
                control={control}
                rules={rules || {required}}
                render={({field: {onChange, value}}) => {
                    return (
                        <CompactPicker
                            onChange={(color) => {
                                onChange(color.hex)
                            }}
                            color={value}
                            label={label}
                            {...p}
                        />
                    )
                }}
            />
            {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}
        </div>
    )
}

export default FormColorPicker
