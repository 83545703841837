import {styled} from 'common'
import {Controller} from 'react-hook-form'
import Checkbox from '../Checkbox'

const FormCheckbox = ({name, label, control}) => {
    label = label || name
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}}) => (
                <StyledCheckbox
                    onChange={onChange}
                    value={value}
                    checked={value}
                    label={label}
                    defaultChecked={value}
                />
            )}
        />
    )
}

export default FormCheckbox

const StyledCheckbox = styled(Checkbox)`
    width: 100%;
`
