import {React} from 'common'
import {Page, PageContent} from 'components/Page'
import {validationPatterns} from 'constants/auth'

import {
    FormActionButton,
    FormContainer,
    FormTitle,
} from 'components/Control/Form'
import styled from 'styled-components'
import useLogin from 'logic/Auth/useLogin'
import {CircularProgress} from '@mui/material'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {useI18n} from 'store/Store'
import {useEffect, useState} from 'react'
import ResendCode from 'components/Control/ResendCode'
import {prevenNonNumeric} from 'services/utils/formUtils'
import Button from 'components/Control/Button'
import {Texts} from 'appearance'

const loginButtonText = (source, i18n) =>
    source ? i18n.t('auth.login') : i18n.t('auth.sendCode')

// eslint-disable-next-line complexity
const Login = () => {
    const {i18n} = useI18n()
    const [source, setSource] = useState(null)

    const {
        formControl,
        verifyFormControl,
        submitPhoneLogin,
        submitVerify,
        isLoading,
        error,
        formErrors,
        verifyFormErrors,
        response,
        setVerifyFormValue,
        submissionTime,
    } = useLogin(source)

    useEffect(() => {
        if (response) {
            setSource(response.source)
            setVerifyFormValue('id', response.id)
        }
    }, [response, setVerifyFormValue])

    if (source && source !== 'login') {
        return (
            <NoAccess>
                {i18n.t('auth.noAccess')}
                <Button variant="text" onClick={() => window.location.reload()}>
                    {i18n.t('form.back')}
                </Button>
            </NoAccess>
        )
    }

    return (
        <Page noHeader noDrawer>
            <PageContent centered>
                <FormContainer style={{width: 400}}>
                    <FormTitle>{`${i18n.t('auth.welcome')}${
                        window.appName
                    }`}</FormTitle>
                    {!source ? (
                        <FormTextInput
                            key="phone"
                            name="phone"
                            label={i18n.t('auth.phone')}
                            control={formControl}
                            rules={{
                                required: true,
                                pattern: validationPatterns.PHONE,
                            }}
                            fullWidth
                            type="tel"
                            initialValue=""
                            errors={formErrors}
                        />
                    ) : (
                        source === 'login' && (
                            <FormTextInput
                                key="code"
                                name="code"
                                label={i18n.t('auth.code')}
                                control={verifyFormControl}
                                rules={{
                                    required: true,
                                }}
                                fullWidth
                                onKeyPress={prevenNonNumeric}
                                errors={{
                                    code: {
                                        message: error,
                                    },
                                    ...verifyFormErrors,
                                }}
                            />
                        )
                    )}
                </FormContainer>

                <StyledFormActionButton
                    name="sendCode"
                    onClick={!source ? submitPhoneLogin : submitVerify}
                    disabled={isLoading}
                    variant="contained">
                    {isLoading && (
                        <LoadingCircularProgress color="white" size={16} />
                    )}
                    {loginButtonText(source, i18n)}
                </StyledFormActionButton>

                {source && (
                    <ResendCode
                        submissionTime={submissionTime}
                        submitPhoneLogin={submitPhoneLogin}
                    />
                )}
            </PageContent>
        </Page>
    )
}

export default Login

const LoadingCircularProgress = styled(CircularProgress)`
    margin-inline-end: 10px;
`

const StyledFormActionButton = styled(FormActionButton)`
    width: 400px;
`
const NoAccess = styled(Texts.RegularText)`
    direction: rtl;
    width: 100%;
    padding-top: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
`
