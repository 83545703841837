import {
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    styled,
} from '@mui/material'
import {Controller} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {FormErrorText} from '.'

const FormRadioGroup = ({
    name,
    label,
    control,
    errors,
    options,
    required,
    itemName,
}) => {
    const {i18n} = useI18n()
    label = label || name

    return (
        <Container>
            <FormLabel focused required={required}>
                {label}
            </FormLabel>
            <Controller
                name={name}
                control={control}
                render={({field}) => (
                    <StyledRadioGroup {...field} row>
                        {options.map((option) => (
                            <StyledFormControlLabel
                                checked={field.value === option}
                                value={option}
                                control={
                                    <StyledRadio
                                        inputProps={{
                                            'data-testid': `${name}_${option.key}_radio`,
                                        }}
                                    />
                                }
                                label={i18n.t(`${itemName}.${name}s.${option}`)}
                            />
                        ))}
                    </StyledRadioGroup>
                )}
            />
            {errors?.[name] && (
                <FormErrorText>{errors[name].message}</FormErrorText>
            )}
        </Container>
    )
}

export default FormRadioGroup

const Container = styled(FormControl)`
    margin-top: 12px;
`
const StyledRadioGroup = styled(RadioGroup)`
    margin-top: 8px;
    display: flex;
    gap: 8px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
    margin: 0;
    padding-inline-end: 8px;
`

const StyledRadio = styled(Radio)`
    padding: 0;
    padding-inline-end: 4px;
`
