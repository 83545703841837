import {styled} from '@mui/material'
import {dashboardStatuses, ticketStatuses} from 'constants/tickets'
import DashboardStatusItem from './DashboardStatusItem'

const DashboardStatus = ({statusesCount, activeStatus, onClick}) => {
    return (
        <Container>
            {dashboardStatuses.map((status, index) => (
                <DashboardStatusItem
                    key={status}
                    name={status}
                    status={status}
                    active={(activeStatus === ticketStatuses[index]).toString()}
                    count={statusesCount?.[status] || 0}
                    onClick={onClick}
                />
            ))}
        </Container>
    )
}

export default DashboardStatus

const Container = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-block: 48px;
    padding: 36px;
`
