import {React} from 'common'
import {useNavigate} from 'react-router-dom'
import {Page, PageContent} from 'components/Page'
import {useForgotPasswordMutation} from 'store/Store'
import {Controller, useForm} from 'react-hook-form'
import {validationPatterns} from 'constants/auth'
import Button from 'components/Control/Button'
import {
    FormActionButton,
    FormContainer,
    FormTitle,
    FormInput,
    FormRow,
    FormAdditionalButton,
    FormErrorMessage,
    FormAdditionalText,
} from 'components/Control/Form'

const ForgotPassword = () => {
    const navigate = useNavigate()

    const [doForgotPassword, {data: forgotPasswordResponse}] =
        useForgotPasswordMutation()

    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm()

    const onSubmit = (submittedData) => {
        console.log('submit data', submittedData)
        doForgotPassword(submittedData)
    }

    if (forgotPasswordResponse?.message) {
        return (
            <Page noHeader noDrawer>
                <PageContent>{forgotPasswordResponse.message}</PageContent>
                <Button onClick={() => navigate('/Login')} variant="contained">
                    Continue
                </Button>
            </Page>
        )
    }

    return (
        <Page noHeader noDrawer>
            <PageContent centered>
                {/* <Controller style={{width: 400}}> */}
                <FormContainer style={{width: 400}}>
                    <FormTitle>Password Reset</FormTitle>
                    <Controller
                        name={'email'}
                        control={control}
                        rules={{
                            required: true,
                            pattern: validationPatterns.EMAIL,
                        }}
                        render={({field: {onChange, value}}) => (
                            <FormInput
                                onChange={onChange}
                                value={value}
                                fullWidth
                                label="Email"
                                type="email"
                            />
                        )}
                    />
                    <FormErrorMessage
                        errors={errors}
                        name={'email'}
                        errorMessage={'valid email is required.'}
                    />
                </FormContainer>

                <FormActionButton
                    onClick={handleSubmit(onSubmit)}
                    variant="contained">
                    Send Password Reset Email
                </FormActionButton>
                <FormRow>
                    <FormAdditionalText>Have an Account?</FormAdditionalText>
                    <FormAdditionalButton onClick={() => navigate('/login')}>
                        Login
                    </FormAdditionalButton>
                </FormRow>
                {/* </Controller> */}
            </PageContent>
        </Page>
    )
}

export default ForgotPassword
