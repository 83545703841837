import {styled} from 'common'

const KeyValue = ({object}) => {
    return Object.keys(object).map((key) => (
        <Container>
            <Key>{`${key}: `}</Key>
            <Value>
                {typeof object[key] === 'object'
                    ? JSON.stringify(object[key])
                    : object[key]}
            </Value>
        </Container>
    ))
}

export default KeyValue

const Container = styled.div`
    display: flex;
    flex-direction: row;
`

const Key = styled.span`
    font-weight: bold;
`

const Value = styled.span`
    margin-left: 20px;
    font-size: 14px;
`
