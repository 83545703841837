import {DialogActions, styled} from '@mui/material'
import Dialog from './DialogForm/Dialog'
import {Texts} from 'appearance'
import Button from 'components/Control/Button'
import {useI18n} from 'store/Store'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault'
import EditIcon from '@mui/icons-material/Edit'

const ConfirmationDialog = ({
    open,
    handleClose,
    text,
    onOk,
    okButtonOptions,
}) => {
    const {i18n} = useI18n()
    return (
        <Dialog
            titlePadding="12px 12px 0 12px"
            open={open}
            onClose={handleClose}>
            <Container>
                <Title>{text}</Title>

                <ButtonWrapper>
                    <CancelButton
                        endIcon={<DisabledByDefaultIcon />}
                        name="cancel"
                        onClick={handleClose}
                        color="secondary">
                        {i18n.t('form.cancel')}
                    </CancelButton>
                    <OkButton
                        name="submit"
                        color={okButtonOptions?.color}
                        endIcon={okButtonOptions?.icon ?? <EditIcon />}
                        onClick={onOk}>
                        {okButtonOptions?.text ?? i18n.t('form.update')}
                    </OkButton>
                </ButtonWrapper>
            </Container>
        </Dialog>
    )
}

export default ConfirmationDialog

const Container = styled('div')``

const Title = styled(Texts.BoldText)`
    padding-inline: 12px;
    font-size: 20px;
`

const ButtonWrapper = styled(DialogActions)`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    padding: 12px;
`
const OkButton = styled(Button)``
const CancelButton = styled(Button)``
