import RawCard from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {styled} from '@mui/system'

const Card = ({children, ...p}) => {
    return (
        <StyledCard {...p}>
            <StyledCardContent>{children}</StyledCardContent>
        </StyledCard>
    )
}

export default Card

const StyledCard = styled(RawCard)``

const StyledCardContent = styled(CardContent)`
    padding: 8px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`
