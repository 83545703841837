import {React, styled} from 'common'
import Table from 'components/Display/Table'
import {Page, PageContent} from 'components/Page'
import {userTableTypes, optionEmptyOrganization} from 'constants/users'
import Dialog from 'components/Display/DialogForm/Dialog'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {
    useDeleteUserMutation,
    useGetAccountsQuery,
    useGetCurrentUserQuery,
    useGetUserQuery,
    useGetUsersQuery,
    useI18n,
    useGetOrganizationsQuery,
} from 'store/Store'
import User from './User'
import {FormProvider, useForm} from 'react-hook-form'
import {formatPhone, setValueOptions} from 'services/utils/formUtils'
import {yupResolver} from '@hookform/resolvers/yup'
import {userSchemas} from 'services/utils/validations/validationSchemas'
import ConfirmationDialog from 'components/Display/ConfirmationDialog'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import ErrorDialog from 'components/Display/ErrorDialog'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {isNew} from 'services/utils/editForm'
import {hasPermissions, isAdmin} from 'services/utils/misc'
import {FileInfo} from '@styled-icons/remix-fill/FileInfo'
import {Whatsapp} from '@styled-icons/boxicons-logos/Whatsapp'
import {useNavigate} from 'react-router-dom'

const getUserDefaultValues = (type) => {
    return type === 'admin'
        ? {role: 'ADMIN', type: 'specialist'}
        : {type, role: 'USER'}
}

const getUsersQuery = (type) =>
    type === 'admin'
        ? {'userAccounts.role': ['OWNER', 'ADMIN']}
        : {
              type,
              'userAccounts.role': 'USER',
          }

const getUserNameToDelete = (user) => user?.firstName || 'המשתמש'

const getUserAccounts = (user, type) => {
    if (type !== 'volunteer') {
        return
    }
    return user?.userAccounts.map((userAccount) => ({...userAccount.account}))
}

const Users = () => {
    const {i18n} = useI18n()
    const [selectedType, setSelectedType] = useState('volunteer')
    const navigate = useNavigate()
    const [userId, setUserId] = useState()
    const [deleteUser, setDeleteUser] = useState()
    const [cannotDeleteUser, setCannotDeleteUser] = useState()
    const {data: loggedInUser} = useGetCurrentUserQuery()
    const defaultValues = useMemo(() => {
        return getUserDefaultValues(selectedType)
    }, [selectedType])

    const id = userId ?? deleteUser
    const {data: user} = useGetUserQuery({userId: id}, {skip: isNew(id)})
    const onHistoryClick = () => (id) => {
        window.open(`/ticketHistory?volunteers=${id}`)
    }
    const {data: accounts} = useGetAccountsQuery()
    const {data: organizations} = useGetOrganizationsQuery()

    const [doDeleteUser] = useDeleteUserMutation()

    const clearUserId = useCallback(() => {
        setUserId(null)
    }, [])

    const methods = useForm({
        defaultValues: {
            ...user,
            phone: formatPhone(user?.phone),
            additionalPhone: formatPhone(user?.additionalPhone),
            accounts: getUserAccounts(user, selectedType),
            ...defaultValues,
        },
        mode: 'all',
        resolver: yupResolver(userSchemas[selectedType]),
    })

    const {reset, setValue} = methods

    useEffect(() => {
        if (user && userId && userId !== 'new') {
            reset({
                ...user,
                phone: formatPhone(user?.phone),
                additionalPhone: formatPhone(user?.additionalPhone),
                accounts: getUserAccounts(user, selectedType),
                organization:
                    organizations?.data.find(
                        (org) => org._id === user?.organization?._id
                    ) || optionEmptyOrganization,
                ...defaultValues,
            })
        } else {
            reset(defaultValues)
        }
    }, [user, reset, userId, selectedType, defaultValues, organizations])

    const setAddressValue = (address) => {
        setValue('address', {...address}, setValueOptions)
    }

    const onDeleteUser = (userId) => {
        setDeleteUser(userId)
    }

    const handleDeleteUser = async () => {
        try {
            const deleteResponse = await doDeleteUser(deleteUser)
            setDeleteUser(null)
            clearUserId()

            if (
                deleteResponse?.error?.data?.error ===
                'cannot delete user with existing tickets'
            ) {
                setCannotDeleteUser(deleteUser)
                return
            }
        } catch (error) {
            console.log('delete user error', {error})
        }
    }

    const getButtonsOptiosn = () =>
        isAdmin(loggedInUser)
            ? userTableTypes
            : userTableTypes.filter(
                  (type) => !(type === 'admin' || type === 'specialist')
              )

    const setVolunteerRowColor = (volunteer) =>
        volunteer?.status === 'pending_support' && '#F08080'

    return (
        <Page menuTitle={`users`}>
            <Content>
                <Table
                    itemName={`users_${selectedType}`}
                    itemType="user"
                    selectedToggleButton={selectedType}
                    setSelectedToggleButton={setSelectedType}
                    titleTextAlign="center"
                    allowAdd={hasPermissions(selectedType, loggedInUser)}
                    allowEdit={hasPermissions(selectedType, loggedInUser)}
                    allowDelete={isAdmin(loggedInUser)}
                    allowDownload
                    addFunction={() => setUserId('new')}
                    editFunction={setUserId}
                    deleteFunction={onDeleteUser}
                    deletePrompt={true}
                    paginationType="external"
                    useGetQuery={useGetUsersQuery}
                    useDeleteMutation={useDeleteUserMutation}
                    buttonsGroupOptions={getButtonsOptiosn()}
                    query={getUsersQuery(selectedType)}
                    setRowColor={setVolunteerRowColor}
                    customRowActions={[
                        {
                            key: 'navigate',
                            icon: () => <FileInfo size={24} />,
                            onClick: onHistoryClick(),
                        },
                        {
                            key: 'whatsapp',
                            icon: () => <Whatsapp size={24} />,
                            onClick: (id) => navigate(`/user/${id}/chats`),
                        },
                    ]}
                />

                <FormProvider {...methods}>
                    <Dialog
                        onClose={clearUserId}
                        open={(!!userId && !!user) || userId === 'new'}
                        title={i18n.t(
                            `${
                                isNew(userId)
                                    ? 'users.dialogTitle'
                                    : 'users.dialogTitleEdit'
                            }`,
                            {
                                type: i18n.t(`users.${selectedType}`),
                            }
                        )}>
                        <User
                            userId={userId}
                            type={selectedType}
                            setAddressValue={setAddressValue}
                            handleClose={clearUserId}
                            onDeleteUser={onDeleteUser}
                            accounts={accounts?.data}
                            organizations={organizations?.data}
                        />
                    </Dialog>
                </FormProvider>

                <ConfirmationDialog
                    open={!!deleteUser && user}
                    text={i18n.t(`users.deleteConfirmationText`, {
                        name: getUserNameToDelete(user),
                    })}
                    onOk={handleDeleteUser}
                    okButtonOptions={{
                        text: i18n.t('form.delete'),
                        icon: <DoNotDisturbIcon />,
                        color: 'error',
                    }}
                    handleClose={() => setDeleteUser(null)}
                />

                <ErrorDialog
                    open={!!cannotDeleteUser}
                    text={i18n.t(`users.cannotDeleteError.${selectedType}`, {
                        name: getUserNameToDelete(user),
                    })}
                    handleClose={() => setCannotDeleteUser(null)}
                    buttonOptions={{
                        icon: <KeyboardArrowRightIcon />,
                        color: 'secondary',
                    }}
                />
            </Content>
        </Page>
    )
}

export default Users
const Content = styled(PageContent)``
