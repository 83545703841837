import {useI18n} from 'store/Store'
import {
    ColumnContainer,
    Title,
} from '../../../../Display/Table/TableTitleAndFilters/filtersStyles'
import {useUrlParams} from 'services/hooks'
import Checkbox from 'components/Control/Checkbox'
import {statuses} from 'constants/users'
import {isChecked, toggleFilters} from '../../Tickets/Filters/utils'

const VolunteerStatus = () => {
    const {i18n} = useI18n()
    const {urlParams, setUrlParam} = useUrlParams()

    const onChange = (fieldName) => (event) => {
        updateFiltersParams(fieldName, event.target.name)
    }

    const updateFiltersParams = (fieldName, value) => {
        const filters = urlParams[fieldName]?.split(',') || []
        const newFilters = toggleFilters(filters, value)
        setUrlParam(fieldName, newFilters)
    }

    return (
        <ColumnContainer>
            <Title>{i18n.t('users.status')}</Title>
            {statuses.map((status) => (
                <Checkbox
                    name={status}
                    checked={isChecked(urlParams, 'status', status)}
                    onChange={onChange('status')}
                    value={status}
                    label={i18n.t(`users.statuss.${status}`)}
                />
            ))}
        </ColumnContainer>
    )
}

export default VolunteerStatus
