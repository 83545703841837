import {useGetUsersQuery, useI18n} from 'store/Store'
import {CircularProgress, InputAdornment, styled} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SearchResults from './SearchResults'
import {Texts} from 'appearance'
import Input from 'components/Control/Input'
import useDebounce from 'logic/Forms/useDebounce'
import {useState} from 'react'

const SearchElder = ({onClose}) => {
    const {i18n} = useI18n()

    const [inputValue, setInputValue] = useState()
    const debouncedValue = useDebounce(inputValue, 500)

    const {data: users, isFetching} = useGetUsersQuery({
        search: debouncedValue,
        type: 'elder',
        pageSize: 10,
    })

    const handleChange = (event) => {
        setInputValue(event.target.value)
    }

    return (
        <Container>
            <DescriptionText>
                {i18n.t('tickets.create_ticket_description')}
            </DescriptionText>

            <SearchInput
                name="searchElder"
                hiddenLabel
                onChange={handleChange}
                value={inputValue}
                variant="filled"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: isFetching && (
                        <InputAdornment position="start">
                            <CircularProgress size={20} />
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                }}
            />

            {debouncedValue && !isFetching && (
                <SearchResults results={users?.data} onClose={onClose} />
            )}
        </Container>
    )
}

export default SearchElder

const Container = styled('div')`
    display: flex;
    flex-direction: column;
`

const DescriptionText = styled(Texts.RegularText)`
    font-size: 18px;
    margin-bottom: 24px;
`
const SearchInput = styled(Input)`
    border-bottom: none;
`
