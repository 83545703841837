import {Slider, styled} from '@mui/material'
import {
    ColumnContainer,
    Title,
} from 'components/Display/Table/TableTitleAndFilters/filtersStyles'
import {useUrlParams} from 'services/hooks'
import {dateToYears, yearsToBirthDate} from 'services/utils/tableFilters'
import {useI18n} from 'store/Store'
import {ThemeProvider} from '@mui/material/styles'
import {ltrTheme} from 'theme'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm} from 'react-hook-form'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {rangeFilterSchema} from 'services/utils/validations/validationSchemas'
import {prevenNonNumeric} from 'services/utils/formUtils'

const AgeRange = () => {
    const {i18n} = useI18n()
    const {urlParams, setUrlParam} = useUrlParams()

    const getInputValue = (paramName) => {
        const position = paramName === 'birthDate[$gte]' ? 'start' : 'end'
        return dateToYears(urlParams[paramName], position)
    }

    const {
        control,
        formState: {errors: formErrors},
        setValue,
    } = useForm({
        defaultValues: {
            lt: getInputValue('birthDate[$lt]'),
            gte: getInputValue('birthDate[$gte]'),
        },
        mode: 'all',
        resolver: yupResolver(rangeFilterSchema),
    })

    const handleChange = (event, newValue, activeThumb) => {
        const [value, fieldName] =
            activeThumb === 0 ? [newValue[0], 'lt'] : [newValue[1], 'gte']

        const date = yearsToBirthDate(value)
        setValue(fieldName, value)
        setUrlParam(`birthDate[$${fieldName}]`, date)
    }

    const onInputChange = (event) => {
        const {value, name} = event.target
        const fieldName = `birthDate[$${name}]`

        if (value === '0') {
            setUrlParam('birthDate[$exists]', false)
            setUrlParam(fieldName, undefined)
            return
        }
        if (value === '') {
            setUrlParam(fieldName, undefined)
            setUrlParam('birthDate[$exists]', undefined)
            return
        }

        const position = name === 'gte' ? 'start' : 'end'
        const birthDate = yearsToBirthDate(value, position)
        setUrlParam(fieldName, birthDate)
    }

    return (
        <ColumnContainer>
            <TitleWrapper>
                <Title>{i18n.t('elders.filters.ageRange')}</Title>
            </TitleWrapper>
            <InputsContainer>
                <FormTextInput
                    name="lt"
                    control={control}
                    errors={formErrors}
                    onChange={onInputChange}
                    initialValue={getInputValue('birthDate[$lt]')}
                    value={getInputValue('birthDate[$lt]')}
                    label={i18n.t('elders.filters.range_from')}
                    InputProps={{
                        style: {height: 35, width: 100},
                        'data-testid': `ageRange-from_field`,
                    }}
                    style={{height: 100}}
                    type="number"
                    onKeyPress={prevenNonNumeric}
                />
                <FormTextInput
                    name="gte"
                    control={control}
                    errors={formErrors}
                    onChange={onInputChange}
                    initialValue={getInputValue('birthDate[$gte]')}
                    value={getInputValue('birthDate[$gte]')}
                    label={i18n.t('elders.filters.range_to')}
                    InputProps={{
                        style: {height: 35, width: 100},
                        'data-testid': `ageRange-to_field`,
                    }}
                    style={{height: 100}}
                    type="number"
                    onKeyPress={prevenNonNumeric}
                />
            </InputsContainer>
            <ThemeProvider theme={ltrTheme}>
                <StyledSlider
                    name="age-range-filter"
                    value={[
                        getInputValue('birthDate[$lt]'),
                        getInputValue('birthDate[$gte]'),
                    ]}
                    onChange={handleChange}
                    valueLabelDisplay="on"
                    disableSwap
                    step={1}
                    max={120}
                />
            </ThemeProvider>
        </ColumnContainer>
    )
}

export default AgeRange

const StyledSlider = styled(Slider)`
    margin-top: 36px;
    & .MuiSlider-valueLabel {
        font-size: 12px;
        font-weight: bold;
        top: -6px;
        background-color: unset;
        color: black;
        &:before {
            display: none;
        }
        & * {
            background: transparent;
            color: #000;
        }
    }
`

const InputsContainer = styled('div')`
    display: flex;
    gap: 36px;
    justify-content: space-between;
    align-items: center;
`

const TitleWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
`
