import {styled} from '@mui/material'
import {Texts} from 'appearance'
import {allowSearchItems, referralTypes} from 'constants/tickets'
import {userTypes} from 'constants/users'
import {useGetCurrentUserQuery, useI18n} from 'store/Store'
import TicketIconsSection from './components/TicketIconsItem'
import TicketTabSection from '../Tickets/TicketTabSection'
import {useFormContext} from 'react-hook-form'
import AddUserForm from './components/AddUserForm'
import OtherTicketSource from '../Tickets/NewTicket/OtherTicketSource'
import {useLocation, useParams} from 'react-router-dom'
import {
    getFullName,
    getUserType,
    setValueOptions,
} from 'services/utils/formUtils'
import {useGetTicketQuery} from 'store/slices/api/ticketsApi'
import {useCallback, useEffect} from 'react'
import {CenteredFormErrorText} from 'components/Control/Form'
import {shouldSkipFetchTicket} from 'services/utils/tickets'

const TicketSource = () => {
    const {i18n} = useI18n()
    const {data: user} = useGetCurrentUserQuery()
    const {ticketId} = useParams()
    const {data: ticket} = useGetTicketQuery(ticketId, {
        skip: shouldSkipFetchTicket(ticketId),
    })

    const {state: elderData} = useLocation()
    const elder = elderData ?? ticket?.elder
    const formContext = useFormContext()
    const {
        watch,
        setValue,
        clearErrors,
        formState: {errors: formErrors},
    } = formContext

    const values = watch()
    const dispatcherName = getFullName(user)

    const handleSelectItem = (fieldName, selectedItem) => {
        let value = selectedItem
        if (fieldName === 'issuerType') {
            setValue('issuer', null)
            clearErrors(['issuer', 'issuerPhone', 'issuerName', 'issuerSource'])
            selectedItem === 'elder' &&
                setValue('issuer', elder, setValueOptions)
        }

        setValue(fieldName, value, setValueOptions)
    }

    const setIssuer = (user) => {
        setValue('issuer', user, setValueOptions)
    }

    const isIssuerOtherSelected = useCallback(() => {
        return (
            values.issuerType === 'other' ||
            (!values.issuerType &&
                (ticket?.issuerName ||
                    ticket?.issuerPhone ||
                    ticket?.issuerSource))
        )
    }, [ticket, values.issuerType])

    useEffect(() => {
        let issuerValue = getUserType(ticket?.issuer)
        if (ticket && !values.issuerType) {
            if (isIssuerOtherSelected()) {
                issuerValue = 'other'
            }
            setValue('issuerType', issuerValue)
        }
    }, [ticket, setValue, values, isIssuerOtherSelected])

    return (
        <Container>
            <DispatcherNameWrapper>
                <Title>{i18n.t('tickets.dispatcher_name')}:</Title>
                <BoldTitle>{dispatcherName}</BoldTitle>
            </DispatcherNameWrapper>

            <TicketTabSection
                testKey={'issuerType'}
                title={i18n.t('tickets.ticket_source')}
                subtitle={i18n.t('tickets.new_ticket')}>
                <TicketIconsSection
                    fieldName="issuerType"
                    itemName="user"
                    types={userTypes}
                    handleSelectItem={handleSelectItem}
                    activeItem={values.issuerType}
                    allowAdd
                    DialogForm={
                        <AddUserForm
                            type={values.issuerType}
                            setIssuer={setIssuer}
                        />
                    }
                />

                {isIssuerOtherSelected() && <OtherTicketSource />}

                {formErrors.issuer &&
                    !allowSearchItems.includes(values.issuerType) && (
                        <CenteredFormErrorText>
                            {formErrors.issuer.message}
                        </CenteredFormErrorText>
                    )}
            </TicketTabSection>

            <TicketTabSection
                testKey={'howDidYouHearAboutUs'}
                subtitle={i18n.t('tickets.how_did_you_hear_about_us')}>
                <TicketIconsSection
                    fieldName="howDidYouHearAboutUs"
                    itemName="ticket"
                    types={referralTypes}
                    allowAdd={false}
                    activeItem={values.howDidYouHearAboutUs}
                    handleSelectItem={handleSelectItem}
                />

                <CenteredFormErrorText>
                    {formErrors.howDidYouHearAboutUs?.message}
                </CenteredFormErrorText>
            </TicketTabSection>
        </Container>
    )
}

export default TicketSource

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const DispatcherNameWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    padding-inline: 12px;
`

const Title = styled(Texts.RegularText)`
    font-size: 16px;
`
const BoldTitle = styled(Texts.BoldText)`
    font-size: 18px;
`
